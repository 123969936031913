import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import styles from './LabelMap.module.scss';
import { getLabels, saveLabelMap, setLastLabels } from './labelMapSlice';
import classNames from 'classnames';
//import { useForm } from 'react-hook-form'

import { CSSTransition } from 'react-transition-group';

import { LabelContext } from './labelMapSlice';
import { toast } from 'react-toastify';

function QuickButton({label_id, rgb, idx, highlight, title, label_map_abbrev}) {
    const { lastLabels, labels } = useSelector(state => state.labelMap);
    const context = useContext(LabelContext);
    const labelClicked = useCallback(() => {
        let id = lastLabels[lastLabels.length - (idx + 1)];
        if (id) {
            let label = labels.find(v => v.id === id);
            context.setLabel(label);
            context.labelRef.current = label;
            context.onStartClicked();
        }
    }, [])
    return <div className="button-container" onClick={labelClicked}>
        <div className="hotkey"><kbd>{idx + 1}</kbd> </div>
            <div className={classNames('quick-button', {highlight: highlight})} style={{
            backgroundColor: 'rgb(' + rgb + ')'
            }} title={title}>{label_map_abbrev}</div>
        </div>;
}
export default function QuickLabelPanel() {
    const dispatch = useDispatch();
    const { labels, info, lastLabels, hideAll } = useSelector(state => state.labelMap);
    const [lookup, setLookup] = useState({});
    const { section_id } = useParams();
    const context = useContext(LabelContext);
    useEffect(() => {
        if (labels.length > 0) {
            let lookup = {};
            labels.forEach(v => lookup[v.id] = v);
            setLookup(lookup);
            let last_labels = localStorage.getItem('last_labels');
            if (last_labels) {
                let lasts = JSON.parse(last_labels);

                dispatch(setLastLabels(lasts));
            }
        }
    }, [dispatch, labels]);

    const lasts = lastLabels.slice().reverse();
    if (hideAll) {
        return null;
    } else {
        return <div className={classNames(['quick-label', 'ol-unselectable'])}>
            {labels.length > 0 && lasts.slice(0, 3).map(
                (m, idx) => {
                    let target = lookup[m];
                    return <QuickButton key={m} idx={idx} label_id={m} rgb={target.rgb} title={target.title} label_map_abbrev={target.label_map_abbrev} highlight={context.label?.id===target.id} />
                    }
            )}
        </div>
    }
}
