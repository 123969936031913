import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './LabelMap.module.scss';
import {
    getSummary, selectLabelList, updateAP, clearAP,
    leftAP, rightAP, interpolationSelector
} from './labelMapSlice';
import * as d3 from 'd3';

import { getOffset } from '../lib';
//import { useForm } from 'react-hook-form';

import { Dialog, DialogContent } from '@material-ui/core';

let margin = {top: 30, right: 10, bottom: 10, left: 60};
export default function SummaryPanel() {
    const dispatch = useDispatch();
    const { summary, info, ap } = useSelector(state => state.labelMap);
    const ap_ref = useRef(ap);
    const x_bandwidth = useRef(20);
    const y_bandwidth = useRef(20);
    const summary_mounted = useRef(false);
    useEffect(() => {
        if (info) {
            dispatch(getSummary(info.case_id));
        }
    }, [dispatch, info]);
    function round_1(v) {
        return Math.round((v + Number.EPSILON) * 100) / 100
    }
    const interpolation = useSelector(interpolationSelector);
    function update_ap() {
        const { label_titles, label_ids, stack_indices, section_info } = summary;
        function show_ap(node, d) {
            let tip = d3.select('.tooltip');
            let parent = d3.select('.modal-content').node();
            let offset = getOffset(node, parent);
            let stack = node.dataset.index;
            let info = section_info[stack];
            let l = offset.left + x_bandwidth.current - 60;
            if (offset.right < 220) {
                l -= (220 - offset.right);
            }
            tip.style('left', l + 'px')
                .style('top', (offset.top - y_bandwidth.current - 60) + 'px')
                .style('display', 'block')
                .attr('data-ap', true)
                .html(
                    '<div class="stack-index">' + info.nissl_section + ' (' + stack + ')</div><div class="close-button" id="tooltip_close_button">X</div><div>AP: <input type="number" class="ap-value" id="ap_value" value="' + d.value + '" step="0.1" /> ' +  '<button id="ap_left">&lt;</button> <button id="ap_set" >Set</button> <button id="ap_clear" >Clear</button> <button id="ap_right">&gt;</button></div>'
                );
            let value = d3.select('#ap_value');
            //value.node().focus();
            d3.select('#ap_set').on('click', function() {
                let value = parseFloat(d3.select('#ap_value').node().value);
                dispatch(updateAP(d.index, value));
            });
            d3.select('#ap_clear').on('click', function() {
                dispatch(clearAP(d.index));
            });
            d3.select('#ap_left').on('click', async function() {
                let value = parseFloat(d3.select('#ap_value').node().value);
                await dispatch(leftAP(d.index, value));
                setTimeout(() => {
                    d3.select(node.parentNode).select('text.ap[data-index="' + (d.index - 1) + '"').dispatch('click');
                }, 50);
                //show_ap(d3.select(node.parentNode).select('text.ap[data-index="' + (d.index - 1) + '"').node(), d);
            });
            d3.select('#ap_right').on('click', async function() {
                let value = parseFloat(d3.select('#ap_value').node().value);
                await dispatch(rightAP(d.index, value));
                setTimeout(() => {
                    d3.select(node.parentNode).select('text.ap[data-index="' + (d.index + 1) + '"').dispatch('click');
                }, 50);
                //show_ap(d3.select(node.parentNode).select('text.ap[data-index="' + (d.index + 1) + '"').node(), d);
            });
            d3.select('#tooltip_close_button').on('click', function() {
                tip.style('display', 'none');
                tip.attr('data-ap', null);
            });
        }
        let indices = Object.keys(ap_ref.current).map(v => parseInt(v, 10)).sort(function (a, b) { return a-b; });
        let last_index = null;
        let _ap = interpolation;
        let container = d3.select('g.graph');
        container.selectChildren('text.ap')
            .data(_ap)
            .join(
                enter => enter
                    .append('text')
                    /*
                    .attr('x', function (d, i) {
                        //return x_bandwidth * i + 6;
                        return 0;
                    })
                    .attr('y', function (d, i) {
                        //return y_bandwidth * (label_titles.length + 1);
                        return 0;
                    })
                    */
                    .attr('class', 'ap')
                    .attr('transform', function (d, i) {
                        return 'translate(' + (x_bandwidth.current * i + 6) + ', ' + (y_bandwidth.current * (label_titles.length + 1) + 30) + ') rotate(-90)';
                    })
                    .attr('dy', '.7em')
                    .attr('fill', function (d, i) {
                        if (d && d.method == 'manual') {
                            return '#ff0000';
                        } else {
                            return '#000000';
                        }
                    })
                    .attr('stroke', function (d, i) {
                        if (d && d.method == 'manual') {
                            return '#ff0000';
                        } else {
                            return null;
                        }
                    })
                    .style('cursor', function (d, i) {
                        return 'pointer';
                    })
                    .text(function (d, i) {
                        if (d) {
                            return d.value.toFixed(2);
                        } else {
                            return null;
                        }
                    })
                    .attr('data-index', function(d, i) {
                        if (d) {
                            return d.index;
                        } else {
                            return null;
                        }
                    })
                    .on('click', function(evt, d) {
                        show_ap(this, d);
                    }),
            update => update
                .text(function (d, i) {
                    if (d) {
                        return d.value;
                    } else {
                        return null;
                    }
                })
                .attr('fill', function (d, i) {
                    if (d && d.method == 'manual') {
                        return '#ff0000';
                    } else {
                        return '#000000';
                    }
                })
                .attr('stroke', function (d, i) {
                    if (d && d.method == 'manual') {
                        return '#ff0000';
                    } else {
                        return null;
                    }
                })
                .on('click', function(evt, d) {
                    show_ap(this, d);
                }),
        );
    }
    const gen_summary = (summary) => {
        if (summary !== null) {
            const { mapping, label_titles, label_ids, stack_indices, section_info } = summary;
            let width, height;
            width = stack_indices.length * 10 * 2;
            height = label_titles.length * 10 * 2;
            let canvas = d3.select('.matrix');
            canvas.empty();
            let x = d3.scaleBand().range([0, width]),
                y = d3.scaleBand().range([0, height]);
            x.domain(stack_indices);
            y.domain(label_titles);
            x_bandwidth.current = x.bandwidth();
            y_bandwidth.current = y.bandwidth();
            let svg = canvas.append('svg')
                .attr('width', width + margin.left + margin.right + 30)
                .attr('height', height + margin.top + margin.bottom + y.bandwidth() + 30)
                .attr('id', 'summary_svg');
            let container = svg.append('g').attr('class', 'graph')
                .attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')');
            let bg = container.append('rect')
                .attr('class', 'background')
                .attr('x', 1)
                .attr('y', 1)
                .attr('width', width - 1)
                .attr('height', height - 1)
                .attr('stroke', '#000000');


            let rows = [];
            label_titles.forEach(l => {
                rows.push(mapping[l]);
            });
            let row = container.selectAll('g')
                .data(rows)
                .enter()
                .append('g')
                .attr('transform', function(d, i) {
                    return 'translate(0, ' + y.bandwidth() * i + ')';
                })
                .attr('data-label-index', function(d, i) {
                    return i;
                });
            /*
            row.append('line')
                .attr('x2', width)
                .attr('y1', x.bandwidth() / 2 + 1)
                .attr('y2', x.bandwidth() / 2 + 1)
                .attr('class', 'crosshair')
                .each(function(d) {
                    d3.select(this).classed('row-' + d, true);
                });
            */
            row.selectAll('rect')
                .data(function(d, i) {
                    let cols = [];
                    stack_indices.forEach(idx => {
                        let c = d[idx];
                        cols.push(c);
                    });
                    return cols;

                })
                .enter()
                .append('rect')
                .attr('x', function(d, i) { return x.bandwidth() * i + 1})
                .attr('y', 1)
                .attr('width', x.bandwidth() - 1)
                .attr('height', y.bandwidth() - 1)
                .attr('data-section-id', function(d, i) {
                    return section_info[stack_indices[i]].id;
                })
                .attr('data-index', function(d, i) {
                    return i;
                })
                .attr('data-label', function(d) {
                    return d;
                })
                .attr('fill', function(d, i) {
                    if (d) {
                        return 'rgb(' + d.rgb + ')';
                    } else {
                        return '#ffffff';
                    }
                })
                .attr('stroke', function(d, i) {
                    let me = d3.select(this);
                    if (parseInt(me.attr('data-section-id'), 10) == info.section_id) {
                        return '#000000';
                    } else {
                        return null;
                    }
                })
                .on('click', function(evt, d) {
                    let me = d3.select(this);
                    let tip = d3.select('.tooltip');
                    if (tip.attr('data-ap')) {
                        return;
                    }
                    window.location.href = '/label/' + me.attr('data-section-id');
                })
                .on('mouseenter', function(evt, d) {
                    let me = d3.select(this);
                    let tip = d3.select('.tooltip');
                    if (tip.attr('data-ap')) {
                        return;
                    }
                    let parent = d3.select('.modal-content').node();
                    let offset = getOffset(this, parent);
                    let i = me.attr('data-index');
                    let label = me.attr('data-label');
                    let info = section_info[stack_indices[i]];
                    let label_index = d3.select(this.parentNode).attr('data-label-index');
                    me.attr('stroke', '#ff0000');
                    let html = '<div class="label-info">' + label_titles[label_index] + ' on ' + info.nissl_section + ' (' + info.stack_index + ')</div>';
                    if (d && d.section_memo) {
                        html += '<div>Memo: ' + (d && d.section_memo) + '</div>';
                    }
                    let l = offset.left + x_bandwidth.current - 60;
                    if (offset.right < 80) {
                        l -= (80 - offset.right);
                    }
                    tip.style('left', l + 'px')
                        .style('top', (offset.top - y_bandwidth.current - 60) + 'px')
                        .style('display', 'block')
                        .html(html);
                })
                .on('mouseleave', function(evt, d) {
                    let me = d3.select(this);
                    let tip = d3.select('.tooltip');
                    if (tip.attr('data-ap')) {
                        return;
                    }
                    tip.style('display', 'none');
                    if (parseInt(me.attr('data-section-id'), 10) == info.section_id) {
                        me.attr('stroke', '#000000');
                    } else {
                        me.attr('stroke', 'none');
                    }
                });
            row.selectAll('circle')
                .data(function(d, i) {
                    let cols = [];
                    stack_indices.forEach(idx => {
                        let c = d[idx];
                        cols.push(c);
                    });
                    return cols;

                })
                .enter()
                .each(function(d, i) {
                    if (d) {
                        d3.select(this)
                        .append('circle')
                        .attr('cx', x.bandwidth() * (i + 0.5) + 0.5)
                        .attr('cy', y.bandwidth() / 2 + 0.5)
                        .attr('r', 2)
                        .attr('stroke', '#000000')
                        .attr('fill', 'none')
                    }
                });
            row.selectAll('line')
                /*
                .data(function(d, i) {
                    let cols = [];
                    stack_indices.forEach(idx => {
                        let c = d[idx];
                        cols.push(c);
                    });
                    return cols;

                    })*/
                .data(stack_indices)
                .enter()
                .each(function(d, i) {
                    if (section_info[stack_indices[i]].memo) {
                        // draw the memo line
                        d3.select(this)
                        .append('line')
                        .attr('x1', x.bandwidth() * (i + 1))
                        .attr('y1', 1)
                        .attr('x2', x.bandwidth() * i + 0.5)
                        .attr('y2', y.bandwidth())
                        .attr('stroke', '#000000')
                        .attr('fill', 'none')
                        .attr('class', 'memo-strike')
                    }
                });
            row.append('text')
                .attr('x', -6)
                .attr('y', y.bandwidth() )
                .attr('dy', '-.5em')
                .attr('text-anchor', 'end')
                .attr('class', 'label-row')
                .text(function (d, i) { return label_titles[i]})
                .each(function (d, i) {
                    d3.select(this).classed('row-' + d, true);
                });
            container//.selectChildren('text.ap-label')
                .append('text')
                .attr('class', 'ap-label')
                .datum(['AP'])
                .attr('x', function (d, i) {
                    return -6;
                })
                .attr('y', function (d, i) {
                    return y.bandwidth() * (label_titles.length + 1);
                })
                .attr('text-anchor', 'end')
                //.attr('dy', '-.5em')
                .text(function (d, i) {
                    return d;
                });
            update_ap();
            /*
            row.append('line')
                .attr('x2', width)
                .attr('y1', y.bandwidth() / 2 + 1)
                .attr('y2', y.bandwidth() / 2 + 1)
                .attr('class', 'crosshair')
                .each(function (d) {
                    d3.select(this).classed('row' + d, true);
                });
                */
            let label_column = container.selectAll('g.column')
                .data(stack_indices)
                .enter()
                .append('g')
                .classed('column', true)
                .attr('transform', function(d, i) {
                    return 'translate(' + x(d) + ') rotate(-90)';
                })
                .attr('data-section-id', function(d, i) {
                    return section_info[stack_indices[i]].id;
                })
                .on('click', function(evt, d) {
                    let me = d3.select(this);
                    window.location.href = '/label/' + me.attr('data-section-id');
                })
                ;
            /*
            label_column.append('line')
                .attr('x1', -(height))
                .attr('y1', x.bandwidth() / 2 + 1)
                .attr('y2', x.bandwidth() / 2 + 1)
                .attr('class', 'crosshair')
                .each(function (d) {
                    d3.select(this).classed('column-' + d, true)
                });
            */
            label_column.append('text')
                .attr('x', 6)
                .attr('y', y.bandwidth() / 2)
                .attr('dy', '.3em')
                .attr('text-anchor', 'start')
                .attr('class', 'label-column')
                .text(function (d, i) {
                    return section_info[stack_indices[i]].nissl_section;
                })
                .each(function (d) {
                    d3.select(this).classed('column-' + d, true);
                });
                /*
            container.append('text')
                .attr('x', 0)
                .attr('y', 0)
                .attr('dy', '-4em')
                .attr('text-anchor', 'middle')
                .attr('class', 'caption-section')
                .text('section')
                .attr('transform', 'translate(0 ' + (( height / 2) + margin.top) + ') rotate(-90)');
            container.append('text')
                .attr('x', 0)
                .attr('y', 20)
                .attr('dy', '.3em')
                .attr('text-anchor', 'middle')
                .attr('class', 'caption-label')
                .text('Label');
            */
            let mtx = d3.select('.matrix');
            if (mtx.node()) {
                mtx.node().scrollLeft = x.bandwidth() * Math.max(info.stack_index - 25, 0);
            }
        }
    }
    useEffect(() => {
        if (summary && modalIsOpen && !summary_mounted.current) {
            summary_mounted.current = true;
            gen_summary(summary);
        }
    }, [ summary ]);
    useEffect(() => {
        if (summary) {
            ap_ref.current = ap;
            update_ap();
        }
    }, [ ap ]);
    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 'none'
        },
    };
    let [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => {
        setModalIsOpen(true);
        //setTimeout(afterOpenModal, 1000);
    }
    const closeModal = () => {
        setModalIsOpen(false);
        d3.select('.tooltip').style('display', 'none');
    }
    const afterOpenModal = () => {
        if (summary) {
            summary_mounted.current = true;
            gen_summary(summary);
        }
    }
    const setShowPopper = (state) => {

    }
    /*
            <Modal
                isOpen={modalIsOpen}
                style={modalStyles}
                className="modal-content"
                overlayClassName="modal-overlay"
                contentLabel="Summary"
                onRequestClose={closeModal}
                onAfterOpen={afterOpenModal}
                >
                <div className="summary-panel">
                    <div className="matrix">
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpen}
                style={modalStyles}
                className="modal-content"
                overlayClassName="modal-overlay"
                contentLabel="Summary"
                onRequestClose={closeModal}
                onAfterOpen={afterOpenModal}
                >
                <div className="summary-panel">
                    <div className="matrix">
                    </div>
                </div>
            </Modal>
            */
    return (
        <div className="summary-function">
            <button className="summary-button"
                onClick={openModal}
                >Summary</button>
            <Dialog
                className="label-summary"
                open={modalIsOpen}
                style={modalStyles}
                onClose={closeModal}
                onEntered={afterOpenModal}
                aria-labelledby="label-summary"
                >
                <DialogContent
                    className="modal-content"
                    >
                    <div className="title">
                        <div className="main-title">
                            Label summary for {info && info.case_id}
                        </div>
                        <div className="sub-title">
                        </div>
                    </div>
                    <div className="summary-panel">
                        <div className="matrix">
                        </div>
                    </div>
                    <div className="tooltip">
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
