import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    save, setAutosave,
    setLabelSelected, setSnap, setWheelZoom,
    addOverlayOffset, setOverlaySelected, updateOverlayOffsets,
    getOverlayOffsets, setShowOverlayPopper, deleteOverlayOffset,
    setAdjustOverlay, setHideBox, getCropProgress, requestCrop,
    setOverlayOn, setOverlayOpacity,
} from './layer2Slice';
import classNames from 'classnames';
import { GlobalHotKeys } from 'react-hotkeys';
//import { useForm } from 'react-hook-form'

import { CSSTransition } from 'react-transition-group';

import { TextField, Dialog, DialogContent } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';

import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';
import { updateLabelSectionMemo } from '../../api/API';
import styles from './overlay.module.scss';

//configure({ logLevel: 'debug' });
const useStyles = makeStyles({
    autocomplete: {
        display: 'inline-block',
        width: 250,
        verticalAlign: 'middle',
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            padding: 5
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000',
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
            paddingTop: 0,
            paddingBottom: 0,
        },
        '& .MuiAutocomplete-popper': {
            '& .MuiAutocomplete-option': {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: '2em',
                '& .label-option': {
                    lineHeight: 1,
                    '& span': {
                        lineHeight: 1,
                    }
                }
            }
        },
    },
    cropProgress: {
        border: '1px solid #000',
        padding: '5px',
        marginTop: '10px',
    },
    tooltip: {
        border: '1px solid #000',
        padding: '5px',
        marginTop: '10px',
        width: '100%'
    },
});

const cancellablePromise = promise => {
    let isCanceled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            value => (isCanceled ? reject({ isCanceled, value }): resolve(value)),
            error => reject({ isCanceled, error }),
        )
    });
    return {
        promise: wrappedPromise,
        cancel: () => (isCanceled = true),
    };
};

let waitForClick = null;

const filter = createFilterOptions();
const delay = n => new Promise(resolve => setTimeout(resolve, n));

export default function (props) {
    const dispatch = useDispatch();
    const {
        labels, mapDirty, hideAll, polylist,
        autosave, drawing, labelSelected, gamma,
        snap, wheel_zoom, overlayOffsets, overlaySelected,
        overlayOn,
        mapInit,
    } = useSelector(state => state.layer2);
    const tipRef = useRef();
    const [ showPopper, setShowPopper ] = useState(false);
    const [ showList, setShowList ] = useState(true);
    const [ version, setVersion ] = useState(0);
    const { section_id } = useParams();
    const completeRef = useRef(null);
    const label = null;
    const classes = useStyles();
    /*
    useEffect(() => {
        const map = window.app.map;
        if (!map) {
            return;
        }
        if (snap) {
            map.snap.setActive(true);
        } else {
            map.snap.setActive(false);
        }
        if (wheel_zoom) {
            map.mouse_zoom.setActive(true);
        } else {
            map.mouse_zoom.setActive(false);
        }
    }, [snap, wheel_zoom]);
    */
    useEffect(() => {
        dispatch(getOverlayOffsets(section_id));
        dispatch(getCropProgress(section_id));
    }, [dispatch]);

    const startDraw = () => {
        const map = window.app.map;
        map.startDraw();
    }
    const onShowPopper = () => {
        setShowPopper(true);
    };
    const keyMap = {
        //SLASH: '/',
        //STAR: '*',
        D: 'D+Shift',
        Focus: 'C+Shift',
    };
    const handlers = {
        CLOSE: () => {
            if (showPopper) {
                setShowPopper(false);
            }
        },
        SLASH: e => {
            e.preventDefault();
            const map = window.app.map;
            let value = map.labels.find(v => v.id === 11);
            if (value) {
                dispatch(setLabelSelected(value));
                map.selectLabel(value);
            }
            if (map.drawing) {
                map.draw_polyline.finishDrawing();
            }
            map.startDraw();
        },
        STAR: e => {
            e.preventDefault();
            const map = window.app.map;
            let value = map.labels.find(v => v.id === 12);
            if (value) {
                dispatch(setLabelSelected(value));
                map.selectLabel(value);
            }
            if (map.drawing) {
                map.draw_polyline.finishDrawing();
            }
            map.startDraw();
        },
        D: e => {
            if (e.key === 'd') {
                // ignore small cased letter
                return true;
            }
            const map = window.app.map;
            map.startDraw();
        },
        Focus: e => {
            if (e.key === 'c') {
                // ignore small cased letter
                return;
            }
            e.preventDefault();
            completeRef.current.focus()
            completeRef.current.value = '';
        },
    };
    const onSaveClicked = async () => {
        await dispatch(save(section_id));
        toast.success('Annotation saved.', {position: 'top-center', autoClose: 5000, draggable: true});
    };
    const [memoDirty, setMemoDirty] = useState(false);
    const onMemoChanged = async (e) => {
        setMemoDirty(true);

    };
    const onMemoBlurred = async (e) => {
        await updateLabelSectionMemo(section_id, e.target.value);
        setMemoDirty(false);
    };
    const adjustOverlay = useSelector(state => state.layer2.adjustOverlay);
    const hideBox = useSelector(state => state.layer2.hideBox);
    const cropProgress = useSelector(state => state.layer2.cropProgress);
    let counts = {};
    polylist.forEach(p => {
        p.properties.title in counts || (counts[p.properties.title] = {'title': p.properties.title, 'count': 0, 'memo': p.properties.memo});
        counts[p.properties.title].count += 1;
    });
    let handleSetOverlay = () => {
        dispatch(updateOverlayOffsets(section_id, window.app.map.view2_offset));
    };
    let handleRecallOverlay = () => {
        const map = window.app.map;
        if (!map) {
            return;
        }
        map.view2_offset = { ...overlaySelected.offsets };
        const main_view = overlaySelected.main_view;
        map.original_center_x = main_view.original_center_x;
        map.original_center_y = main_view.original_center_y;
        map.original_resolution = main_view.original_resolution;
        map.original_rotation = main_view.rotation;
        map.view1.setCenter([main_view.x, main_view.y]);
        map.view1.setResolution(main_view.resolution);
        map.view1.setRotation(main_view.rotation);
        map.updateView2();
        if (overlaySelected.overlay_on) {
            dispatch(setOverlayOn(true));
        } else {
            dispatch(setOverlayOn(false));
        }
        window.setTimeout(() => {
            map.map2.updateSize();
        }, 200);

        dispatch(setOverlayOpacity(overlaySelected.overlay_opacity));

    };
    useEffect(() => {
        if (overlayOffsets.length > 0 && mapInit) {
            const params = new URLSearchParams(window.location.search);
            const overlay_view = params.get('overlay_view');
            if (overlay_view) {
                //window.location.href = window.location.pathname;
                const map = window.app.map;
                if (!map) {
                    return;
                }
                let overlaySelected = overlayOffsets.find(v => v.id === parseInt(overlay_view, 10));

                if (overlaySelected === undefined) {
                    overlaySelected = overlayOffsets.find(v => v.title === overlay_view);
                }
                console.log('overlay selected', overlaySelected);
                if (overlaySelected) {
                    dispatch(setOverlaySelected(overlaySelected));
                    map.view2_offset = { ...overlaySelected.offsets };
                    const main_view = overlaySelected.main_view;
                    map.original_center_x = main_view.original_center_x;
                    map.original_center_y = main_view.original_center_y;
                    map.original_resolution = main_view.original_resolution;
                    map.original_rotation = main_view.rotation;
                    map.view1.setCenter([main_view.x, main_view.y]);
                    map.view1.setResolution(main_view.resolution);
                    map.view1.setRotation(main_view.rotation);
                    map.updateView2();
                    //const url = window.location.href.split('?')[0];
                    //window.history.pushState(null, document.title, url)
                    dispatch(setAdjustOverlay(false));
                    if (overlaySelected.overlay_on) {
                        dispatch(setOverlayOn(true));
                    } else {
                        dispatch(setOverlayOn(false));
                    }
                    window.setTimeout(() => {
                        map.map2.updateSize();
                    }, 200);

                    dispatch(setOverlayOpacity(overlaySelected.overlay_opacity));
                }
            }
        }
    }, [overlayOffsets, mapInit]);
    let handleDeleteOverlay = () => {
        dispatch(deleteOverlayOffset(section_id, overlaySelected.id));
    };
    let handleMemo = () => {
        dispatch(setShowOverlayPopper(true));
    };
    let handleLink = () => {
        let link = window.location.href + '?overlay_view=' + overlaySelected.id;
        navigator.clipboard.writeText(link)
        .then(() => {
            toast('Link ' + link + ' sent to clipboard');

        });

    };
    let handleAdjustOverlay = () => {
        if (adjustOverlay) {
            dispatch(setAdjustOverlay(false));
        } else {
            dispatch(setAdjustOverlay(true));
        }
    };
    let handleCountingBox = () => {
        if (hideBox) {
            dispatch(setHideBox(false));
        } else {
            dispatch(setHideBox(true));
        }
    };
    const [showTip, setShowTip] = useState(false);
    //const [tip, setTip] = useState('');
    const [tipItem, setTipItem] = useState(null);
    const [showCrop, setShowCrop] = useState(false);
    let handleCrop = () => {
        if (cropProgress?.crop_status === 'Done') {
            if (waitForClick !== null) {
                waitForClick.cancel();
            }
            waitForClick = cancellablePromise(delay(300));
            waitForClick.promise.then(result => {
                let link = cropProgress.result_url;
                navigator.clipboard.writeText(link)
                .then(() => {
                    toast('Link ' + link + ' sent to clipboard');

                });
                return result;
            })
            .catch(errInfo => {
                if (!errInfo.isCanceled) {
                    throw errInfo.error;
                }
            });
        } else if (cropProgress?.crop_status === 'Pending') {
            dispatch(requestCrop(section_id))
            .then(() =>
                dispatch(getCropProgress(section_id))
             );
        } else if (!cropProgress.crop_status) {
            dispatch(requestCrop(section_id))
            .then(() =>
                dispatch(getCropProgress(section_id))
             );
        }
    };
    let handleRecrop = (e) => {
        if (cropProgress?.crop_status === 'Done') {
            if (waitForClick !== null) {
                waitForClick.cancel();
            }
            dispatch(requestCrop(section_id))
            .then(() =>
                dispatch(getCropProgress(section_id))
             );
        }
    };
    let tip;
    switch (tipItem) {
        case 'hideBox':
            tip = <><div>Toggle the display of counting boxes.</div><div>Currently {hideBox ? 'hidden' : 'showing'}.</div></>;
            break;
        case 'adjustOverlay':
            tip = <><div>Toggle if the alignment of overview can be tuned.</div><div>Currently {adjustOverlay ? 'editable' : 'fixed'}.</div></>;
            break;
        case 'set':
            tip = <><div>Set the current overlay states to be saved</div></>;
            break;
        case 'recall':
            tip = <><div>Recall the overlay states</div></>;
            break;
        case 'memo':
            tip = <><div>Update the memo for selected overlay</div></>;
            break;
        case 'delete':
            tip = <><div>Delete the selected overlay states</div></>;
            break;
        case 'link':
            tip = <><div>Copy the hyperlink to recall<br/> the current overlay state to clipboard.</div></>;
            break;
    }
    if (hideAll) {
        return <div className="hide-all-overlay">UI is hidden, press Shift + O to reveal.</div>
    } else {
        return (
            <div className={classNames([styles.overlay, 'ol-unselectable'])}>
                <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
                <div className={styles.comboBox}>
                    <Autocomplete
                        className={classes.autocomplete}
                        options={overlayOffsets}
                        getOptionLabel={option => option.title}
                        style={{ width: 300 }}
                        value={overlaySelected}
                        renderInput={params =>
                            <TextField { ...params }
                                inputRef={ref => completeRef.current = ref}
                                variant="outlined" />
                            }
                        renderOption={option =>
                            <div className={styles.labelOption}>
                                    {option.title}
                            </div>
                        }
                        onChange={(e, value) => {
                            const map = window.app.map;
                            console.log('selected', value);

                            if (value && value.inputValue) {
                                console.log('got a new one?', value);
                                dispatch(addOverlayOffset(value.inputValue));
                            } else {
                                console.log('normal select');
                                dispatch(setOverlaySelected(value));
                            }

                        }}
                        blurOnSelect={true}
                        filterOptions={(options, params) => {
                            console.log('params is', params);
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            const exists = options.some((option) => inputValue === option.title);
                            if (inputValue !== '' && !exists) {
                                filtered.push({
                                    inputValue,
                                    title: `Add "${inputValue}"`,
                                });
                            }
                            return filtered;
                        }}
                        getOptionSelected={(option, value) => option.id === value?.id}
                    />
                    <div>
                        {overlaySelected && overlaySelected.offsets?.memo && 'Memo: ' + overlaySelected.offsets.memo}
                    </div>
                </div>
                <div className={styles.control}>
                    <button className={classNames(styles.action, {[styles.active]: overlaySelected})}
                        disabled={!overlaySelected}
                        onMouseEnter={() => {
                            setTipItem('set');
                            setShowTip(true);
                        }}
                        onMouseLeave={() => { setTipItem(null); setShowTip(false)}}
                        onClick={handleSetOverlay}>Set</button>
                    <button className={classNames(styles.action, {[styles.active]: overlaySelected?.id})}
                        disabled={!overlaySelected?.id}
                        onMouseEnter={() => {
                            setTipItem('recall');
                            setShowTip(true);
                        }}
                        onMouseLeave={() => { setTipItem(null); setShowTip(false)}}
                        onClick={handleRecallOverlay}>Recall</button>
                    <button className={classNames(styles.action, {[styles.active]: overlaySelected?.id})}
                        disabled={!overlaySelected?.id}
                        onMouseEnter={() => {
                            setTipItem('memo');
                            setShowTip(true);
                        }}
                        onMouseLeave={() => { setTipItem(null); setShowTip(false)}}
                        onClick={handleMemo}>Memo</button>&nbsp;
                    <button className={classNames(styles.action, {[styles.active]: overlaySelected?.id})}
                        disabled={!overlaySelected?.id}
                        onMouseEnter={() => {
                            setTipItem('delete');
                            setShowTip(true);
                        }}
                        onMouseLeave={() => { setTipItem(null); setShowTip(false)}}
                        onClick={handleDeleteOverlay}
                        >Delete</button>
                    <button className={classNames(styles.action, {[styles.active]: overlaySelected?.id})}
                        disabled={!overlaySelected?.id}
                        onMouseEnter={() => {
                            setTipItem('link');
                            setShowTip(true);
                        }}
                        onMouseLeave={() => { setTipItem(null); setShowTip(false)}}
                        onClick={handleLink}
                        >⛓</button>
                </div>
                <div className={styles.control}>
                    <button className={classNames(styles.action, {[styles.active]: !hideBox})}
                        onClick={handleCountingBox}
                        onMouseEnter={() => {
                            setTipItem('hideBox');
                            setShowTip(true);
                        }}
                        onMouseLeave={() => { setTipItem(null); setShowTip(false)}}
                            >{hideBox ? '☖' : '☗'}</button>
                    <button className={classNames(styles.action, {[styles.active]: adjustOverlay})}
                        onClick={handleAdjustOverlay}
                        onMouseEnter={() => {
                            setTipItem('adjustOverlay');
                            setShowTip(true);
                        }}
                        onMouseLeave={() => { setTipItem(null); setShowTip(false)}}

                            >Adjust</button>
                    <button className={classNames(styles.action, {[styles.active]: true})}
                        onClick={handleCrop}
                        onDoubleClick={handleRecrop}
                        onMouseEnter={() => {
                            dispatch(getCropProgress(section_id));
                            setShowCrop(true);
                        }}
                        onMouseLeave={() => {
                            setShowCrop(false);
                        }}
                            >✂</button>
                </div>
                {overlayOn && <div>Overlay on, press 0 to disable it.</div>}
                { showCrop &&
                    <div className={classes.cropProgress}>
                        { cropProgress.crop_status === 'Done' &&
                            <div>
                                Click to copy result url to clipboard.<br/>
                                Double click to request cropping again.
                            </div>
                        }

                        { cropProgress.crop_status === 'Pending' &&
                            <div>
                                Section in the cropping queue.
                            </div>
                        }
                        { !cropProgress.crop_status &&
                            <div>
                                Click to queue cropping.
                            </div>
                        }
                        { cropProgress.crop_status === 'Cropping' &&
                            <div>
                                Section being cropped, please wait...<br/>
                                Progress: {cropProgress.progress}
                            </div>
                        }
                    </div>
                }
                { showTip &&
                    <div className={classes.tooltip}>
                        { tip }
                    </div>
                }
            </div>
        );
    }
}

