import { configureStore } from '@reduxjs/toolkit';
import sampleReducer from '../features/sample/sampleSlice';
import cropReducer from '../features/crop/cropSlice';
import inspectReducer from '../features/inspect/inspectSlice';
import labelMapReducer from '../features/labelmap/labelMapSlice';
import userReducer from '../features/user/userSlice';
import flatmapReducer from '../features/flatmap/flatmapSlice';
import layerReducer from '../features/layer/layerSlice';
import layer2Reducer from '../features/layer2/layer2Slice';

export default configureStore({
  reducer: {
    sample: sampleReducer,
    crop: cropReducer,
    inspect: inspectReducer,
    labelMap: labelMapReducer,
    user: userReducer,
    flatmap: flatmapReducer,
    layer: layerReducer,
    layer2: layer2Reducer,
  },
});
