import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProcessingQueue, getProcessingQueueFull } from './cropSlice';
import { useParams } from 'react-router-dom';
import styles from './Crop.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Th } from './utils';

export function Queue({ show }) {
    const dispatch = useDispatch();
    const queue = useSelector(state => state.crop.processingQueue);
    const queueFull = useSelector(state => state.crop.processingQueueFull);

    return <div className={classNames(styles.queue, {[styles.hidden]: !show})}>
        <ol>
        {
            queue.length === 0 ? <li>Queue is empty.</li> : null
        }
        {   queue.map(q =>
              <li key={q.case_id + q.section}>{q.case_id}-{q.section}</li>
            )
        }
        </ol>
        <hr/>
        Full Resolution Queue
        <ol>
        {
            queueFull.length === 0 ? <li>Full res queue is empty.</li> : null
        }
        {   queueFull.map(q =>
              <li key={q.section}>{q.case_id}-{q.section}</li>
            )
        }
        </ol>
        </div>
}

export default function () {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProcessingQueue())
        dispatch(getProcessingQueueFull())
    }, [dispatch]);
    return (
        <div className={styles.queue_preview}>
            <a href="#"
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                >Queue</a>
            <Queue show={show}/>
        </div>
    )
}
