import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

import { getLabels as apiGetLabels,
    getLabelSections as apiGetSections,
    getLayerSectionInfo as apiGetSectionInfo,
    updateLabels as apiUpdateLabels,
    getLayerTypes as apiGetLayerTypes,
    saveLayerPolygons as apiSaveLayerPolygons,
    getLayerCases as apiGetLayerCases,
} from '../../api/API';
import { createSelector } from 'reselect';
import create from 'zustand';

import { Collection, Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { LineString, Polygon } from 'ol/geom';
import { cmpalphanum } from '../lib';

export const LayerContext = React.createContext();
export const polyCollection = new Collection();
export let modifyObject = {};

let geojson = new GeoJSON();

function getLocalStorage(key, def) {
    let value;
    try {
        value = JSON.parse(localStorage.getItem(key));
    } catch (e) {
        value = def;
    }
    if (value === null) {
        value = def;
    }
    return value;
}
export const layerSlice = createSlice({
    name: 'layer',
    initialState: {
        labels: [],
        autosave: getLocalStorage('autosave', false),
        drawing: false,
        hideAll: getLocalStorage('hide_all'),
        labelSelected: null,
        mapDirty: false,
        polylist: [],
        sections: [],
        info: null,
        gamma: 1.0,
        loading: true,
        caseIds: [],
        cases: [],
        showPopper: false,
        featuresDump: [],
        snap: getLocalStorage('snap', true),
        wheel_zoom: getLocalStorage('wheel_zoom', true),
    },
    reducers: {
        setLabels: (state, action) => {
            const map = window.app.map;
            state.labels = action.payload;
            if (map) {
                map.labels = action.payload;
            }
        },
        setHideAll: (state, action) => {
            state.hideAll = action.payload;
            localStorage.setItem('last_hide_all', JSON.stringify(action.payload));
        },
        setDrawing: (state, action) => {
            state.drawing = action.payload;
        },
        setLabelSelected: (state, action) => {
            const map = window.app.map;
            if (map) {
                map.selectLabel(action.payload);
            }
            state.labelSelected = action.payload;
            localStorage.setItem('label_selected', JSON.stringify(action.payload));
        },
        setMapDirty: (state, action) => {
            state.mapDirty = action.payload;
        },
        setPolylist: (state, action) => {
            state.polylist = action.payload;
        },
        setSections: (state, action) => {
            state.sections = action.payload;
        },
        setInfo: (state, action) => {
            state.info = action.payload;
        },
        setGamma: (state, action) => {
            state.gamma = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setAutosave: (state, action) => {
            const map = window.app.map;
            if (map) {
                map.autosave = action.payload;
            }
            state.autosave = action.payload;
            localStorage.setItem('autosave', action.payload);
        },
        setWheelZoom: (state, action) => {
            const map = window.app.map;
            /*
            if (map) {
                map.wheel_zoom = action.payload;
            }
            */
            state.wheel_zoom = action.payload;
            localStorage.setItem('wheel_zoom', action.payload);
        },
        setSnap: (state, action) => {
            const map = window.app.map;
            /*
            if (map) {
                //map.autosave = action.payload;
            }
            */
            state.snap = action.payload;
            localStorage.setItem('snap', action.payload);
        },
        setCaseIds: (state, action) => {
            state.caseIds = action.payload;
        },
        setCases: (state, action) => {
            state.cases = action.payload;
        },
        setShowPopper: (state, action) => {
            state.showPopper = action.payload;
        },
        setFeaturesDump: (state, action) => {
            state.featuresDump = action.payload;
        }
    },
});
export const {
    setLabels, setHideAll, setAutosave, setDrawing, setLabelSelected,
    setMapDirty, setPolylist, setSections, setInfo, setGamma, setLoading,
    setCaseIds, setCases, setShowPopper, setFeaturesDump, setSnap, setWheelZoom
} = layerSlice.actions;

export const getLabels = () => async (dispatch) => {
    let result = await apiGetLayerTypes();
    await dispatch(setLabels(result.labels));
    let selected = getLocalStorage('label_selected');
    if (!selected) {
        dispatch(setLabelSelected(result.labels[0]));
    }
    dispatch(setLabelSelected(selected));
}
export const save = (section_id) => async (dispatch) => {
    let json = geojson.writeFeaturesObject(polyCollection.getArray());

    await apiSaveLayerPolygons(section_id, json);
    dispatch(setMapDirty(false));
}
window.collection = polyCollection;
export const addPolygon = f => async (dispatch) => {
    polyCollection.push(f);
    let json = geojson.writeFeaturesObject(polyCollection.getArray());
    dispatch(setPolylist(json.features));
    dispatch(setMapDirty(true));
}
export const removePolygon = (uid) => async (dispatch, getState) => {
    let target = uid;
    let { highlight } = getState().layer;
    if (uid === -1) {
        target = highlight;
    } else {
        target = uid;
    }
    if (target) {
        let to_remove = [];
        polyCollection.forEach(v => {
            if (v.ol_uid === target) {
                to_remove.push(v);
            }
        })
        to_remove.forEach(v => {
            polyCollection.remove(v);
        });
        let json = geojson.writeFeaturesObject(polyCollection.getArray());
        dispatch(setPolylist(json.features));
        dispatch(setMapDirty(true));
    }
}

export const removeVertex = () => async (dispatch, getState) => {
    let { vertex, polygon } = modifyObject;
    if (!polygon) {
        return;
    }
    if (!vertex) {
        let target = polygon.ol_uid;
        let to_remove = [];
        polyCollection.forEach(v => {
            if (v.ol_uid === target) {
                to_remove.push(v);
            }
        })
        to_remove.forEach(v => {
            polyCollection.remove(v);
        });
    } else {
        let vertexCoord = vertex.getGeometry().getCoordinates();
        let polygonGeom = polygon.getGeometry();
        let coords = polygonGeom.getCoordinates();
        let newCoords = coords[0].filter(v => !(v.length === 2 && v[0] === vertexCoord[0] && v[1] === vertexCoord[1]));
        polygonGeom.setCoordinates([newCoords]);
    }
    let json = geojson.writeFeaturesObject(polyCollection.getArray());
    dispatch(setPolylist(json.features));
    dispatch(setMapDirty(true));

}

export const getSections = (section_id) => async (dispatch, getState) => {
    let res = await apiGetSections(section_id);
    dispatch(setSections(res.sections));
}

export const getSectionInfo = (section_id) => async (dispatch, getState) => {
    let res = await apiGetSectionInfo(section_id);
    dispatch(setInfo(res.info));

    res.info.layers.forEach(l => {
        let fill = JSON.parse(l.fill);
        let stroke = JSON.parse(l.stroke);
        let opacity;

        opacity = fill[3];
        fill = `${fill[0]}, ${fill[1]}, ${fill[2]}`;
        stroke = `${stroke[0]}, ${stroke[1]}, ${stroke[2]}`;

        let geom;
        if (l.geometry === 'Polygon') {
            geom = Polygon;
        } else if (l.geometry === 'LineString') {
            geom = LineString;
        }

        let f = new Feature({
            name: 'layer',
            geometry: new geom(l.coords)
        });
        f.set('name', 'layer');
        f.set('id', l.id);
        f.set('highlight', false);
        f.set('type_id', l.type_id);
        f.set('ol_uid', f.ol_uid);
        f.set('title', l.title);
        f.set('rgb', fill);
        f.set('stroke', stroke);
        f.set('opacity', opacity);
        f.set('memo', l.memo);
        polyCollection.push(f);
    });
    let json = geojson.writeFeaturesObject(polyCollection.getArray());
    dispatch(setPolylist(json.features));
    dispatch(setMapDirty(false));
}

export const getLayerCases = () => async (dispatch, getState) => {
    let res = await apiGetLayerCases();
    dispatch(setCaseIds(res.case_ids));
    dispatch(setCases(res.cases));
}

const useMapStore = create(set => ({
    map: null,
    setMap: (map) => set(state => ({ map: map })),
    //polyCollection: new Collection(),
    //setPolyCollection: (collection) => set(state => ({ collection: collection })),
}));

export const selectCJCases = createSelector(
    state => state.layer.cases,
    (cases) => {
        console.log('all cases', cases);
        let l = Object.keys(cases).filter(v => v.startsWith('CJ')).sort(cmpalphanum);
        let res = [];
        l.forEach(i => {
            if (cases[i].labels.length > 0) {
                res.push({
                    case_id: i,
                    labels: cases[i].labels
                });
            }
        });
        return res;
    }
);
export const selectWCases = createSelector(
    state => state.layer.cases,
    (cases) => {
        let l = Object.keys(cases).filter(v => v.startsWith('W')).sort(cmpalphanum);
        let res = [];
        l.forEach(i => {
            if (cases[i].labels.length > 0) {
                res.push({
                    case_id: i,
                    labels: cases[i].labels
                });
            }
        });
        return res;
    }
);
export const selectCandidateCases = createSelector(
    state => state.layer.cases,
    (cases) => {
        let l = Object.keys(cases).sort(cmpalphanum);
        let res = [];
        l.forEach(i => {
            res.push({
                case_id: i,
                labels: cases[i]
            });
        });
        return res;
    }
);

export const setFeatureMemo = (featureId, memo) => async (dispatch, getState) => {
    /*
    res.info.layers.forEach(l => {
        let fill = JSON.parse(l.fill);
        let stroke = JSON.parse(l.stroke);
        let opacity;

        opacity = fill[3];
        fill = `${fill[0]}, ${fill[1]}, ${fill[2]}`;
        stroke = `${stroke[0]}, ${stroke[1]}, ${stroke[2]}`;
        console.log('!!!!!!!!!!!!!!!!!opacity is', opacity);

        let geom;
        if (l.geometry === 'Polygon') {
            geom = Polygon;
        } else if (l.geometry === 'LineString') {
            geom = LineString;
        }

        let f = new Feature({
            name: 'layer',
            geometry: new geom(l.coords)
        });
        f.set('name', 'layer');
        f.set('id', l.id);
        f.set('highlight', false);
        f.set('type_id', l.type_id);
        f.set('ol_uid', f.ol_uid);
        f.set('title', l.title);
        f.set('rgb', fill);
        f.set('stroke', stroke);
        f.set('opacity', opacity);
        f.set('memo', l.memo);
        polyCollection.push(f);
    });
    */
    polyCollection.forEach(p => {
        if (p.get('id') === featureId) {
            console.log('found it');
            p.set('memo', memo);
        }
    });
    let json = geojson.writeFeaturesObject(polyCollection.getArray());
    dispatch(setPolylist(json.features));
    dispatch(setMapDirty(true));
};
export { useMapStore };
export default layerSlice.reducer;

