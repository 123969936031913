import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { GlobalHotKeys } from 'react-hotkeys';

import 'ol/ol.css';
import './layer.scss';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { MultiPoint } from 'ol/geom';

import { Dialog, DialogContent } from '@material-ui/core';

import UtilityPanel from './UtilityPanel';

import { DoubleMap } from './Map';
import { toast } from 'react-toastify';
import { addPolygon, getSections,
    getSectionInfo, removeVertex, setGamma, setLoading, getLabels,
    save, setDrawing, setHideAll, setShowPopper, setFeatureMemo,
} from './layerSlice';

let micronPerPx = 0.497400;

function Thumbnail({section, section_id, case_id, index}) {
    const params = useParams();
    return (
        <a href={section_id == params.section_id ? null : '/layer/' + section_id} className={classNames({active: section_id == params.section_id})}>
            <div className="section-thumbnail" id={'thumbnail_' + section_id} data-section-id={section_id}>
                <span className="align-helper"></span>
                <img className="lazy-load bottom-filmstrip" src={'https://cdn.marmosetbrain.org/thumbnails/' + case_id + '/' + section + '.png'} alt={'Section ' + section} data-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAANSURBVBhXYzh8+PB/AAffA0nNPuCLAAAAAElFTkSuQmCC" width="120" height="120"/>
                <div className="section-label">{index} {case_id}-{section}</div>
            </div>
        </a>
    )
}
let lastL;

function MemoUpdater(props) {
    const { memo, id } = props;
    const [ formMemo, setFormMemo ] = useState(memo);
    const dispatch = useDispatch();
    const updateFormMemo = e => {
        setFormMemo(e.target.value);
    }
    const handleUpdateFeatureMemo = e => {
        console.log('updating f', id, formMemo);
        dispatch(setFeatureMemo(id, formMemo));
        dispatch(setShowPopper(false));
    };
    return (
        <>
        <div className="memo">
            <textarea onChange={updateFormMemo} defaultValue={memo} />
        </div>
        <button onClick={handleUpdateFeatureMemo}>Update</button>
        </>
    )
}
export default function () {
    const { sections, info, mapDirty, labels, lastLabels, hideAll,
        loading, autosave, drawing,
        showPopper, featuresDump, snap, wheel_zoom
    } = useSelector(state => state.layer);

    const dispatch = useDispatch();
    let location = useLocation();
    const dirtyRef = useRef(mapDirty);
    const olInited = useRef(false);

    //let query = qs.parse(location.search, { ignoreQueryPrefix: true });
    //let q = query.injection.split('-');
    const [ opacity, setOpacity ] = useState(.8);
    const opacityRef = useRef(opacity);
    const [ label, setLabel ] = useState(null);
    const labelRef = useRef(label);
    const [ showList, setShowList ] = useState(false);
    const polyLayerRef = useRef(null);
    const drawingRef = useRef(drawing);
    const { section_id } = useParams();
    const sectionsRef = useRef(null);
    const history = useHistory();
    const hideAllRef = useRef(hideAll);
    const [ enlarge, setEnlarge ] = useState(false);
    const enlargeRef = useRef(enlarge);
    const [ formMemo, setFormMemo ] = useState(null);
    useEffect(() => {
        if (sections) {
            const film = document.getElementById('filmstrip_container');
            const target = document.getElementById('thumbnail_' + section_id);
            if (target) {
                const left = target.offsetLeft;
                const w = window.innerWidth;
                let l = left - w / 2;
                if (l < 0) {
                    l = 0;
                }
                film.scrollTo({
                    top: 0,
                    left: l,
                    behavior: 'auto'
                });

            }
        }
    });
    function setup_map() {
        dispatch(setLoading(true));
        const map = window.app.map;
        let rft_id = info.case_id + '-' + info.nissl_section + '-' + info.staining;
        let rft_id2 = info.case_id.replace('CB', '') + '-' + info.nissl_section + '-' + info.staining;

        map.setRef1(rft_id);
        map.setRef2(rft_id2);
        return;
        /*
            let loading_count = 0;
            src.on('tileloadstart', function() {
                if (loading_count === 0) {
                    let indicator = document.getElementById('loading_indicator');
                    indicator.style.color = '#ffa500';
                    indicator.style.backgroundColor = '#ffa500';
                    indicator.innerHTML = 'Loading';
                }
                loading_count++;
            });
            src.on('tileloadend', function() {
                loading_count--;
                if (loading_count === 0) {
                    let indicator = document.getElementById('loading_indicator');
                    indicator.innerHTML = 'Loaded';
                    indicator.style.color = '#008000';
                    indicator.style.backgroundColor = '#008000';
                }
            });
            let imgCenter = [imgWidth / 2, -imgHeight / 2];
            let extent = [0, -1.5 * imgHeight, 1.5 * imgWidth, 0];
            {
                let w = imgHeight, h = imgWidth;
                extent = [-0.5 * w, -2 * h, 2 * h, 0.5 * w]
                extent = [-4 * w, -4 * h, 4 * h, 4 * w ]
            }
            let view = new View({
                //zoom: typeof localStorage['last_zoom'] !== 'undefined' ? localStorage['last_zoom'] : 1,
                zoom: 0,
                minZoom: -4,
                maxZoom: meta.levels + 4,
                projection: pixelProjection,
                center: imgCenter,
                extent: extent,
                smoothExtentConstraint: false,
            });
            const scaleLineControl = new ScaleLine();
            const overviewMapControl = new OverviewMap({
                layers: [new TileLayer({
                    source: src,
                    projection: pixelProjection,
                })]
            if (lastLabels.length > 0) {
                let id = lastLabels[lastLabels.length - 1];
                if (id) {
                    let label = labels.find(v => v.id === id);
                    setLabel(label);
                    labelRef.current = label;
                    onStartClicked();
                }
                }
                */
    }

    function adjustOpacity(value, min=0., max=1.)  {
        setOpacity(opacity => {
            let o = opacity;
            if (value === null) {
                // flip the opacity value and set layer to 0;
                if (o > 0) {
                    polyLayerRef.current.setOpacity(0);
                    o = -o;
                } else {
                    polyLayerRef.current.setOpacity(-o);
                    o = -o;
                }
            } else {
                o += value;
                if (o > 1) {
                    o = 1.;
                } else if (o < 0) {
                    o = 0.;
                }
                polyLayerRef.current.setOpacity(o);
            }
            localStorage.setItem('last_opacity', JSON.stringify(o));
            return o;
        });
    }

    useEffect(() => {
        let map = new DoubleMap({
            dispatch,
            addPolygon,
            section_id,
            autosave: autosave,
            snap: snap,
            wheel_zoom: wheel_zoom,
        });
        window.app.map = map;
        if (parseInt(section_id, 10) != section_id) {
            fetch('/api/layer/case/' + section_id)
            .then(res => {
                if (!res.ok) {
                    alert('No such case ' + section_id + ' in database!');
                    throw Error(res.statusText);
                } else {
                    return res.json();
                }
            })
            .then(data => {
                //history.push(data.url);
                window.location.href = data.url;
            });
            return;
        }

        let o;
        try {
            o = JSON.parse(localStorage['last_alt_opacity']);
        } catch (e) {
            console.log('error parsing last_alt_opacity', e);
        }
        if (o === undefined || o === null) {
            o = 0.25;
        }

        setOpacity(o);
        dispatch(getSectionInfo(section_id));
        dispatch(getSections(section_id));
        dispatch(getLabels());
        window.addEventListener('beforeunload', e => {
            if (dirtyRef.current) {
                e.preventDefault();
                let confirmationMessage = 'There are unsaved changed in this page, ' +
                    'if you leave before saving, your changes will be lost.';
                (e || window.event).returnValue = confirmationMessage; //Gecko + IE
                return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.

            }
        });
    }, [dispatch])

    useEffect(() => {
        dirtyRef.current = mapDirty;
        hideAllRef.current = hideAll;
        enlargeRef.current = enlarge;
        opacityRef.current = opacity;
    }, [mapDirty, hideAll, enlarge, opacity]);

    useEffect(() => {
        if (info && labels?.length > 0) {
            setup_map(info);
        }
    }, [dispatch, info, labels]);


    useEffect(() => {
        if (sections) {
            const film = document.getElementById('filmstrip_container');
            const target = document.getElementById('thumbnail_' + section_id);
            if (target) {
                const left = target.offsetLeft;
                const w = window.innerWidth;
                let l = left - w / 2;
                if (l < 0) {
                    l = 0;
                }
                film.scrollTo({
                    top: 0,
                    left: l,
                    behavior: 'auto'
                });
            }
            sectionsRef.current = sections;
        }
    }, [dispatch, sections]);
    const updateOffsetX = e => {
        const map = window.app.map;
        let v = parseInt(e.target.value, 10);
        if (!isNaN(v)) {
            map.offset[0] = v;
            map.updateOffset();
        }
    }
    const updateOffsetY = e => {
        const map = window.app.map;
        let v = parseInt(e.target.value, 10);
        if (!isNaN(v)) {
            map.offset[1] = v;
            map.updateOffset();
        }
    }
    const shiftLeft = e => {
        const map = window.app.map;
        map.offset[0] += 10;
        map.updateOffset();
    }
    const shiftRight = e => {
        const map = window.app.map;
        map.offset[0] -= 10;
        map.updateOffset();
    }
    const shiftUp = e => {
        const map = window.app.map;
        map.offset[1] -= 10;
        map.updateOffset();
    }
    const shiftDown = e => {
        const map = window.app.map;
        map.offset[1] += 10;
        map.updateOffset();
    }
    const enlargeCanvas2 = e => {
        const map = window.app.map;
        if (enlargeRef.current) {
            setEnlarge(false);
            window.setTimeout(() => {
                map.map2.updateSize();
            }, 100);
        } else {
            setEnlarge(true);
            window.setTimeout(() => {
                map.map2.updateSize();
            }, 100);
        }
    }
    /*
    <div className="offsets">
        <span className="unicode-button" onClick={shiftLeft}>{'\u2B9C'}</span>
        <span className="unicode-button" onClick={shiftUp}>{'\u2B9D'}</span>
        <span className="unicode-button" onClick={shiftDown}>{'\u2B9F'}</span>
        <span className="unicode-button" onClick={shiftRight}>{'\u2B9E'}</span>
        <input type="text" onChange={updateOffsetX}/>
        <input type="text" onChange={updateOffsetY}/>
    </div>
    */
    let w, h;
    if (enlarge) {
        w = '100vw';
        h = '100vh';

    } else {
        w = '0vw';
        h = '0vh';
    }
    let keyMap = {
        a: 'a',
        s: 's',
        d: 'd',
        g: 'g',
        O: 'Shift+O',
        DEL: ['del', 'Backspace'],
        SAVE: ['Shift+S', 'Space'],
        ZOOM_IN: '+',
        ZOOM_OUT: '-',
        RIGHT: 'ArrowRight',
        LEFT: 'ArrowLeft',
        L: 'Shift+L',
        FINISH: 'enter',
        Z: 'z',
        C: 'c',
        X: 'x',
        h: 'h',
        ESC: ['Esc'],
        eight: '8',
        two: '2',
        four: '4',
        six: '6',
        seven: '7',
        nine: '9',
        zero: '0',
        one: '1',
        three: '3',
    }
    let handlers = {
        a: (e) => {
            if (e.key === 'A') {
                return;
            }

            const map = window.app.map;
            map.adjustOpacity(-0.05);
        },
        s: (e) => {
            if (e.key === 'S') {
                return;
            }
            const map = window.app.map;
            map.adjustOpacity(null);
        },
        d: (e) => {
            if (e.key === 'D') {
                return;
            }
            const map = window.app.map;
            map.adjustOpacity(0.05);
        },
        g: (e) => {
            const map = window.app.map;
            map.toggleVector();
        },
        DEL: async e => {
            dispatch(removeVertex());
            const map = window.app.map;
            if (map.autosave) {
                await dispatch(save(section_id));
                toast.success('Annotation saved.', {position: 'top-center', autoClose: 5000, draggable: true});
            }
        },
        SAVE: async e => {
            await dispatch(save(section_id));
            toast.success('Annotation saved.', {position: 'top-center', autoClose: 5000, draggable: true});
        },
        ZOOM_IN: e => {
            console.log('plus');
            const map = window.app.map;
            map.map2.getView().adjustZoom(0.01);
        },
        ZOOM_OUT: e => {
            const map = window.app.map;
            map.map2.getView().adjustZoom(-0.01);
        },
        RIGHT: e => {
            let idx = sectionsRef.current.findIndex(s => {
                return s.id == section_id;
            });
            if (idx === sectionsRef.current.length - 1) {
                toast('Already the last section of the case', {
                    position: 'top-center', autoClose: 5000, draggable: true
                });
            } else {
                let next = sectionsRef.current[idx + 1];

                window.location.href = '/layer/' + next.id;
            }
        },
        LEFT: e => {
            let idx = sectionsRef.current.findIndex(s => {
                return s.id == section_id;
            });
            if (idx === 0) {
                toast('Already the first section of the case', {
                    position: 'top-center', autoClose: 5000, draggable: true
                });
            } else {
                let next = sectionsRef.current[idx - 1];

                window.location.href = '/layer/' + next.id;
            }
        },
        O: e => {
            if (hideAllRef.current) {
                dispatch(setHideAll(false));
            } else {
                dispatch(setHideAll(true));
            }
        },
        L: e => {
            if (lastL && (new Date() - lastL) < 1000) {

                let res = window.confirm('You can pressed L twice, do you want to open developer function - API shortcuts?');
                if (res) {
                    //dispatch(setAPIHelper(true));
                }
            }
            lastL = new Date();
        },
        FINISH: e => {
            const map = window.app.map;
            map.draw_polyline.finishDrawing();
        },
        Z: e => {
            const map = window.app.map;
            let djatoka = map.img_layer1.getSource();
            map.gamma -= 0.1;
            djatoka.gamma = map.gamma;
            djatoka.refresh();
            dispatch(setGamma(map.gamma));
        },
        C: e => {
            const map = window.app.map;
            let djatoka = map.img_layer1.getSource();
            map.gamma += 0.1;
            djatoka.gamma = map.gamma;
            djatoka.refresh();
            dispatch(setGamma(map.gamma));
        },
        X: e => {
            const map = window.app.map;
            let djatoka = map.img_layer1.getSource();
            map.gamma = 1.0;
            djatoka.gamma = map.gamma;
            djatoka.refresh();
            dispatch(setGamma(map.gamma));
        },
        h: e => {
        },
        ESC: e => {
            e.preventDefault();
            const map = window.app.map;
            map.draw_polyline.abortDrawing();
            map.draw_polyline.setActive(false);
            map.drawing = false;
            dispatch(setDrawing(false));
        },
        four: e => {
            const map = window.app.map;
            const view = map.map2.getView();
            let center = view.getCenter();
            view.animate({
                center: [center[0] + 2, center[1]],
                duration: 250
            })

        },
        eight: e => {
            const map = window.app.map;
            const view = map.map2.getView();
            let center = view.getCenter();
            view.animate({
                center: [center[0], center[1] - 2],
                duration: 250
            })

        },
        two: e => {
            const map = window.app.map;
            const view = map.map2.getView();
            let center = view.getCenter();
            view.animate({
                center: [center[0], center[1] + 2],
                duration: 250
            })

        },
        six: e => {
            const map = window.app.map;
            const view = map.map2.getView();
            let center = view.getCenter();
            view.animate({
                center: [center[0] - 2, center[1]],
                duration: 250
            })

        },
        seven: e => {
            const map = window.app.map;
            const view = map.map2.getView();
            view.adjustRotation(-Math.PI/360);
        },
        nine: e => {
            const map = window.app.map;
            const view = map.map2.getView();
            view.adjustRotation(Math.PI/360);
        },
        zero: e => {
            const map = window.app.map;
            enlargeCanvas2();
        },
        one: e => {
            let o = opacityRef.current;
            o -= .1;
            if (o < 0) {
                o = 0;
            }
            setOpacity(o);
            localStorage.setItem('last_alt_opacity', JSON.stringify(o));
        },
        three: e => {
            let o = opacityRef.current;
            o += .1;
            if (o > 1) {
                o = 1;
            }
            setOpacity(o);
            localStorage.setItem('last_alt_opacity', JSON.stringify(o));
        },

    }
    const updateFormMemo = e => {
        setFormMemo(e.target.value);
    };
    const handleUpdateFeatureMemo = (id) => e => {
        console.log('updating', id, formMemo);
        //dispatch(updateMemo(id, e.target.value));
    };
    let debug_info = featuresDump.map(
        f => (
            <div className="feature" key={f.ol_uid} style={{color: `rgb(${f.rgb})`}}>
                <span className="title">{f.title}</span>
                <MemoUpdater key={f.ol_uid} id={f.id} memo={f.memo} />
                <div className="coordinates">{f.coords.map(v => v.map(_v => '[' + parseInt(Math.round(_v[0]), 10) + ', ' + parseInt(Math.round(_v[1] * -1), 10) + ']').join(', '))}</div>
            </div>
        ));
    /* &#x26F6; */
    /*
     *
                <span className="coordinates">{f.coords.map(v => v[0].toFixed(2) + ', ' + v[1].toFixed(2)) }</span>
                */
    return (
        <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
            { loading && <div className="loading">
                <div className="loading-text">Loading please wait...</div>
            </div> }
            <div className="layer-container">
                <div className="canvas1-container" id="canvas1-container">
                </div>
                <div className="canvas2-container" id="canvas2-container"
                    style={{width: w, height: h, opacity: opacity}}
                    >
                    <div className="enlarge" onClick={enlargeCanvas2}>
                        &#x21F1;
                    </div>
                </div>
            </div>
            <UtilityPanel />
            <div className={'filmstrip'} id="filmstrip_container">
                { sections.map(s => <Thumbnail key={s.id} section={s.code} case_id={s.case_id}
                    section_id={s.id} current_section_id={section_id} index={s.stack_index}
                />) }
            </div>
            <Dialog className="layer-meta" open={showPopper} onClose={() => dispatch(setShowPopper(false))}
                aria-labelledby="layer-meta"
            >
                <DialogContent>
                    <div className="title">
                        <div className="main-title">
                            Layer Delineation
                        </div>
                        <div className="sub-title">
                            { label &&
                                <span className="pilot-square" style={{color: `rgb(${label.fill.substring(1, label.fill.length - 1)})`, borderColor: `rgb(${label.stroke.substring(1, label.stroke.length - 1)})`}}>■ </span>
                            }
                        </div>
                    </div>
                    <div className="content-container">
                        <div className="caption">
                        </div>
                        <div className="debug-info">
                            <div className="section">Section {info?.nissl_section}</div>
                            <div className="api">Export API:
                                <a href={'/api/layer/export/' + info?.case_id} target="_blank">
                                    {'/api/layer/export/' + info?.case_id}
                                </a>
                            </div>
                            {debug_info}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </GlobalHotKeys>
    );
}
