import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setLabelEditing, setAreaEditing,
    removeArea, addArea, getCaseSummary, addLabel,
    updateTitle, updateAbbrev, updateRGB, revertLabel,
    LabelContext, removePolygon, chooseLabel, getAreas, updateLabels,
    setAPIHelper
} from './labelMapSlice';
import classNames from 'classnames';
import { Dialog, DialogContent } from '@material-ui/core';
import { GlobalHotKeys, ObserveKeys, HotKeys } from 'react-hotkeys';
import * as d3 from 'd3';

//import { useForm } from 'react-hook-form'

import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';

let margin = {top: 30, right: 10, bottom: 10, left: 60};
function getOffset(me, parent) {
    const pbox = parent.getBoundingClientRect();
    const box = me.getBoundingClientRect();
    return {
        left: box.left - pbox.left,
        top: box.top - pbox.top,
        right: pbox.right - box.right

    }
}

export default function APIPanel() {
    const dispatch = useDispatch();
    useEffect(() => {
    }, [ dispatch ]);
    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 'none'
        },
    };
    const { apiHelper, info } = useSelector(state => state.labelMap);
    const openModal = () => {
        //setTimeout(afterOpenModal, 1000);
    }
    const closeModal = () => {
        dispatch(setAPIHelper(false));
        d3.select('.tooltip').style('display', 'none');
    }
    const afterOpenModal = () => {
    }
    const setShowPopper = (state) => {

    }
    return info && (
        <div className="api-helper">
            <Dialog
                className="label-summary"
                open={apiHelper}
                style={modalStyles}
                onClose={closeModal}
                TransitionProps={{
                    onEntered: afterOpenModal
                }}
                aria-labelledby="label-summary"
                >
                <DialogContent
                    className="modal-content"
                    >
                    <div className="title">
                        <div className="main-title">
                            API Shortcuts for debugging
                        </div>
                        <div className="sub-title">
                        </div>
                    </div>
                    <div className="api-helper-content">
                        <ol>
                            <li>
                                <a href={`/api/label/export/${info.case_id}`} target="_blank">
                                    Export polygons of this case
                                </a>
                            </li>
                            <li><a href={`/api/label/summary/${info.case_id}`} target="_blank">
                                    Export label summary for this case
                                </a>
                            </li>
                            <li><a href={`/api/label/case_summary`} target="_blank">
                                    List which cases are plotted with a type of label group by label ids
                                </a>
                            </li>
                            <li><a href={`/api/label/export_xls`} target="_blank">
                                    Export label map definitions in excel format
                                </a>
                            </li>
                            <li><a href={`/api/label/sections/${info.section_id}`} target="_blank">
                                    Export section id and code in stack index order
                                </a>
                            </li>
                            <li><a href={`/api/label/cases`} target="_blank">
                                    List all cases with has a label
                                </a>
                            </li>
                            <li><a href={`/api/label/section_info/${info.section_id}`} target="_blank">
                                    Export metadata of current section
                                </a>
                            </li>
                        </ol>
                    </div>
                    <div className="tooltip">
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
