import { createSlice } from '@reduxjs/toolkit';
import { getInspectInjections as apiGetInjections } from '../../api/API';

export const cropSlice = createSlice({
    name: 'inspect',
    initialState: {
        injections: []
    },
    reducers: {
        setInjections: (state, action) => {
            state.injections = action.payload;
        }
    },
});

export const { setInjections } = cropSlice.actions;

export const getInjections = () => async dispatch => {
    let data = await apiGetInjections()
    dispatch(setInjections(data.injections));
};

export default cropSlice.reducer;
