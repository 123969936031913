import axios from 'axios'

export const API_HOST = process.env.REACT_APP_API || 'marmo.mrosa.org'

axios.defaults.baseURL = `https://${API_HOST}`
//axios.defaults.headers.common['content-type'] = 'application/json'
axios.defaults.headers.common['accept'] = 'application/json'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.withCredentials = true

const axiosWithAuth = axios.create({
    baseURL: `https://${API_HOST}`,
    timeout: 60000,
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('jwt')
    }
});


export async function processGoogleLogin(id_token) {
    const endpoint = `/api/user/google_login`;
    try {
        const result = await axios.post(endpoint, {
            id_token: id_token,
        });
        axiosWithAuth.defaults.headers['Authorization'] = `Bearer ${result.data.jwt}`
        return result.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function getCases() {
    const endpoint = `/api/crop/cases`;
    try {
        const response = await axiosWithAuth.get(endpoint, {
            filter: null
        });
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function getCaseDetail(case_id) {
    const endpoint = `/api/crop/case/${case_id}`;
    try {
        const response = await axiosWithAuth.get(endpoint, {
            filter: null
        });
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function getSlideDetail(case_id, slide_id) {
    const endpoint = `/api/crop/slide/${case_id}/${slide_id}`;
    try {
        const response = await axiosWithAuth.get(endpoint, {
            filter: null
        });
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function updateCrops(case_id, slide_id, crops) {
    const endpoint = `/api/crop/update_crops/${case_id}/${slide_id}`;
    const response = await axiosWithAuth.post(endpoint, {
        crops
    });
    return response.data;
}

export async function getThumb(case_id) {
    const endpoint = `/api/crop/get_thumb/${case_id}`;
    try {
        const response = await axiosWithAuth.get(endpoint, {
        });
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function cropFull(case_id, section) {
    const endpoint = `/api/crop/crop_full/${case_id}/${section}`;
    try {
        const response = await axiosWithAuth.get(endpoint, {
        });
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function getProcessingQueue(case_id, section) {
    const endpoint = `/api/crop/processing_queue`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}
export async function getProcessingQueueFull(case_id, section) {
    const endpoint = `/api/crop/processing_queue_full`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function getLabels() {
    const endpoint = `/api/label/get`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function getAreas() {
    const endpoint = `/api/label/areas`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function getLabelPolygons() {
    const endpoint = `/api/label/polygons/`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function saveLabelPolygons(section_id, json) {
    const endpoint = `/api/label/polygons/save/${section_id}`;
    try {
        const response = await axiosWithAuth.post(endpoint, json);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function saveLayerPolygons(section_id, json) {
    const endpoint = `/api/layer/polygons/save/${section_id}`;
    try {
        const response = await axiosWithAuth.post(endpoint, json);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}
export async function getLabelSections(section_id) {
    const endpoint = `/api/label/sections/${section_id}`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function getLabelSectionInfo(section_id) {
    const endpoint = `/api/label/section_info/${section_id}`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}
export async function getLayerSectionInfo(section_id) {
    const endpoint = `/api/layer/section_info/${section_id}`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function getLayer2SectionInfo(section_id) {
    const endpoint = `/api/layer2/section_info/${section_id}`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}
export async function getLabelCases() {
    const endpoint = `/api/label/cases`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}
export async function getLayerCases() {
    const endpoint = `/api/layer/cases`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}
export async function getLayer2Cases() {
    const endpoint = `/api/layer2/cases`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}
export async function updateLabelSectionMemo(section_id, memo) {
    const endpoint = `/api/label/section_memo/${section_id}`;
    try {
        const response = await axiosWithAuth.post(endpoint, {
            memo: memo
        });
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}
export async function getInspectInjections() {
    const endpoint = `/api/inspect/injections`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function getFlatmapInjections() {
    const endpoint = `/api/flatmap/injections`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function getInjectionInfo(injection) {
    const endpoint = `/api/flatmap/injection_info/injection`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function getUserInfo() {
    const endpoint = `/api/user/info`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        let url = response.request.responseURL;
        if (url.match(/\/login$/)) {
            throw new Error('Unauthorised');
        }
        return response.data;
    } catch (err) {
        throw err;
    }
}

export async function updateInjectionPaxinosCoordinates(injection, x, y) {
    const endpoint = `/api/flatmap/injection_info/${injection}`;
    try {
        const response = await axiosWithAuth.post(endpoint, {x: x, y: y});
        return response.data;
    } catch (err) {
        throw err;
    }
}

export async function getLabelSummary(case_id) {
    const endpoint = `/api/label/summary/${case_id}`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        throw err;
    }
}

export async function updateAP(case_id, ap) {
    const endpoint = `/api/label/ap/${case_id}`;
    try {
        const response = await axiosWithAuth.post(endpoint, {ap: ap});
        return response.data;
    } catch (err) {
        throw err;
    }
}

export async function getLabelCaseSummary() {
    const endpoint = `api/label/case_summary`;
    try  {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        throw err;
    }
}

export async function updateLabels(labels) {
    const endpoint = `/api/label/update`
    try {
        const response = await axiosWithAuth.post(endpoint, {labels: labels});
        return response.data;
    } catch (err) {
        throw err;
    }
}

export async function getLayerTypes() {
    const endpoint = `/api/layer/types`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}
export async function getLabelNewColor(label_id) {
    const endpoint = `/api/label/new_color/${label_id}`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}
export async function updateOverlayOffsets(section_id, offsets, overlay_on, overlay_opacity) {
    const endpoint = `/api/layer2/overlay_offsets/${section_id}`;
    try {
        const response = await axiosWithAuth.post(endpoint, {
            offsets,
            overlay_on,
            overlay_opacity
        });
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function getOverlayOffsets(section_id) {
    const endpoint = `/api/layer2/overlay_offsets/${section_id}`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function deleteOverlayOffset(section_id, overlay_id) {
    const endpoint = `/api/layer2/overlay_offsets/${section_id}/${overlay_id}`;
    try {
        const response = await axiosWithAuth.delete(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function getCropProgress(section_id) {
    const endpoint = `api/layer2/crop_counting/${section_id}`;
    try {
        const response = await axiosWithAuth.get(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }

}

export async function requestCrop(section_id) {
    const endpoint = `api/layer2/crop_counting/${section_id}`;
    try {
        const response = await axiosWithAuth.post(endpoint);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}
