import React, { useEffect, useState, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import styles from './LabelMap.module.scss';
import {
    getLabels, saveLabelMap, interpolationSelector,
    LabelContext, removePolygon, chooseLabel, setSnap, setWheelZoom
} from './labelMapSlice';
import classNames from 'classnames';
import { GlobalHotKeys } from 'react-hotkeys';
//import { useForm } from 'react-hook-form'

import 'ol/ol.css';
import './labelmap.scss';
import { CSSTransition } from 'react-transition-group';

import { TextField, Dialog, DialogContent } from '@material-ui/core';

import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';
import { updateLabelSectionMemo } from '../../api/API';


function Item({ geometry, properties, highlight }) {
    let color;
    if (highlight === properties.ol_uid) {
        color = `rgb(${properties.rgb})`;
    } else {
        color = '#000';
    }
    let dispatch = useDispatch();
    return (
        <tr data-id={properties.ol_uid} data-delineation-status="Active">
            <td style={{color: color}}>{properties.title}</td>
            <td><span id="memo-text-2706851b-d89a-4e11-908a-5501ed332683"></span></td>
            <td>
                <button className="action btn-delete" onClick={() => {
                    dispatch(removePolygon(properties.ol_uid));
                }}>Delete</button>
            </td>
        </tr>
    );
}

export default function UtilityPanel() {
    const dispatch = useDispatch();
    const { labels, info, mapDirty, lastLabels, hideAll, ap, summary, snap, wheel_zoom } = useSelector(state => state.labelMap);
    const tipRef = useRef();
    //const [ showPopper, setShowPopper ] = useState(false);
    const context = useContext(LabelContext);
    const label = context.label;
    const labelRef = context.labelRef;
    const showList = context.showList;
    const setShowList = context.setShowList;
    const showPopper = context.showPopper;
    const setShowPopper = context.setShowPopper;

    const { polys, highlight } = useSelector(state => state.labelMap);
    const { section_id } = useParams();
    const labelsRef = useRef(null);
    const lastLabelsRef = useRef(null);
    const autocompleteRef = useRef(null);

    useEffect(() => {
        lastLabelsRef.current = lastLabels;
    }, [lastLabels]);
    useEffect(() => {
        labelsRef.current = labels;
    }, [labels]);
    useEffect(() => {
        dispatch(getLabels());
        const listener = e => {
            if (e.key == 'Escape') {
                if (context.completeRef.current === document.activeElement) {
                    context.setLabel(null);
                    labelRef.current = null;
                    if (context.completeRef.current) {
                        context.completeRef.current.blur();
                    }
                }
                context.onDrawStop();

            }
        };
        let handle = window.addEventListener('keyup', listener);
        return () => {
            window.removeEventListener('keyup', listener);
        }
    }, [dispatch]);
    const onShowPopper = () => {
        setShowPopper(true);
    };
    const keyMap = {
        ONE: '1',
        TWO: '2',
        THREE: '3',
        FOUR: '4',
        FIVE: '5',
    };
    const recall = (id) => {
        let value = labelsRef.current.find(v => v.id === id);
        if (value) {
            context.setLabel(value);
            labelRef.current = value;
            /*
            if (value) {
                dispatch(chooseLabel(value.id));
            }
            */

            context.onStartClicked();
        }
    }
    const handlers = {
        CLOSE: () => {
            if (showPopper) {
                setShowPopper(false);
            }
        },
        ONE: e => {
            let last = lastLabelsRef.current.slice();
            let l = last.pop();
            if (l) {
                recall(l);
            }
        },
        TWO: e => {
            let last = lastLabelsRef.current.slice();
            last.pop();
            let l = last.pop();
            if (l) {
                recall(l);
            }
        },
        THREE: e => {
            let last = lastLabelsRef.current.slice();
            last.pop();
            last.pop();
            let l = last.pop();
            if (l) {
                recall(l);
            }
        },
        FOUR: e => {
            let last = lastLabelsRef.current.slice();
            last.pop();
            last.pop();
            last.pop();
            let l = last.pop();
            if (l) {
                recall(l);
            }
        },
        FIVE: e => {
            let last = lastLabelsRef.current.slice();
            last.pop();
            last.pop();
            last.pop();
            last.pop();
            let l = last.pop();
            if (l) {
                recall(l);
            }
        },
    };

    const onSaveClicked = async () => {
        await dispatch(saveLabelMap(section_id));
        toast.success('Label Map Saved.', {position: 'top-center', autoClose: 5000, draggable: true});
    };
    const [memoDirty, setMemoDirty] = useState(false);
    const onMemoChanged = async (e) => {
        setMemoDirty(true);

    };
    const onMemoBlurred = async (e) => {
        await updateLabelSectionMemo(section_id, e.target.value);
        setMemoDirty(false);
    };
    const { autosave, setAutosave } = context;

    const onAutosaveChange = (e) => {
        if (autosave) {
            setAutosave(false);
            localStorage.setItem('last_autosave', false);
        } else {
            setAutosave(true);
            localStorage.setItem('last_autosave', true);
        }
    };
    const onWheelZoomChange = (e) => {
        if (wheel_zoom) {
            dispatch(setWheelZoom(false));
        } else {
            dispatch(setWheelZoom(true));
        }
    };

    const onSnapChange = (e) => {
        if (snap) {
            dispatch(setSnap(false));
        } else {
            dispatch(setSnap(true));
        }
    };

    const backClicked = () => {
        window.location.href = '/label';
    };
    //Label label={'Label Map for ' + info?.case_id + '-' + info?.section_code}of {label && label.title}
    let interpolation = useSelector(interpolationSelector);
    let ref = interpolation.find(v => v?.index === info?.stack_index);
    if (ref) {
        ref = ref.value;
        if (ref >= 0) {
            ref = ref + 'R';
        } else {
            ref = (-ref) + 'C';
        }
    } else {
        ref = 'N/A';
    }
    if (hideAll) {
        return <div className="hide-all-overlay">UI is hidden, press Shift + O to reveal.</div>
    } else {
        return <>
            <div className="info-window">

                <div className={classNames(['utility', 'ol-unselectable'])}>
                <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
                <Dialog className="label-meta" open={showPopper} onClose={() => setShowPopper(false)}
                    aria-labelledby="label-meta"
                >
                    <DialogContent>
                        <div className="title">
                            <div className="main-title">
                                Keyboard shortcuts
                            </div>
                            <div className="sub-title">
                                { label &&
                                    <span className="pilot-square" style={{color: 'rgb(' + label.rgb + ')'}}>■ </span>
                                }
                            </div>
                        </div>
                        <div className="content-container">
                            <div className="caption">
                            </div>
                            <div className="list">
                                Basic usage
                                <ul>
                                    <li>Click Label Map Combox box and start typing to search for a label</li>
                                    <li>Click the label to draw and after the label is selected, the drawing mode is enabled</li>
                                    <li>Click on the histology to start plotting the first vertex, and followed by a few vertices</li>
                                    <li>Double click the last vertex to finish the drawing.</li>
                                    <li>Press Esc key to abandon the current drawing.</li>
                                    <li>If the page has user modifications, the Save button turns yellow. Click save to preserve the changes.</li>
                                </ul>
                                Shortcut keys
                                <ul>
                                    <li><kbd>Esc</kbd> to cancel current drawing or to clear combox typing.</li>
                                    <li><kbd>1</kbd>-<kbd>5</kbd> to quickly select the recent 5 labels used.</li>
                                    <li><kbd>Shift</kbd> + <kbd>C</kbd>, to jump to the label selection combobox and start searching.</li>
                                    <li><kbd>0</kbd> or <kbd>Shift</kbd> + <kbd>S</kbd> or <kbd>Space</kbd> shortcut to save the current drawing.</li>
                                    <li><kbd>a</kbd> Reduce label polygon fill intensity to allow better viewing of histology.</li>
                                    <li><kbd>d</kbd> Increase label polygon fill intensity.</li>
                                    <li><kbd>s</kbd> Quickly toggle label polygon fill.</li>
                                    <li><kbd>Shift</kbd> + <kbd>O</kbd>, to hide or show all UI elements.</li>
                                    <li><kbd>Shift</kbd> + <kbd>D</kbd> Start drawing when not in drawing mode (after previous polygon finished).</li>
                                    <li><kbd>Del</kbd> Hover over vertex and press Del key to remove the vertex. Hover the polygon fill to remove the label polygon</li>
                                    <li><kbd>-</kbd> Zoom out</li>
                                    <li><kbd>+</kbd> Zoom in</li>
                                    <li><kbd>Right</kbd> Move to work on the next histology section.</li>
                                    <li><kbd>Left</kbd> Move to work on the previous histology section.</li>
                                </ul>
                            </div>
                            <div className="note">
                                Hit [<strong>Esc</strong>] to close the window.
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <div className="combo-box-container">
                    <Autocomplete
                        options={labels}
                        getOptionLabel={option => option.title}
                        style={{ width: 300 }}
                        value={label}
                        ref={r => autocompleteRef.current = r}
                        renderInput={params =>
                            <TextField { ...params } inputRef={ref => context.completeRef.current=ref}  variant="outlined" />
                            }
                        renderOption={option =>
                            <div className="label-option">
                                <span className="pilot-square" style={{color: 'rgb(' + option.rgb + ')'}}>■ </span>
                                <span>
                                    {option.title}
                                </span>
                            </div>
                        }
                        onChange={(e, value) => {
                            context.setLabel(value);
                            labelRef.current = value;
                            if (value) {
                                dispatch(chooseLabel(value.id));
                            }

                            context.onStartClicked();
                        }}
                        blurOnSelect={true}
                        filterOptions={createFilterOptions({
                            stringify: option => option.title + option.label_map_abbrev
                        })}
                        />
                    { true &&
                        <div className="combo-info">
                            <div ref={tipRef} data-tip="tooltip" data-iscapture="true"
                                className="tip"
                                onClick={onShowPopper}
                                > ? </div>
                            <button className="back" onClick={backClicked}>Back</button>
                        </div>
                    }
                </div>
                <div className="control">
                    <button className={classNames('btn-start', 'action', {active: context.drawing})}
                        id="ol-delineation-button"
                        disabled={!labelRef.current}
                        onClick={context.onStartClicked}>Draw</button>
                    <button className={classNames('btn-save', 'action', {warn: mapDirty})} id="ol-delineation-save" onClick={onSaveClicked}>Save</button>
                    <button className={classNames('btn-expand', 'action', {expanded: showList, collapsed: !showList})} onClick={() => {
                        if (showList) {
                            setShowList(false);
                        } else {
                            setShowList(true);
                        }
                    }}><span className="btn-toggle-meta">&#x2304;</span></button>
                    <div className="autosave">
                        <input type="checkbox" name="autosave" id="autosave" onChange={onAutosaveChange} checked={autosave} value="autosave" /> <label htmlFor="autosave">Autosave</label>
                    </div>
                    <div className="wheel-zoom">
                        <input type="checkbox" name="wheel_zoom" id="wheel_zoom" onChange={onWheelZoomChange} checked={wheel_zoom} value="wheel_zoom" /> <label htmlFor="wheel_zoom">Zoom</label>
                    </div>
                    <div className="ap">{ref}</div>
                    { /*
                    <div className="snap">
                        <input type="checkbox" name="snap" id="snap" onChange={onSnapChange} checked={snap} value="snap" /> <label htmlFor="snap">Snap</label>
                    </div>
                    */ }

                    { showList &&
                        <CSSTransition
                            in={showList} timeout={5000} classNames="label-list">
                            <div className="delineation-list" >
                                <table className="delineation-list">
                                    <thead>
                                        <tr>
                                            <th>Item</th><th>Memo</th><th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { polys.map(p => <Item key={p.properties.ol_uid} highlight={highlight} { ...p }/>) }
                                    </tbody>
                                </table>
                            </div>
                        </CSSTransition>
                    }
                </div>
            </div>
        </div>
        <div className="section-memo">
            <textarea
                placeholder="Per section memo"
                defaultValue={info?.memo}
                className={classNames({dirty: memoDirty})}
                onChange={onMemoChanged}
                onBlur={onMemoBlurred}
                ></textarea>
        </div>
        </>
    }
}
