import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

import { getLabels as apiGetLabels,
    getLabelSections as apiGetSections,
    getLayer2SectionInfo as apiGetSectionInfo2,
    updateLabels as apiUpdateLabels,
    getLayerTypes as apiGetLayerTypes,
    saveLayerPolygons as apiSaveLayerPolygons,
    getLayerCases as apiGetLayerCases,
    getLayer2Cases as apiGetLayer2Cases,
    updateOverlayOffsets as apiUpdateOverlayOffsets,
    getOverlayOffsets as apiGetOverlayOffsets,
    deleteOverlayOffset as apiDeleteOverlayOffset,
    getCropProgress as apiGetCropProgress,
    requestCrop as apiRequestCrop,
} from '../../api/API';
import { createSelector } from 'reselect';

import { Collection, Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { LineString, Polygon } from 'ol/geom';
import { cmpalphanum } from '../lib';

export const LayerContext = React.createContext();
export const polyCollection = new Collection();
export const countingBoxCollection = new Collection();
export let modifyObject = {};

let geojson = new GeoJSON();

function getLocalStorage(key, def) {
    let value;
    try {
        value = JSON.parse(localStorage.getItem(key));
    } catch (e) {
        value = def;
    }
    if (value === null) {
        value = def;
    }
    return value;
}
export const layerSlice2 = createSlice({
    name: 'layer2',
    initialState: {
        labels: [],
        autosave: getLocalStorage('autosave', false),
        drawing: false,
        hideAll: getLocalStorage('hide_all'),
        labelSelected: null,
        mapDirty: false,
        polylist: [],
        removal_list: [],
        sections: [],
        info: null,
        gamma: 1.0,
        loading: true,
        caseIds: [],
        cases: [],
        showPopper: false,
        showOverlayPopper: false,
        featuresDump: [],
        snap: getLocalStorage('snap', true),
        wheel_zoom: getLocalStorage('wheel_zoom', true),
        overlayOffsets: [],
        overlaySelected: null,
        adjustOverlay: true,
        mapInit: false,
        hideBox: getLocalStorage('hide_box', false),
        hideCircle: false,
        cropProgress: {},
        overlayOn: false,
        overlayOpacity: 0.8,
        blurPx: 0,
    },
    reducers: {
        setLabels: (state, action) => {
            const map = window.app.map;
            state.labels = action.payload;
            if (map) {
                map.labels = action.payload;
            }
        },
        setHideAll: (state, action) => {
            state.hideAll = action.payload;
            localStorage.setItem('last_hide_all', JSON.stringify(action.payload));
        },
        setDrawing: (state, action) => {
            state.drawing = action.payload;
        },
        setLabelSelected: (state, action) => {
            const map = window.app.map;
            if (map) {
                map.selectLabel(action.payload);
            }
            state.labelSelected = action.payload;
            localStorage.setItem('label_selected', JSON.stringify(action.payload));
        },
        setMapDirty: (state, action) => {
            state.mapDirty = action.payload;
        },
        setPolylist: (state, action) => {
            state.polylist = action.payload;
        },
        setRemovalList: (state, action) => {
            state.removal_list = action.payload;
        },
        setSections: (state, action) => {
            state.sections = action.payload;
        },
        setInfo: (state, action) => {
            state.info = action.payload;
        },
        setGamma: (state, action) => {
            state.gamma = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setAutosave: (state, action) => {
            const map = window.app.map;
            if (map) {
                map.autosave = action.payload;
            }
            state.autosave = action.payload;
            localStorage.setItem('autosave', action.payload);
        },
        setWheelZoom: (state, action) => {
            const map = window.app.map;
            /*
            if (map) {
                map.wheel_zoom = action.payload;
            }
            */
            state.wheel_zoom = action.payload;
            localStorage.setItem('wheel_zoom', action.payload);
        },
        setSnap: (state, action) => {
            const map = window.app.map;
            /*
            if (map) {
                //map.autosave = action.payload;
            }
            */
            state.snap = action.payload;
            localStorage.setItem('snap', action.payload);
        },
        setCaseIds: (state, action) => {
            state.caseIds = action.payload;
        },
        setCases: (state, action) => {
            state.cases = action.payload;
        },
        setShowPopper: (state, action) => {
            state.showPopper = action.payload;
        },
        setShowOverlayPopper: (state, action) => {
            state.showOverlayPopper = action.payload;
        },
        setFeaturesDump: (state, action) => {
            state.featuresDump = action.payload;
        },
        setOverlayOffsets: (state, action) => {
            state.overlayOffsets = action.payload;
        },
        setOverlaySelected: (state, action) => {
            state.overlaySelected = action.payload;
            const map = window.app.map;
            if (map) {
            }
        },
        setAdjustOverlay: (state, action) => {
            state.adjustOverlay = action.payload;
        },
        setMapInit: (state, action) => {
            state.mapInit = action.payload;
        },
        setHideBox: (state, action) => {
            if (action.payload) {
                // move the box items in polyCollections to countingBox backup.
                polyCollection.forEach(p => {
                    if (p.get('type_id') === 7) {
                        countingBoxCollection.push(p);
                    }
                });
                countingBoxCollection.forEach(p => {
                    polyCollection.remove(p);
                });
                localStorage.setItem('hide_box', JSON.stringify(action.payload));
            } else {
                countingBoxCollection.forEach(p => {
                    polyCollection.insertAt(0, p);
                });
                countingBoxCollection.clear();
                localStorage.setItem('hide_box', JSON.stringify(action.payload));
            }

            state.hideBox = action.payload;
        },
        setHideCircle: (state, action) => {
            const { app } = window;
            const map = app.map;
            if (action.payload) {
                map.hideCircle = true;
                map.vs1.changed();
            } else {
                map.hideCircle = false;
                map.vs1.changed();
            }
            state.hideCircle = action.payload;
        },
        setCropProgress: (state, action) => {
            state.cropProgress = action.payload;
        },
        setOverlayOn: (state, action) => {
            state.overlayOn = action.payload;
        },
        setOverlayOpacity: (state, action) => {
            state.overlayOpacity = action.payload;
        },
        setBlurPx: (state, action) => {
            state.blurPx = action.payload;
        },
    },
});
export const {
    setLabels, setHideAll, setAutosave, setDrawing, setLabelSelected,
    setMapDirty, setPolylist, setSections, setInfo, setGamma, setLoading,
    setCaseIds, setCases, setShowPopper, setFeaturesDump, setSnap, setWheelZoom,
    setOverlayOffsets, setOverlaySelected, setShowOverlayPopper,
    setAdjustOverlay, setMapInit, setHideBox, setHideCircle,
    setCropProgress, setOverlayOn, setOverlayOpacity, setBlurPx,
    setRemovalList,
} = layerSlice2.actions;

export const getLabels = () => async (dispatch) => {
    let result = await apiGetLayerTypes();
    await dispatch(setLabels(result.labels));
    let selected = getLocalStorage('label_selected');
    if (!selected) {
        dispatch(setLabelSelected(result.labels[0]));
    }
    dispatch(setLabelSelected(selected));
}
export const save = (section_id) => async (dispatch, getState) => {
    const state = getState().layer2;
    if (state.loading) {
        alert('Previous saving is still in progress, please retry later');
        return;
    }
    await dispatch(setLoading(true));
    let json = geojson.writeFeaturesObject(polyCollection.getArray());
    json.removal_list = state.removal_list;

    await apiSaveLayerPolygons(section_id, json);
    dispatch(setMapDirty(false));
    dispatch(setRemovalList([]));
    await dispatch(setLoading(false));
}
window.collection = polyCollection;
export const addPolygon = f => async (dispatch) => {
    polyCollection.push(f);
    let json = geojson.writeFeaturesObject(polyCollection.getArray());
    dispatch(setPolylist(json.features));
    dispatch(setMapDirty(true));
}
export const removePolygon = (uid) => async (dispatch, getState) => {
    let target = uid;
    let { highlight } = getState().layer;
    if (uid === -1) {
        target = highlight;
    } else {
        target = uid;
    }
    if (target) {
        let to_remove = [];
        polyCollection.forEach(v => {
            if (v.ol_uid === target) {
                to_remove.push(v);
            }
        })
        to_remove.forEach(v => {
            polyCollection.remove(v);
        });
        let json = geojson.writeFeaturesObject(polyCollection.getArray());
        dispatch(setPolylist(json.features));
        dispatch(setMapDirty(true));
    }
}

export const removeVertex = () => async (dispatch, getState) => {
    let { vertex, polygon } = modifyObject;
    let { removal_list } = getState().layer2;
    console.log('modifyobj', polygon, vertex);
    if (!polygon) {
        return;
    }
    if (!vertex) {
        let target = polygon.ol_uid;
        let to_remove = [];
        polyCollection.forEach(v => {
            if (v.ol_uid === target) {
                to_remove.push(v);
            }
        })
        to_remove.forEach(v => {
            polyCollection.remove(v);
        });
        dispatch(setRemovalList(removal_list.concat(to_remove.map(v => v.get('id')))));

    } else {
        let vertexCoord = vertex.getGeometry().getCoordinates();
        let polygonGeom = polygon.getGeometry();
        if (polygonGeom.getType() === 'Polygon') {
            let coords = polygonGeom.getCoordinates();
            let newCoords = coords[0].filter(v => !(v.length === 2 && v[0] === vertexCoord[0] && v[1] === vertexCoord[1]));
            polygonGeom.setCoordinates([newCoords]);
        } else {
            let coords = polygonGeom.getCoordinates();
            let newCoords = coords.filter(v => !(v.length === 2 && v[0] === vertexCoord[0] && v[1] === vertexCoord[1]));
            if (newCoords.length === coords.length || newCoords.length === 1) {
                let target = polygon.ol_uid;
                let to_remove = [];
                polyCollection.forEach(v => {
                    console.log('testing', v.ol_uid, target);
                    if (v.ol_uid === target) {
                        to_remove.push(v);
                    }
                })
                to_remove.forEach(v => {
                    polyCollection.remove(v);
                });
                dispatch(setRemovalList(removal_list.concat(to_remove.map(v => v.get('id')))));
            } else {
                polygonGeom.setCoordinates(newCoords);
            }
        }
    }
    let json = geojson.writeFeaturesObject(polyCollection.getArray());
    dispatch(setPolylist(json.features));
    dispatch(setMapDirty(true));

}

export const getSections = (section_id) => async (dispatch, getState) => {
    let res = await apiGetSections(section_id);
    dispatch(setSections(res.sections));
}

export const getSectionInfo = (section_id) => async (dispatch, getState) => {
    let res = await apiGetSectionInfo2(section_id);
    let hideBox = getState().layer2.hideBox;

    dispatch(setInfo(res.info));

    res.info.layers.forEach(l => {
        let fill = JSON.parse(l.fill);
        let stroke = JSON.parse(l.stroke);
        let opacity;

        opacity = fill[3];
        fill = `${fill[0]}, ${fill[1]}, ${fill[2]}`;
        stroke = `${stroke[0]}, ${stroke[1]}, ${stroke[2]}`;

        let geom;
        if (l.geometry === 'Polygon') {
            geom = Polygon;
        } else if (l.geometry === 'LineString') {
            geom = LineString;
        }

        let f = new Feature({
            name: 'layer',
            geometry: new geom(l.coords)
        });
        f.set('name', 'layer');
        f.set('id', l.id);
        f.set('highlight', false);
        f.set('type_id', l.type_id);
        f.set('ol_uid', f.ol_uid);
        f.set('title', l.title);
        f.set('rgb', fill);
        f.set('stroke', stroke);
        f.set('opacity', opacity);
        f.set('memo', l.memo);
        if (f.get('type_id') === 7 && hideBox) {
            countingBoxCollection.push(f);
        } else {
            polyCollection.push(f);
        }
    });
    let json = geojson.writeFeaturesObject(polyCollection.getArray());
    dispatch(setPolylist(json.features));
    dispatch(setMapDirty(false));
}

export const getLayerCases = () => async (dispatch, getState) => {
    let res = await apiGetLayerCases();
    dispatch(setCaseIds(res.case_ids));
    dispatch(setCases(res.cases));
}

export const getLayer2Cases = () => async (dispatch, getState) => {
    let res = await apiGetLayer2Cases();
    dispatch(setCaseIds(res.case_ids));
    dispatch(setCases(res.cases));
}
export const selectCJCases = createSelector(
    state => state.layer2.cases,
    (cases) => {
        console.log('all cases', cases);
        let l = Object.keys(cases).filter(v => v.startsWith('CJ')).sort(cmpalphanum);
        let res = [];
        l.forEach(i => {
            if (cases[i].labels.length > 0) {
                res.push({
                    case_id: i,
                    labels: cases[i].labels
                });
            }
        });
        return res;
    }
);
export const selectWCases = createSelector(
    state => state.layer2.cases,
    (cases) => {
        let l = Object.keys(cases).filter(v => v.startsWith('W')).sort(cmpalphanum);
        let res = [];
        l.forEach(i => {
            if (cases[i].labels.length > 0) {
                res.push({
                    case_id: i,
                    labels: cases[i].labels
                });
            }
        });
        return res;
    }
);
export const selectCandidateCases = createSelector(
    state => state.layer2.cases,
    (cases) => {
        let l = Object.keys(cases).sort(cmpalphanum);
        let res = [];
        l.forEach(i => {
            res.push({
                case_id: i,
                labels: cases[i]
            });
        });
        return res;
    }
);

export const setFeatureMemo = (featureId, memo) => async (dispatch, getState) => {
    /*
    res.info.layers.forEach(l => {
        let fill = JSON.parse(l.fill);
        let stroke = JSON.parse(l.stroke);
        let opacity;

        opacity = fill[3];
        fill = `${fill[0]}, ${fill[1]}, ${fill[2]}`;
        stroke = `${stroke[0]}, ${stroke[1]}, ${stroke[2]}`;
        console.log('!!!!!!!!!!!!!!!!!opacity is', opacity);

        let geom;
        if (l.geometry === 'Polygon') {
            geom = Polygon;
        } else if (l.geometry === 'LineString') {
            geom = LineString;
        }

        let f = new Feature({
            name: 'layer',
            geometry: new geom(l.coords)
        });
        f.set('name', 'layer');
        f.set('id', l.id);
        f.set('highlight', false);
        f.set('type_id', l.type_id);
        f.set('ol_uid', f.ol_uid);
        f.set('title', l.title);
        f.set('rgb', fill);
        f.set('stroke', stroke);
        f.set('opacity', opacity);
        f.set('memo', l.memo);
        polyCollection.push(f);
    });
    */
    polyCollection.forEach(p => {
        if (p.get('id') === featureId) {
            console.log('found it');
            p.set('memo', memo);
        }
    });
    let json = geojson.writeFeaturesObject(polyCollection.getArray());
    dispatch(setPolylist(json.features));
    dispatch(setMapDirty(true));
};

export const addOverlayOffset = (title) => async (dispatch, getState) => {
    await dispatch(setLoading(true));
    let { overlayOffsets } = getState().layer2;
    let newOffset = { index: overlayOffsets.length === 0 ? 0 : Math.max(...overlayOffsets.map(o => o.index)) + 1, title: title };
    let newOffsets = [
        ...overlayOffsets,
        newOffset
    ];
    dispatch(setOverlayOffsets(newOffsets));
    dispatch(setOverlaySelected(newOffset));
    await dispatch(setLoading(false));
};

export const updateOverlayOffsets = (section_id, offsets) => async (dispatch, getState) => {
    await dispatch(setLoading(true));
    const { overlaySelected, overlayOffsets, overlayOn, overlayOpacity } = getState().layer2;
    const map = window.app.map;
    const [x, y] = map.view1.getCenter();
    const main_view = {
        x: x,
        y: y,
        original_center_x: map.original_center_x,
        original_center_y: map.original_center_y,
        rotation: map.view1.getRotation(),
        resolution: map.view1.getResolution(),
        original_resolution: map.original_resolution,
        original_rotation: map.original_rotation,
    };

    let o = overlayOffsets.map((item, index) => {
        if (item.index === overlaySelected.index) {
            return { ...item, offsets: { ...offsets }, main_view: main_view };
        } else {
            return item;
        }
    });

    let result = await apiUpdateOverlayOffsets(section_id, o, overlayOn, overlayOpacity);
    dispatch(setOverlayOffsets(result.offsets));
    dispatch(setOverlaySelected(result.offsets[overlaySelected.index]));
    //dispatch(setOverlayOn(result.overlay_on));
    //dispatch(setOverlayOpacity(result.overlay_opacity));
    await dispatch(setLoading(false));
};

export const getOverlayOffsets = (section_id) => async (dispatch) => {
    let result = await apiGetOverlayOffsets(section_id);
    dispatch(setOverlayOffsets(result.offsets));
};

export const deleteOverlayOffset = (section_id, overlay_id) => async (dispatch, getState) => {
    await dispatch(setLoading(true));
    const { overlaySelected } = getState().layer2;
    if (overlaySelected && overlaySelected.id) {
        let result = await apiDeleteOverlayOffset(section_id, overlay_id);
        dispatch(setOverlayOffsets(result.offsets));
        dispatch(setOverlaySelected(null));
    }
    await dispatch(setLoading(false));
};

export const updateOverlayMemo = (section_id, memo) => async (dispatch, getState) => {
    await dispatch(setLoading(true));
    const { overlaySelected, overlayOffsets } = getState().layer2;
    let target_index = null;
    let o = overlayOffsets.map((item, index) => {
        if (item.id === overlaySelected.id) {
            target_index = index;
            console.log('same found!', memo, item.index);
            return { ...item, offsets: { ...overlaySelected.offsets, 'memo': memo } };
        } else {
            return item;
        }
    });

    let result = await apiUpdateOverlayOffsets(section_id, o);
    dispatch(setOverlayOffsets(result.offsets));
    if (target_index) {
        dispatch(setOverlaySelected(result.offsets[target_index]));
    }
    await dispatch(setLoading(false));
};

export const notifyCountingEvent = (data) => async (dispatch, getState) => {
    console.log('event received', data);
};

export const getCropProgress = (section_id) => async (dispatch, getState) => {
    let result = await apiGetCropProgress(section_id);
    dispatch(setCropProgress(result));
};

export const requestCrop = (section_id) => async (dispatch, getState) => {
    let result = await apiRequestCrop(section_id);
};

export default layerSlice2.reducer;


