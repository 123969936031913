import { createSlice } from '@reduxjs/toolkit';
import {
    getFlatmapInjections as apiGetInjections,
    getInjectionInfo as apiGetInjectionInfo,
    updateInjectionPaxinosCoordinates as apiUpdateInjectionPaxinosCoordinates
} from '../../api/API';

export const flatmapSlice = createSlice({
    name: 'flatmap',
    initialState: {
        injections: [],
        injectionInfo: null
    },
    reducers: {
        setInjections: (state, action) => {
            state.injections = action.payload;
        },
        setInjectionInfo: (state, action) => {
            state.injectionInfo = action.payload;
        }
    },
});

export const { setInjections, setInjectionInfo } = flatmapSlice.actions;

export const getInjections = () => async dispatch => {
    let data = await apiGetInjections();
    dispatch(setInjections(data.injections));
};

export const getInjectionInfo = (inj) => async dispatch => {
    let data = await apiGetInjectionInfo();
    dispatch(setInjectionInfo(data.injection));
};

export const updateInjectionPaxinosCoordinates = (injection, x, y) => async dispatch => {
    await apiUpdateInjectionPaxinosCoordinates(injection, x, y);
};

export default flatmapSlice.reducer;
