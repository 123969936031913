import { createSlice } from '@reduxjs/toolkit';
import {
    getCases as apiGetCases, getCaseDetail as apiGetCaseDetail,
    getSlideDetail as apiGetSlideDetail,
    getThumb as apiGetThumb, cropFull as apiCropFull,
    updateCrops as apiUpdateCrops,
    getProcessingQueue as apiGetProcessingQueue,
    getProcessingQueueFull as apiGetProcessingQueueFull

} from '../../api/API';
import { createSelector } from 'reselect';
import { toast } from 'react-toastify';

import _ from 'lodash'

export const cropSlice = createSlice({
    name: 'crop',
    initialState: {
        cases: [],
        caseDetail: null,
        slideDetail: null,
        slideDetailSnapsot: null,
        processingQueue: [
        ],
        processingQueueFull: [
        ],
    },
    reducers: {
        setCases: (state, action) => {
            state.cases = action.payload;
        },
        setCaseDetail: (state, action) => {
            state.caseDetail = action.payload;
        },
        setSlideDetail: (state, action) => {
            state.slideDetail = action.payload;
        },
        storeSlideDetail: (state, action) => {
            state.slideDetailSnapshot = _.cloneDeep(state.slideDetail);
        },
        recallSlideDetail: (state, action) => {
            state.slideDetail = _.cloneDeep(state.slideDetailSnapshot);
        },
        removeCrop: (state, action) => {
            for (let c of state.slideDetail.crops) {
                if (c.id === action.payload) {
                    c.deleted = true;
                    c.dirty = true;
                }
            }
        },
        updateCrop: (state, action) => {
            let payload = action.payload;
            for (let c of state.slideDetail.crops) {
                if (c.id === payload.id) {
                    for (let attr of payload.attrs) {
                        c[attr] = payload.values[attr];
                        c.dirty = true;
                    }
                }
            }
        },
        addCrop: (state, action) => {
            let crop = action.payload;
            state.slideDetail.crops.push(crop);
        },
        finishCrop: (state, action) => {
            let payload = action.payload;
            for (let c of state.slideDetail.crops) {
                if (c.id === payload) {
                    c.status = 'Done';
                }
            }

        },
        startCrop: (state, action) => {
            let payload = action.payload;
            for (let c of state.slideDetail.crops) {
                if (c.id === payload) {
                    c.status = 'Cropping';
                }
            }
        },
        setProcessingQueue: (state, action) => {
            state.processingQueue = action.payload;
        },
        setProcessingQueueFull: (state, action) => {
            state.processingQueueFull = action.payload;
        },
    },
});

export const {
    setCases, setCaseDetail,
    storeSlideDetail, recallSlideDetail,
    setSlideDetail, removeCrop, updateCrop, addCrop,
    finishCrop, startCrop, setProcessingQueue, setProcessingQueueFull
} = cropSlice.actions;

export const getCases = () => async dispatch => {
    let data = await apiGetCases()
    dispatch(setCases(data));
};

export const getCaseDetail = (case_id) => async dispatch => {
    let data = await apiGetCaseDetail(case_id)
    dispatch(setCaseDetail(data));
};

export const getSlideDetail = (case_id, slide_id) => async dispatch => {
    let data = await apiGetSlideDetail(case_id, slide_id);
    await dispatch(setSlideDetail(data));
    await dispatch(storeSlideDetail());
};
//export const selectCases = state => state.crop.cases;


export const moveCrop = (crop_id, direction, delta) => async (dispatch, getState) => {
    let state = getState().crop;

    let detail = { ...state.slideDetail };
    detail.crops = [];
    for (let c of state.slideDetail.crops) {
        let _c = { ...c };
        if (_c.id === crop_id) {
            switch (direction) {
                case 'left':
                    _c.x -= delta;
                    break;
                case 'right':
                    _c.x += delta;
                    break;
                case 'up':
                    _c.y -= delta;
                    break;
                case 'down':
                    _c.y += delta;
                    break;
                default:
                    break;
            }
            _c.dirty = true;
        }
        detail.crops.push(_c);
    }
    dispatch(setSlideDetail(detail));

};

export const flipCrop = (crop_id, value) => async (dispatch, getState) => {
    let state = getState().crop;
    let detail = { ...state.slideDetail };
    detail.crops = [];
    for (let c of state.slideDetail.crops) {
        let _c = { ...c };
        if (_c.id === crop_id) {
            _c.hflip = value;
            _c.dirty = true;
        }
        detail.crops.push(_c);
    }
    dispatch(setSlideDetail(detail));
};


export const updateCrops = () => async (dispatch, getState) => {
    let slide = getState().crop.slideDetail;
    try {
        let data = await apiUpdateCrops(slide.case_id, slide.slide_id, slide.crops);

        await dispatch(setSlideDetail(data));
        await dispatch(storeSlideDetail());
        toast('Saved.')
    } catch (err) {
        if (err.response.status === 403) {
            toast(err.response.data.detail, {autoClose: false});
        }
    }
}

function sortBy(field, numeric = false) {
    return function(a, b) {
        let va = a[field];
        let vb = b[field];
        if (numeric) {
            va = parseFloat(va)
            vb = parseFloat(vb)
        }
        return (va > vb) - (va < vb)
    };
}

let case_regex = /([a-z]+)(\d+)([a-z]?)(_.*)?/i;
function sortByCaseId(a, b) {
    let ma = a.case_id.match(case_regex);
    let mb = b.case_id.match(case_regex);
    if (ma && mb) {
        let case_a = ma[1];
        let case_b = mb[1];
        if (case_a < case_b) {
            return -1;
        } else if (case_a > case_b) {
            return 1;
        } else {
            let va = parseFloat(ma[2])
            let vb = parseFloat(mb[2])
            if (va === vb) {
                va = ma[3]
                vb = mb[3]
                return (va > vb) - (va < vb)

            } else {
                return (va > vb) - (va < vb)
            }

        }
    } else {
        if (!ma) {
            return 1;
        } else {
            return -1;
        }
    }
}

function sortByMemo(a, b) {
    if (!a.memo) {
        return -1;
    } else if (!b.memo) {
        return 1;
    } else {
        return sortByCaseId(a, b);
    }
}
function sortByProgress(a, b) {
    let fa = a.pending;
    let fb = b.pending;
    if (fa === fb) {
        fa = a.total;
        fb = b.total;
        if (fa === fb) {
            return sortByCaseId(a, b);
        } else {
            return (fa > fb) - (fa < fb);
        }
    } else {
        return (fa > fb) - (fa < fb);
    }

}
function sortByStaining(a, b) {
    let va = a.case_id.includes('_') ? a.case_id.split('_')[a.case_id.split('_').length - 1] : 'ZZZ';
    let vb = b.case_id.includes('_') ? b.case_id.split('_')[b.case_id.split('_').length - 1] : 'ZZZ';
    return (va > vb) - (va < vb);
}
let slide_match = /_([rc])(\d+)$/i
function sortBySlideName(a, b) {
    let ma = a.slide_id.match(slide_match);
    if (!ma) {
        return 1;
    }
    let mb = b.slide_id.match(slide_match);
    if (!mb) {
        return -1;
    }
    let ba = ma[1].toLowerCase();
    let bb = mb[1].toLowerCase();
    let sa = parseInt(ma[2], 10);
    let sb = parseInt(mb[2], 10);
    if (ba === 'r' && bb === 'c') {
        return -1;
    } else if (ba === 'c' && bb === 'r') {
        return 1;
    } else if (ba === 'r') {
        return (sa > sb) - (sa < sb);
    } else if (ba === 'c') {
        return (sa < sb) - (sa > sb);
    }
}

function sortBySlideId(a, b) {
    let va = a.slide_id.includes('_') ? parseInt(a.slide_id.split('_')[0], 10) : 'ZZZ';
    let vb = b.slide_id.includes('_') ? parseInt(b.slide_id.split('_')[0], 10) : 'ZZZ';
    return (va > vb) - (va < vb);
}
function sortBySize(a, b) {
    let va = parseFloat(a.thumbnail_width) * parseFloat(a.thumbnail_height);
    let vb = parseFloat(b.thumbnail_width) * parseFloat(b.thumbnail_height);
    return (va > vb) - (va < vb);
}
export const selectCases = createSelector(
    (state) => state.crop.cases,
    (state, sortBy) => sortBy,
    (state, sortBy, order) => order,
    (cases, sortBy, order) => {
        let ret = [...cases]
        switch (sortBy) {
            case 'case_id': {
                ret.sort(sortByCaseId);
                break;
            }
            case 'progress': {
                ret.sort(sortByProgress);
                break;
            }
            case 'memo': {
                ret.sort(sortByMemo);
                break;
            }
            case 'staining': {
                ret.sort(sortByStaining);
                break;
            }
            default: {
                ret.sort(sortByCaseId);
                break;

            }
        }
        if (order === 'desc') {
            ret.reverse();

        }
        return ret;
    }
)

export const selectCase = createSelector(
    (state) => state.crop.cases,
    (state, case_id) => case_id,
    (cases, case_id) => {
        let ret;
        console.log('cases', cases, case_id);
        ret = cases.find(v => v.case_id = case_id);
        console.log('looked for', case_id, ret);
        return ret;
    }
)

export const selectCaseSlides = createSelector(
    (state) => state.crop.caseDetail,
    (state, filterBy) => filterBy,
    (state, filterBy, order) => order,
    (case_, filterBy, order) => {
        if (case_ === null) {
            return [];
        }
        let ret = [...case_.slides]
        switch (filterBy) {
            case 'slide_name': {
                ret.sort(sortBySlideName)
                break;
            }
            case 'slide_id': {
                ret.sort(sortBySlideId);
                break;
            }
            case 'width': {
                ret.sort(sortBy('width', true));
                break;
            }
            case 'height': {
                ret.sort(sortBy('height', true));
                break;
            }
            case 'progress': {
                ret.sort(sortByProgress);
                break;
            }
            case 'thumbnail': {
                ret.sort(sortBySize);
                break;
            }
            case 'memo': {
                ret.sort(sortByMemo);
                break;
            }
            default:
                break;
        }
        if (order === 'desc') {
            ret.reverse();

        }
        return ret;
    }
)

export const selectActiveCrops = createSelector(
    (state) => state.crop.slideDetail !== null ? state.crop.slideDetail.crops : [],
    (crops) => {
        return crops.filter(v => !v.deleted);
    }
)
export const actionCreateThumb = (case_id) => async dispatch => {
    let data = await apiGetThumb(case_id);
    toast(`Generating thumbnail for ${case_id}`);
    //dispatch(setCases(data));
};

export const actionCropFull = (case_id, section) => async dispatch => {
    let data = await apiCropFull(case_id, section);
    toast(`Full resolution crop for ${case_id} ${section} queued`);
};

export const notifyCropFinish = (message) => async (dispatch, getState) => {
    const slide = getState().crop.slideDetail;
    if (slide !== null) {
        if (slide.slide_id !== message.slide_id) {
            return;
        }
        toast(`${message.section} finished cropping.`);
        dispatch(finishCrop(message.crop_id));
    }
    //dispatch(updateCropFinish(message))
};
export const notifyCropStart = (message) => async (dispatch, getState) => {
    const slide = getState().crop.slideDetail;
    if (slide !== null) {
        if (slide.slide_id !== message.slide_id) {
            return;
        }
        dispatch(startCrop(message.crop_id));
    }
    //dispatch(updateCropFinish(message))
};
export const getProcessingQueue = (message) => async (dispatch) => {
    const res = await apiGetProcessingQueue();
    dispatch(setProcessingQueue(res));
}
export const getProcessingQueueFull = (message) => async (dispatch) => {
    const res = await apiGetProcessingQueueFull();
    dispatch(setProcessingQueueFull(res));
}

export default cropSlice.reducer;
