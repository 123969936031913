import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import styles from './Inspect.module.scss';
import classNames from 'classnames';
import { getInjections } from './inspectSlice';
import * as d3 from 'd3';
import qs from 'qs';
//import { useForm } from 'react-hook-form'

const rect_width = 5;
const rect_height = 5;
const colorMap = {
    '1': '#BDE93B',
    '10': '#D1FF85',
    '100': '#00FFA5',
    '101': '#00FFAB',
    '102': '#00FF28',
    '103': '#94E959',
    '104': '#EFE700',
    '105': '#E98B3B',
    '106': '#E8FFB2',
    '107': '#C5E976',
    '108': '#9DE900',
    '109': '#A7FF00',
    '11': '#93EF00',
    '110': '#B1FF00',
    '111': '#FF9F85',
    '112': '#E9963B',
    '113': '#E9B476',
    '114': '#E98000',
    '115': '#FFDDB2',
    '116': '#CBFFB2',
    '117': '#51FF00',
    '118': '#71E93B',
    '119': '#75FF3B',
    '12': '#FFE73B',
    '120': '#9DFF76',
    '121': '#FFC576',
    '122': '#FF9700',
    '123': '#FF973B',
    '124': '#DF542C',
    '125': '#0EFF00',
    '126': '#BBFF59',
    '127': '#C3EF85',
    '128': '#80DF00',
    '129': '#A4FF2C',
    '13': '#FFF6B2',
    '130': '#8EFF00',
    '131': '#98EF2C',
    '132': '#B6DF85',
    '133': '#A9EF59',
    '134': '#6DCF00',
    '135': '#E6FF00',
    '136': '#C1EF85',
    '137': '#A3FF59',
    '138': '#B2FFE7',
    '139': '#A2DF85',
    '14': '#EFDC3B',
    '15': '#DFD576',
    '16': '#FFB3B2',
    '17': '#DF0800',
    '18': '#FF6359',
    '19': '#FF1600',
    '2': '#FF6D3B',
    '20': '#FFE6B2',
    '21': '#DFD13B',
    '22': '#EFE676',
    '23': '#FFEF00',
    '24': '#FFF876',
    '25': '#FFB100',
    '26': '#FFCF59',
    '27': '#4ED400',
    '28': '#A0E976',
    '29': '#CAFF3B',
    '3': '#E9BF3B',
    '30': '#D8FF76',
    '31': '#72E900',
    '32': '#E99676',
    '33': '#FF9D76',
    '34': '#D6FFB2',
    '35': '#73FF00',
    '36': '#A0FF59',
    '37': '#EDFF76',
    '38': '#C8E900',
    '39': '#E0FF3B',
    '4': '#E9CF76',
    '40': '#B0D400',
    '41': '#D0FF00',
    '42': '#C8E93B',
    '43': '#EFFFB2',
    '44': '#D0E976',
    '45': '#E96F3B',
    '46': '#FFCAB2',
    '47': '#FF5300',
    '48': '#E95000',
    '49': '#DF6059',
    '5': '#FFC700',
    '50': '#E95900',
    '51': '#FEFF76',
    '52': '#00FF26',
    '53': '#EF2B00',
    '54': '#DF1C00',
    '55': '#EF472C',
    '56': '#EF6E59',
    '57': '#EF2300',
    '58': '#FF4D2C',
    '59': '#FF2900',
    '6': '#D4A800',
    '60': '#DF7059',
    '61': '#DF9585',
    '62': '#CF4A2C',
    '63': '#FF7959',
    '64': '#EF9A85',
    '65': '#CF2B00',
    '67': '#E9A476',
    '68': '#EF8759',
    '69': '#FFEA59',
    '7': '#E9BC00',
    '70': '#50E900',
    '71': '#99DF2C',
    '72': '#D45600',
    '73': '#FFDB3B',
    '74': '#EF802C',
    '75': '#FFD2B2',
    '76': '#DDDF76',
    '77': '#FFC985',
    '78': '#00FF2C',
    '79': '#EAEF3B',
    '8': '#E99400',
    '80': '#CF8C00',
    '81': '#A9DF59',
    '82': '#FFD62C',
    '83': '#FFE876',
    '84': '#FFF3B2',
    '85': '#E6EF00',
    '86': '#DFD22C',
    '87': '#E9EF76',
    '88': '#D2DF00',
    '89': '#D4DF3B',
    '9': '#E9B759',
    '90': '#F0FF3B',
    '91': '#F8FFB2',
    '92': '#DFD600',
    '93': '#FF9E3B',
    '94': '#D2DF00',
    '95': '#B1CF2C',
    '96': '#FF6E00',
    '97': '#FFB376',
    '98': '#3FFF00',
    '99': '#00C8FF'
}
export default function () {
    const dispatch = useDispatch();
    let Sx = 0.06;
    let Sy = 0.06;
    /*let flatCenter = {
        x: 22.,
        y: 18.4
    };
    */
    let flatCenter = {
        x: 24.,
        y: 19.2
    };
    let location = useLocation();
    const [ info, setInfo ] = useState(' ');
    const [ mod, setMod ] = useState(0);
    const [ sectionSelected, setSectionSelected ] = useState('');
    const [ sections, setSections ] = useState([]);
    const { injections } = useSelector(state => state.inspect);
    const cellsRef = useRef(null);
    let query = qs.parse(location.search, { ignoreQueryPrefix: true });
    function concat(type, arrays) {
        let i = 0;
        let n = 0;
        for (const a of arrays) n += a.length;
        const concat = new type(n);
        for (let a of arrays) {
            concat.set(a, i);
            i += a.length;
        }
        return concat;
    }
    function getFill(structure_id) {
        let ret;
        return colorMap[structure_id];
        /*
        switch (tracer_id) {
            case 'FB':
                ret = '#0000ff';
                break;
            case 'CTBgr':
            case 'FE':
                ret = '#003300';
                break;
            case 'DY':
                ret = '#333300';
                break;
            case 'CTBr':
            case 'FR':
                ret = '#ff0000';
                break;
            case 'DY-INJ':
                ret = '#ff0000';
                console.log('Injection!!!!!!!!!', tracer_id);
                break;
            default:
                ret = '#000000';
                break;
        }
        return ret;
        */
    }
    function getStroke(tracer_id) {
        let ret;
        switch (tracer_id) {
            case 'DY-INJ':
                ret = '#ffff00';
                break;
            default:
                ret = '#000000';
                break;
        }
        return ret;
    }
    useEffect(() => {
        if (query.injection) {
            let q = query.injection.split('-');

            let container = d3.select('.inspect');
            let svg = container.append('svg')
                .attr('width', 800 * 2)
                .attr('height', 640 * 2);
            //.attr('width', 725 * 2)
            //    .attr('height', 669 * 2);
            let g = svg.append('g').attr('class', 'areas');
            let g_cells = svg.append('g');
            cellsRef.current = g_cells;

            /*
            d3.xml('/api/static/flatmap_template.svg', {
                headers: [
                    ['Content-Type', 'image/svg+xml'],
                ]
            })
            .then(xml => {
                g.node().appendChild(xml.documentElement);
            });
            */
            d3.json('/api/static/areas.json?injection=' + query.injection)
            .then(data => {
                window.app = window.app || {};
                setIndicatorBorder('1px solid orange');
                let _i = {};
                for (let item of data) {
                    _i[item.index] = item;
                }
                window.app.areas = _i;

                g.selectAll('.area')
                .data(data)
                .enter().append('polygon')
                .attr('class', 'area')
                .attr('stroke', '#000000')
                .attr('fill', d => {
                    return d.color
                })
                .attr('points', d => {
                    return d.coords.map(d => {
                        //.attr('x', d => (parseFloat(d.flat_x) + flatCenter.x) / Sx)
                        let x = (d[0] + flatCenter.x) / Sx;
                        let y = (-d[1] + flatCenter.y) / Sy;
                        return [x, y].join(',');
                    }).join(' ');
                })

                let progress;
                let resultFunc = async () => {
                    const sizeUrl = '/api/cell_data_size/' + q[0];
                    const url = '/api/cell_data/' + q[0] + '?tracer=' + q[1] + '&mod=' + mod;
                    let size;
                    await fetch(sizeUrl)
                        .then(response => response.json())
                        .then(data => size = data.bytes);
                    const response = await fetch(url);
                    // Check if streaming is supported.
                    const reader = response.body && response.body.getReader();
                    if (!reader) {
                        const value = new Uint8Array(await response.arrayBuffer());
                        progress = value.length;
                        return value;
                    }

                    // Read chunks, updating as we go.
                    const values = [];
                    progress = 0;
                    while (true) {
                        const {done, value} = await reader.read();
                        if (done) break;
                        if (progress === 0) {
                            setIndicatorBorder('1px solid #008000');
                        }
                        progress += value.length;
                        let pct = (progress / size) * 100;
                        setIndicatorWidth(pct + '%');
                        values.push(value);
                    }
                    setIndicatorBorder('1px solid cyan');

                    // Concatenate chunks into an array.
                    return concat(Uint8Array, values);
                };
                /*
                let result = resultFunc();
                result.then((r) => {
                    let string = new TextDecoder("utf-8").decode(r);
                    let data = d3.csvParse(string);
                        setIndicatorBorder('1px solid green');
                        g_cells.selectAll('.cell')
                        .data(data)
                        .enter().append('g')
                        .attr('class', 'cell')
                        .filter(d => {
                            if (d.tracer_id === q[1] || d.tracer_id === q[1] + '-INJ') {
                                return d;
                            } else {
                                return null;
                            }

                        })
                        .append('rect')
                            .attr('r', 1)
                            .attr('x', d => (parseFloat(d.flat_x) + flatCenter.x) / Sx)
                            .attr('y', d => (-parseFloat(d.flat_y) + flatCenter.y) / Sy)
                            .attr('width', 2)
                            .attr('height', 2)
                            .attr('fill', d => getFill(d.tracer_id))
                            .attr('stroke', d => getStroke(d.tracer_id))
                            .attr('shape-rendering', 'optimizeSpeed')
                            .style('cursor', 'pointer')
                            .on('click', function(datum) {
                                let d = datum.target.__data__;
                                window.open('http://marmoset.mrosa.org/goto/' + q[0] + '/' + d.section + '/' + (-d.section_x) + '/' + (-d.section_y) + '/1', 'High Res');
                            })
                            .on('mouseover', function(datum) {
                                let d = datum.target.__data__;
                                console.log('section: ' + d.section + ' x: ' + d.flat_x + ' y: ' + d.flat_y);
                                setInfo(
                                    'Tracer: ' + q[1] + ' Section: ' + d.section
                                    + ' x: ' + parseFloat(d.flat_x).toFixed(2) + 'mm'
                                    + ' y: ' + parseFloat(d.flat_y).toFixed(2) + 'mm'
                                    + ' Area: ' + window.app.areas[d.structure_id].code
                                    + ' Laminar position: '
                                    + (d.laminar_position === '1' ? 'Supragranular' : 'Infragranular')
                                );
                                console.log('areas', window.app.areas);
                            })

                            })
                            */
            });
            const zoom = d3.zoom().scaleExtent([0.5, 32])
                .on('zoom', zoomed);

            svg.call(zoom).call(zoom.transform, d3.zoomIdentity);

            function zoomed({transform}) {
                console.log('k', transform.k)
              //const zx = transform.rescaleX(x).interpolate(d3.interpolateRound);
              //const zy = transform.rescaleY(y).interpolate(d3.interpolateRound);
             g.attr('transform', transform)//.attr('stroke-width', 0.25 / transform.k);
             g_cells.attr('transform', 'translate(' + transform.x + ' ' + transform.y + ') scale(' + transform.k + ')');
             if (transform.k > 1.5) {
                g_cells.selectAll('rect').style('stroke-width', (0.25) + 'px');
             }
              //gx.call(xAxis, zx);
              //gy.call(yAxis, zy);
              //gGrid.call(grid, zx, zy);
            }

            /*
            let transform;
            console.log('d3 zoom', d3.zoom);
            const zoom = d3.zoom().on('zoom', e => {
                g.attr("transform", (transform = e.transform));
                g.style("stroke-width", 3 / Math.sqrt(transform.k));
                //points.attr("r", 3 / Math.sqrt(transform.k));
            });
            */
            fetch(`/api/cell_data_sections/${q[0]}`)
            .then(res => res.json())
            .then((data) => {
                setSections(data)
            });
        } else {
            dispatch(getInjections());
        }
    }, [dispatch])
    useEffect(() => {
        if (query.injection) {
            let q = query.injection.split('-');
            cellsRef.current.selectAll('*').remove();
            let progress;
            let resultFunc = async () => {
                const sizeUrl = '/api/cell_data_size/' + q[0];
                const url = `/api/cell_data/${q[0]}?tracer=${q[1]}&mod=${mod}&section=${sectionSelected}`;
                let size;
                await fetch(sizeUrl)
                    .then(response => response.json())
                    .then(data => size = data.bytes);
                const response = await fetch(url);
                // Check if streaming is supported.
                const reader = response.body && response.body.getReader();
                if (!reader) {
                    const value = new Uint8Array(await response.arrayBuffer());
                    progress = value.length;
                    return value;
                }

                // Read chunks, updating as we go.
                const values = [];
                progress = 0;
                while (true) {
                    const {done, value} = await reader.read();
                    if (done) break;
                    if (progress === 0) {
                        setIndicatorBorder('1px solid #008000');
                    }
                    progress += value.length;
                    let pct = (progress / size) * 100;
                    setIndicatorWidth(pct + '%');
                    values.push(value);
                }
                setIndicatorBorder('1px solid cyan');

                // Concatenate chunks into an array.
                return concat(Uint8Array, values);
            };
            let result = resultFunc();
            result.then((r) => {
                let string = new TextDecoder("utf-8").decode(r);
                let data = d3.csvParse(string);
                    setIndicatorWidth('100%');
                    setIndicatorBorder('1px solid green');
                    cellsRef.current.selectAll('.cell')
                    .data(data)
                    .enter().append('g')
                    .attr('class', 'cell')
                    .filter(d => {
                        if (d.tracer_id === q[1] || d.tracer_id === q[1] + '-INJ') {
                            return d;
                        } else {
                            return null;
                        }

                    })
                    .append('rect')
                        .attr('x', d => (parseFloat(d.flat_x) + flatCenter.x) / Sx - rect_width/2)
                        .attr('y', d => (-parseFloat(d.flat_y) + flatCenter.y) / Sy - rect_height/2)
                        .attr('width', rect_width)
                        .attr('height', rect_height)
                        .attr('fill', d => getFill(d.structure_id))
                        .attr('stroke', d => getStroke(d.tracer_id))
                        .attr('shape-rendering', 'optimizeSpeed')
                        .style('cursor', 'pointer')
                        .on('click', function(datum) {
                            let d = datum.target.__data__;
                            window.open('http://marmoset.mrosa.org/goto/' + q[0] + '/' + d.section + '/' + (-d.section_x) + '/' + (-d.section_y) + '/1', 'High Res');
                        })
                        .on('mouseover', function(datum) {
                            if (window.app.areas) {
                                let d = datum.target.__data__;
                                console.log('section: ' + d.section + ' x: ' + d.flat_x + ' y: ' + d.flat_y);
                                setInfo(
                                    'Tracer: ' + q[1] + ' Section: ' + d.section
                                    + ' x: ' + parseFloat(d.flat_x).toFixed(2) + 'mm'
                                    + ' y: ' + parseFloat(d.flat_y).toFixed(2) + 'mm'
                                    + ' Area: ' + window.app.areas[d.structure_id].code
                                    + ' Laminar position: '
                                    + (d.laminar_position === '1' ? 'Supragranular' : 'Infragranular')
                                );
                                console.log('areas', window.app.areas);
                            }
                        })

            });
        }
    }, [mod, sectionSelected])
    /*
                <input type="radio" name="tracer" value="FE" /> FE
                <input type="radio" name="tracer" value="FR" /> FR
                <input type="radio" name="tracer" value="FB" /> FB
                <input type="radio" name="tracer" value="DY" /> DY
                <input type="radio" name="tracer" value="BDA" /> BDA
                <input type="text" name="percentage" placeholder="100" />%
                */
    const [ indicatorBorder, setIndicatorBorder ] = useState('1px solid #0000ff');
    const [ indicatorWidth, setIndicatorWidth ] = useState('100%');
    return (
        <div className="inspector-container">
            { query.injection ?
                <>
                    <div className={styles.loadingIndicator} style={{
                        border: indicatorBorder,
                        width: indicatorWidth
                    }}>
                    </div>
                    <div className="canvas-container">
                        <div className="filters">
                            {[...Array(10)].map((_, i) => {
                                return (<span key={i}>
                                    <input type="radio" checked={mod == i} value={i} name="mod" onChange={() => setMod(i)} /> {i + 1}
                                </span>);
                            })}
                            <select name="section" onChange={(v) => setSectionSelected(v.target.value)}>
                                <option value=""></option>
                                {sections.map(s => (
                                    <option value={s} key={s}>{s}</option>
                                    ))}
                            </select>
                        </div>
                        <div className="info-window">
                            { info }
                        </div>
                        <div className="inspect">
                        </div>
                    </div>
                </>
            :
                <ul className="injection-list">
                    List of injections which has inspection csv file available
                    {injections.map(inj => {
                        return <li className="injection-item" key={inj.id}>
                                <a href={'/inspect?injection=' + inj.injection}>{inj.injection}</a>
                        </li>
                    })
                    }
                </ul>
            }
        </div>
    );
}
