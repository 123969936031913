import React from 'react';
import classNames from 'classnames';
import styles from './Crop.module.scss';
import {moveCrop, updateCrop, flipCrop} from './cropSlice'

export function Th(props) {
    const { order, orderBy, name, onSortUpdate, children } = props;
    return (
        <th
            className={classNames(styles.sortable, {
                [styles.up]: order === 'asc' && orderBy === name,
                [styles.down]: order === 'desc' && orderBy === name
            })}
            onClick={onSortUpdate}
            data-order-by={name}
            >{children}</th>
    )
}

export function globalKeyDownFactory(canvas, lastCrop, setLastCrop, dispatch, resolution) {
    return async function globalKeydown(e) {
        if (window.app.inputing === true) {
            return;
        }
        if (window.app.handling === true) {
            console.log('handling too fast key repeats');
            return;
        }
        window.app.handling = true;
        let crop;
        switch (e.keyCode) {
            case 27: //escape
                if (lastCrop) {
                    canvas.remove(lastCrop);
                    setLastCrop(null);
                }
                break;
            case 46: // delete
                crop = canvas.getActiveObject();
                if (crop) {
                    //app.cropStore.remove(crop);
                }
                break;
            case 188: // <
                crop = canvas.getActiveObject();
                if (crop) {
                    let pt = crop.getPointByOrigin('left', 'top');
                    let values = {
                        x: Math.round(pt.x),
                        y: Math.round(pt.y),
                        w: Math.round(crop.scaleX ? crop.scaleX * crop.width : crop.width),
                        h: Math.round(crop.scaleY ? crop.scaleY * crop.height : crop.height)
                    }
                    let deltaX, deltaY;
                    if (e.shiftKey) {
                        deltaX = -Math.round(values.w * 0.01);
                        deltaY = -Math.round(values.h * 0.01);
                    } else {
                        deltaX = -Math.round(crop.width * 0.05);
                        deltaY = -Math.round(crop.height * 0.05);
                    }
                    values.w += deltaX;
                    values.h += deltaY;
                    crop.set({width: values.w, height: values.h});
                    pt = crop.getPointByOrigin('left', 'top');
                    values.x = Math.round(pt.x);
                    values.y = Math.round(pt.y);

                    await dispatch(updateCrop({
                        id: crop.id,
                        attrs: ['x', 'y', 'w', 'h'],
                        values
                    }))
                }
                break;
            case 190: // >
                crop = canvas.getActiveObject();
                if (crop) {
                    let pt = crop.getPointByOrigin('left', 'top');
                    let values = {
                        x: Math.round(pt.x),
                        y: Math.round(pt.y),
                        w: Math.round(crop.scaleX ? crop.scaleX * crop.width : crop.width),
                        h: Math.round(crop.scaleY ? crop.scaleY * crop.height : crop.height)
                    }
                    let deltaX, deltaY;
                    if (e.shiftKey) {
                        deltaX = Math.round(values.w * 0.01);
                        deltaY = Math.round(values.h * 0.01);
                    } else {
                        deltaX = Math.round(crop.width * 0.05);
                        deltaY = Math.round(crop.height * 0.05);
                    }
                    values.w += deltaX;
                    values.h += deltaY;
                    crop.set({width: values.w, height: values.h});
                    pt = crop.getPointByOrigin('left', 'top');
                    values.x = Math.round(pt.x);
                    values.y = Math.round(pt.y);

                    await dispatch(updateCrop({
                        id: crop.id,
                        attrs: ['x', 'y', 'w', 'h'],
                        values
                    }))
                }
                break;
            case 37: // left
                crop = canvas.getActiveObject();
                if (crop) {
                    e.preventDefault();
                    if (e.shiftKey) {
                        crop.set({left: crop.left - 1});
                        await dispatch(moveCrop(crop.id, 'left', 1))
                    } else if (e.ctrlKey) {
                        if (crop.width > 5) {
                            crop.set({width: crop.width - 5});
                            let pt = crop.getPointByOrigin('left', 'top');
                            let _x = Math.round(pt.x);
                            let _y = Math.round(pt.y);
                            await dispatch(updateCrop({id: crop.id, attrs: ['w', 'x'], values: {w: crop.width, x: _x}}))
                        }
                    } else {
                        crop.set({left: crop.left - 5});
                        await dispatch(moveCrop(crop.id, 'left', 5))
                    }
                    canvas.renderAll();
                }
                break;
            case 38: // up
                crop = canvas.getActiveObject();
                if (crop) {
                    e.preventDefault();
                    if (e.shiftKey) {
                        crop.set({top: crop.top - 1});
                        await dispatch(moveCrop(crop.id, 'up', 1))
                    } else if (e.ctrlKey) {
                        if (crop.height > 5) {
                            crop.set({height: crop.height - 5});
                            let pt = crop.getPointByOrigin('left', 'top');
                            let _x = Math.round(pt.x);
                            let _y = Math.round(pt.y);
                            await dispatch(updateCrop({id: crop.id, attrs: ['h', 'y'], values: {h: crop.height, y: _y}}))
                        }
                    } else {
                        crop.set({top: crop.top - 5});
                        await dispatch(moveCrop(crop.id, 'up', 5))
                    }
                }
                break;
            case 39: // right
                crop = canvas.getActiveObject();
                if (crop) {
                    e.preventDefault();
                    if (e.shiftKey) {
                        crop.set({left: crop.left + 1});
                        await dispatch(moveCrop(crop.id, 'right', 1))
                    } else if (e.ctrlKey) {
                        crop.set({width: crop.width + 5});
                        let pt = crop.getPointByOrigin('left', 'top');
                        let _x = Math.round(pt.x);
                        let _y = Math.round(pt.y);
                        await dispatch(updateCrop({id: crop.id, attrs: ['w', 'x'], values: {w: crop.width, x: _x}}))
                    } else {
                        crop.set({left: crop.left + 5});
                        await dispatch(moveCrop(crop.id, 'right', 5))
                    }
                }
                break;
            case 40: // down
                crop = canvas.getActiveObject();
                if (crop) {
                    e.preventDefault();
                    if (e.shiftKey) {
                        crop.set({top: crop.top + 1});
                        await dispatch(moveCrop(crop.id, 'down', 1))
                    } else if (e.ctrlKey) {
                        crop.set({height: crop.height + 5});
                        let pt = crop.getPointByOrigin('left', 'top');
                        let _x = Math.round(pt.x);
                        let _y = Math.round(pt.y);
                        await dispatch(updateCrop({id: crop.id, attrs: ['h', 'y'], values: {h: crop.height, y: _y}}))
                    } else {
                        crop.set({top: crop.top + 5});
                        await dispatch(moveCrop(crop.id, 'down', 5))
                    }
                }
                break;
            case 219: // [
                crop = canvas.getActiveObject();
                if (crop) {
                    if (e.shiftKey) {
                        //crop.set({angle: crop.angle - 1});
                        let angle = crop.angle - 1;
                        if (angle <= -360) {
                            angle += 360;
                        }
                        crop.rotate(angle);
                        const pt = crop.getPointByOrigin('left', 'top');
                        let x = Math.round(pt.x);
                        let y = Math.round(pt.y);


                        await dispatch(updateCrop({
                            id: crop.id,
                            attrs: ['x', 'y', 'angle'],
                            values: {x: x, y: y, angle: angle}}))
                    } else {
                        //crop.set({angle: crop.angle - 5});
                        let angle = crop.angle - 5;
                        if (angle <= -360) {
                            angle += 360;
                        }
                        crop.rotate(angle);
                        const pt = crop.getPointByOrigin('left', 'top');
                        let x = Math.round(pt.x);
                        let y = Math.round(pt.y);
                        await dispatch(updateCrop({
                            id: crop.id,
                            attrs: ['x', 'y', 'angle'],
                            values: {x: x, y: y, angle: angle}}))
                    }
                }
                break;
            case 221: // ]
                crop = canvas.getActiveObject();
                if (crop) {
                    if (e.shiftKey) {
                        //crop.set({angle: crop.angle + 1});
                        console.log('angle is', angle);
                        let angle = crop.angle + 1;
                        if (angle >= 360) {
                            angle -= 360;
                        }
                        crop.rotate(angle);
                        const pt = crop.getPointByOrigin('left', 'top');
                        let x = Math.round(pt.x);
                        let y = Math.round(pt.y);
                        await dispatch(updateCrop({
                            id: crop.id,
                            attrs: ['x', 'y', 'angle'],
                            values: {x: x, y: y, angle: angle}}))
                    } else {
                        let angle = crop.angle + 5;
                        if (angle >= 360) {
                            angle -= 360;
                        }
                        crop.rotate(angle);
                        console.log('angle is', angle);
                        const pt = crop.getPointByOrigin('left', 'top');
                        let x = Math.round(pt.x);
                        let y = Math.round(pt.y);
                        await dispatch(updateCrop({
                            id: crop.id,
                            attrs: ['x', 'y', 'angle'],
                            values: {x: x, y: y, angle: angle}}))
                    }
                }
                break;

            case 75: // k
                crop = canvas.getActiveObject();
                if (crop) {
                    e.preventDefault();
                    let factor = resolution * 64;
                    let delta = 0.5 / factor;
                    if (e.shiftKey) {
                        crop.set({
                            width: crop.width - delta,
                            height: crop.height - delta
                        });
                        const pt = crop.getPointByOrigin('left', 'top');
                        let values = {
                            x: Math.round(pt.x),
                            y: Math.round(pt.y),
                            w: Math.round(crop.scaleX ? crop.scaleX * crop.width : crop.width),
                            h: Math.round(crop.scaleY ? crop.scaleY * crop.height : crop.height)
                        }
                        await dispatch(updateCrop({
                            id: crop.id,
                            attrs: ['x', 'y', 'w', 'h'],
                            values: values}))
                    }  else {
                        crop.set({
                            width: crop.width + delta,
                            height: crop.height + delta
                        });
                        const pt = crop.getPointByOrigin('left', 'top');
                        let values = {
                            x: Math.round(pt.x),
                            y: Math.round(pt.y),
                            w: Math.round(crop.scaleX ? crop.scaleX * crop.width : crop.width),
                            h: Math.round(crop.scaleY ? crop.scaleY * crop.height : crop.height)
                        }
                        await dispatch(updateCrop({
                            id: crop.id,
                            attrs: ['x', 'y', 'w', 'h'],
                            values: values}))
                    }
                }
                break;
            case 70: // f
                crop = canvas.getActiveObject();
                if (crop) {
                    if (e.shiftKey) {
                        await dispatch(flipCrop(crop.id, false));
                    } else {
                        await dispatch(flipCrop(crop.id, true));
                    }
                }

                break;
            default:
                console.log('keycode', e.keyCode);
                break;

        }
        window.app.handling = false;
    }
}
