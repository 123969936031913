import { createSlice } from '@reduxjs/toolkit';
//import { createSelector } from 'reselect';
import { getUserInfo as apiGetUserInfo } from '../../api/API';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        info: {},
        needLogin: false,
    },
    reducers: {
        setInfo: (state, action) => {
            state.info = action.payload;
        },
        setNeedLogin: (state, action) => {
            state.needLogin = action.payload;
        },
    },
});

export const { setInfo, setNeedLogin } = userSlice.actions;

export const getInfo = () => async dispatch => {
    try {
        let data = await apiGetUserInfo()
        dispatch(setInfo(data.info));
        dispatch(setNeedLogin(false));
    } catch (e) {
        if (e.response?.status === 401) {
            dispatch(setNeedLogin(true));
        }
        console.log('err', e.response);
        console.log('href', window.location.href);
        console.log('href2', window.location.href.path);
        /*
        if (window.location.pathname !== '/login') {
            window.location.href = '/login';
        }
        */
    }
};


export default userSlice.reducer;
