import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

import { getLabels as apiGetLabels,
    saveLabelPolygons as apiSaveLabelPolygons,
    getLabelPolygons as apiGetLabelPolygons,
    getLabelSections as apiGetSections,
    getLabelSectionInfo as apiGetSectionInfo,
    getLabelCases as apiGetLabelCases,
    getLabelSummary as apiGetLabelSummary,
    getAreas as apiGetAreas,
    updateAP as apiUpdateAP,
    getLabelCaseSummary as apiGetLabelCaseSummary,
    updateLabels as apiUpdateLabels,
    getLabelNewColor as apiGetLabelNewColor,
} from '../../api/API';
import { createSelector } from 'reselect';

import { Collection, Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { Polygon } from 'ol/geom';
import { cmpalphanum } from '../lib';

export const polyCollection = new Collection();
export let modifyObject = {};

let geojson = new GeoJSON();
let hideAll;
try {
    hideAll = JSON.parse(localStorage.getItem('last_hide_all'));
} catch (e) {
    hideAll = false;
}
function getLocalStorage(key, def) {
    let value;
    try {
        value = JSON.parse(localStorage.getItem(key));
    } catch (e) {
        value = def;
    }
    if (value === null) {
        value = def;
    }
    return value;
}
export const labelMapSlice = createSlice({
    name: 'labelMap',
    initialState: {
        labels: [],
        labelsBackup: [],
        polys: [],
        highlight: null,
        selectedUid: null,
        mapDirty: false,
        sections: [],
        info: null,
        lastLabels: [],
        modifyPolygon: null,
        modifyVertex: null,
        caseIds: [],
        hideAll: hideAll,
        summary: null,
        ap: {},
        labelEditing: null,
        areas: [],
        areaEditing: null,
        caseSummary: null,
        cases: [],
        apiHelper: false,
        newLabel: false,
        snap: getLocalStorage('snap', true),
        wheel_zoom: getLocalStorage('wheel_zoom', true),
    },
    reducers: {
        setLabels: (state, action) => {
            state.labels = action.payload;
        },
        setLabelsBackup: (state, action) => {
            state.labelsBackup = action.payload;
        },
        setPolys: (state, action) => {
            state.polys = action.payload;
        },
        setHighlight: (state, action) => {
            state.highlight = action.payload;
        },
        setSelectedUid: (state, action) => {
            state.selectedUid = action.payload;
        },
        setMapDirty: (state, action) => {
            state.mapDirty = action.payload;
        },
        setSections: (state, action) => {
            state.sections = action.payload;
        },
        setInfo: (state, action) => {
            state.info = action.payload;
        },
        setLastLabels: (state, action) => {
            state.lastLabels = action.payload;
        },
        setModifyPolygon: (state, action) => {
            state.modifyPolygon = action.payload;
        },
        setModifyVertex: (state, action) => {
            state.modifyVertex = action.payload;
        },
        setCaseIds: (state, action) => {
            state.caseIds = action.payload;
        },
        setHideAll: (state, action) => {
            state.hideAll = action.payload;
            localStorage.setItem('last_hide_all', JSON.stringify(action.payload));
        },
        setSummary: (state, action) => {
            state.summary = action.payload;
        },
        setAP: (state, action) => {
            state.ap = action.payload;
        },
        setLabelEditing: (state, action) => {
            state.labelEditing = action.payload;
        },
        setAreas: (state, action) => {
            state.areas = action.payload;
        },
        setAreaEditing: (state, action) => {
            state.areaEditing = action.payload;
        },
        setCaseSummary: (state, action) => {
            state.caseSummary = action.payload;
        },
        setCases: (state, action) => {
            state.cases = action.payload;
        },
        setAPIHelper: (state, action) => {
            state.apiHelper = action.payload;
        },
        setNewLabel: (state, action) => {
            state.newLabel = action.payload;
        },
        setSnap: (state, action) => {
            state.snap = action.payload;
            localStorage.setItem('snap', action.payload);
        },
        setWheelZoom: (state, action) => {
            state.wheel_zoom = action.payload;
            localStorage.setItem('wheel_zoom', action.payload);
        },
    },
});
export const {
    setLabels, setLabelsBackup, setPolys, setHighlight, setSections, setInfo,
    setMapDirty, setLastLabels, setModifyPolygon, setModifyVertex,
    setCaseIds, setHideAll, setSummary, setAP, setLabelEditing,
    setAreas, setAreaEditing, setCaseSummary, setCases, setAPIHelper,
    setNewLabel, setSnap, setWheelZoom
} = labelMapSlice.actions;

export default labelMapSlice.reducer;


export const getLabels = (message) => async (dispatch) => {
    const res = await apiGetLabels();
    await dispatch(setLabels(res.labels));
    await dispatch(setLabelsBackup(res.labels));
}

export const addPolygon = f => async (dispatch) => {
    polyCollection.push(f);
    let json = geojson.writeFeaturesObject(polyCollection.getArray());
    dispatch(setPolys(json.features));
    dispatch(setMapDirty(true));
}

export const removePolygon = uid => async (dispatch, getState) => {
    let target = uid;
    let { highlight } = getState().labelMap;
    if (uid === -1) {
        target = highlight;
    } else {
        target = uid;
    }
    if (target) {
        let to_remove = [];
        polyCollection.forEach(v => {
            if (v.ol_uid === target) {
                to_remove.push(v);
            }
        })
        to_remove.forEach(v => {
            polyCollection.remove(v);
        });
        let json = geojson.writeFeaturesObject(polyCollection.getArray());
        dispatch(setPolys(json.features));
        dispatch(setMapDirty(true));
    }
}

export const removeVertex = () => async (dispatch, getState) => {
    let { vertex, polygon } = modifyObject;
    if (!polygon) {
        return;
    }
    if (!vertex) {
        let target = polygon.ol_uid;
        let to_remove = [];
        polyCollection.forEach(v => {
            if (v.ol_uid === target) {
                to_remove.push(v);
            }
        })
        to_remove.forEach(v => {
            polyCollection.remove(v);
        });
    } else {
        let vertexCoord = vertex.getGeometry().getCoordinates();
        let polygonGeom = polygon.getGeometry();
        let coords = polygonGeom.getCoordinates();
        let newCoords = coords[0].filter(v => !(v.length === 2 && v[0] === vertexCoord[0] && v[1] === vertexCoord[1]));
        polygonGeom.setCoordinates([newCoords]);
    }
    let json = geojson.writeFeaturesObject(polyCollection.getArray());
    dispatch(setPolys(json.features));
    dispatch(setMapDirty(true));

}

export const saveLabelMap = (section_id) => async (dispatch, getState) => {
    let json = geojson.writeFeaturesObject(polyCollection.getArray());

    await apiSaveLabelPolygons(section_id, json);
    dispatch(setMapDirty(false));
    dispatch(setNewLabel(false));
}

export const getPolygons = (section_id) => async (dispatch, getState) => {
    //dispatch(apiGetLabelPolygons());
}

export const getSections = (section_id) => async (dispatch, getState) => {
    let res = await apiGetSections(section_id);
    dispatch(setSections(res.sections));
}

export const getSectionInfo = (section_id) => async (dispatch, getState) => {
    let res = await apiGetSectionInfo(section_id);
    dispatch(setInfo(res.info));

    res.info.labels.forEach(l => {
        let f = new Feature({
            name: 'Label',
            geometry: new Polygon(l.coords)
        });
        f.set('name', 'Label');
        f.set('id', l.id);
        f.set('highlight', false);
        f.set('label_id', l.label_id);
        f.set('rgb', l.rgb);
        f.set('ol_uid', f.ol_uid);
        f.set('title', l.label_title);
        polyCollection.push(f);
    });
    let json = geojson.writeFeaturesObject(polyCollection.getArray());
    dispatch(setPolys(json.features));
    dispatch(setMapDirty(false));
}

export const chooseLabel = (label_id) => async (dispatch, getState) => {
    const { lastLabels } = getState().labelMap;
    let lasts = lastLabels.slice();;
    let idx = lasts.indexOf(label_id);
    if (idx >= 0) {
        lasts.splice(idx, 1);
        //lasts.splice(0, 0, label_id);
        lasts.push(label_id);
    } else {
        if (lasts.length === 5) {
            lasts = lasts.slice(1);
        }
        lasts.push(label_id);
    }
    localStorage.setItem('last_labels', JSON.stringify(lasts));
    dispatch(setLastLabels(lasts));
}
export const getLabelCases = () => async (dispatch, getState) => {
    let res = await apiGetLabelCases();
    dispatch(setCaseIds(res.case_ids));
    dispatch(setCases(res.cases));
}

export const LabelContext = React.createContext();

export const selectLabelList = createSelector(
    state => state.labelMap.labels,
    labels => {
        let label_info = {};
        let label_info_abbrev = {};
        labels.forEach(v => {
            label_info[v.id] = v;
            label_info_abbrev[v.label_map_abbrev] = v;
        })
        return {label_info, label_info_abbrev};
    }
)
export const selectInfo = state => state.labelMap.info;
export const getSummary = (case_id) => async (dispatch) => {
    let res = await apiGetLabelSummary(case_id);
    dispatch(setSummary(res));
    dispatch(setAP(res.ap_stack));
}
export const updateAP = (index, value) => async (dispatch, getState) => {
    let state = getState().labelMap;
    let ap = { ...state.ap };
    ap[index] = value;
    let case_id = state.info.case_id;
    await apiUpdateAP(case_id, ap);
    dispatch(setAP(ap));
}
export const leftAP = (index, value) => async (dispatch, getState) => {
    let state = getState().labelMap;
    let indices = state.summary.stack_indices;
    if (index - 1 < indices[0]) {
        alert('Already at first section');
        return;
    } else {
        let ap = { ...state.ap };
        delete ap[index];
        ap[index - 1] = value;
        let info = selectInfo(getState());
        await apiUpdateAP(info.case_id, ap);
        dispatch(setAP(ap));
    }
}
export const rightAP = (index, value) => async (dispatch, getState) => {
    let state = getState().labelMap;
    let indices = state.summary.stack_indices;
    if (index + 1 > indices[indices.length - 1]) {
        alert('Already at last section');
        return;
    } else {
        let ap = { ...state.ap };
        delete ap[index];
        ap[index + 1] = value;
        let info = selectInfo(getState());
        await apiUpdateAP(info.case_id, ap);
        dispatch(setAP(ap));
    }
}
export const clearAP = (index) => async (dispatch, getState) => {
    let state = getState().labelMap;
    let indices = state.summary.stack_indices;
    if (index === indices[0] || index === indices[indices.length - 1]) {
        alert('Cannot clear the first or last section');
        return;
    }
    let ap = { ...state.ap };
    delete ap[index];
    let case_id = state.info.case_id;
    await apiUpdateAP(case_id, ap);
    dispatch(setAP(ap));
}

function round_1(v) {
    return Math.round((v + Number.EPSILON) * 100) / 100;
}

export const interpolationSelector = createSelector(
    state => state.labelMap.ap,
    state => state.labelMap.summary,
    (ap, summary) => {
        if (!summary) {
            return [];
        }
        let _ap = [];
        let last_index = null;
        let indices = Object.keys(ap).map(v => parseInt(v, 10)).sort(function (a, b) { return a-b; });
        let stack_indices = summary.stack_indices;
        indices.forEach((v, i) => {
            if (i === 0) {
                let idx = stack_indices.indexOf(v);
                _ap[idx] = {
                    index: v,
                    value: ap[v],
                    method: 'manual',
                    position: idx
                };
                last_index = v;
            } else {
                //let old = _ap[v];
                let min = ap[last_index];
                let max = ap[v];
                for (let _i = last_index + 1; _i < v; _i++) {
                    let value = round_1(((max - min) / (v - last_index)) * (_i - last_index) + min);
                    let idx = stack_indices.indexOf(_i);
                    _ap[idx] = {
                        index: _i,
                        value: value,
                        method: 'auto',
                        position: idx
                    };

                }
                let idx = stack_indices.indexOf(v);
                _ap[idx] = {
                    index: v,
                    value: ap[v],
                    method: 'manual',
                    position: idx
                };
                last_index = v;
            }
        });
        return _ap;
    }
);

export const addLabel = () => async (dispatch, getState) => {
    const { labelEditing, labels, labelsBackup } = getState().labelMap;
    let l = [ ...labels ];
    let lOld = [ ...labelsBackup ];
    let new_id = l[l.length - 1].id + 1;
    let new_color = await apiGetLabelNewColor(new_id);
    l.push({
        title: '',
        id: new_id,
        rgb: new_color.rgb,
        label_map_abbrev: '',
        areas: [],
        dirty: true
    });
    lOld.push({
        title: '',
        id: new_id,
        rgb: new_color.rgb,
        label_map_abbrev: '',
        areas: [],
        new_label: true
    });

    await dispatch(setLabels(l));
    await dispatch(setLabelsBackup(lOld));
    dispatch(setLabelEditing(new_id));
    dispatch(setNewLabel(true));
}

export const getAreas = () => async (dispatch) => {
    const res = await apiGetAreas();
    dispatch(setAreas(res.areas));
}

export const removeArea = (areaId) => async (dispatch, getState) => {
    const { labelEditing, labels } = getState().labelMap;
    let index = labels.findIndex(v => v.id === labelEditing);
    if (index === -1) {
        return;
    }
    let newLabels = [
        ...labels.slice(0, index),
        { ...labels[index],
            areas: labels[index].areas.filter(v => v !== areaId),
            dirty: true
        },
        ...labels.slice(index + 1)
    ]
    dispatch(setLabels(newLabels));
}

export const updateTitle = (value) => async (dispatch, getState) => {
    const { labelEditing, labels } = getState().labelMap;
    let index = labels.findIndex(v => v.id === labelEditing);
    if (index === -1) {
        return;
    }
    let newLabels = [
        ...labels.slice(0, index),
        { ...labels[index],
            title: value,
            dirty: true
        },
        ...labels.slice(index + 1)
    ]
    dispatch(setLabels(newLabels));
}

export const updateAbbrev = (value) => async (dispatch, getState) => {
    const { labelEditing, labels } = getState().labelMap;
    let index = labels.findIndex(v => v.id === labelEditing);
    if (index === -1) {
        return;
    }
    let newLabels = [
        ...labels.slice(0, index),
        { ...labels[index],
            label_map_abbrev: value,
            dirty: true
        },
        ...labels.slice(index + 1)
    ]
    dispatch(setLabels(newLabels));
}

export const updateRGB = (value) => async (dispatch, getState) => {
    const { labelEditing, labels } = getState().labelMap;
    let index = labels.findIndex(v => v.id === labelEditing);
    if (index === -1) {
        return;
    }
    let newLabels = [
        ...labels.slice(0, index),
        { ...labels[index],
            rgb: value,
            dirty: true
        },
        ...labels.slice(index + 1)
    ]
    dispatch(setLabels(newLabels));
}

export const addArea = (areaId) => async (dispatch, getState) => {
    const { labelEditing, labels } = getState().labelMap;
    let index = labels.findIndex(v => v.id === labelEditing);
    if (index === -1) {
        return;
    }
    let newLabels = [
        ...labels.slice(0, index),
        { ...labels[index],
            areas: [ ...labels[index].areas, areaId],
            dirty: true
        },
        ...labels.slice(index + 1)
    ]
    dispatch(setLabels(newLabels));
}


export const getCaseSummary = () => async (dispatch) => {
    let res = await apiGetLabelCaseSummary();
    dispatch(setCaseSummary(res));
}

export const revertLabel = () => async (dispatch, getState) => {
    const { labelEditing, labels, labelsBackup } = getState().labelMap;
    let index = labels.findIndex(v => v.id === labelEditing);
    if (index === -1) {
        return;
    }
    const oldLabel = labelsBackup[index];
    console.log('old label is', oldLabel);
    let newLabels;
    if (oldLabel.new_label) {
        newLabels = [
            ...labels.slice(0, index),
            ...labels.slice(index + 1)
        ]
    } else {
        newLabels = [
            ...labels.slice(0, index),
            oldLabel,
            ...labels.slice(index + 1)
        ]
    }
    dispatch(setLabels(newLabels));
}

export const updateLabels = () => async (dispatch, getState) => {
    const { labels } = getState().labelMap;
    let result = await apiUpdateLabels(labels);
    console.log('result is', result);
}

export const selectCJCases = createSelector(
    state => state.labelMap.cases,
    (cases) => {
        let l = Object.keys(cases).filter(v => v.startsWith('CJ')).sort(cmpalphanum);
        let res = [];
        l.forEach(i => {
            if (cases[i].labels.length > 0) {
                res.push({
                    case_id: i,
                    labels: cases[i].labels
                });
            }
        });
        return res;
    }
);
export const selectWCases = createSelector(
    state => state.labelMap.cases,
    (cases) => {
        let l = Object.keys(cases).filter(v => v.startsWith('W')).sort(cmpalphanum);
        let res = [];
        l.forEach(i => {
            if (cases[i].labels.length > 0) {
                res.push({
                    case_id: i,
                    labels: cases[i].labels
                });
            }
        });
        return res;
    }
);
export const selectCandidateCases = createSelector(
    state => state.labelMap.cases,
    (cases) => {
        let l = Object.keys(cases).sort(cmpalphanum);
        let res = [];
        l.forEach(i => {
            res.push({
                case_id: i,
                labels: cases[i]
            });
        });
        return res;
    }
);
