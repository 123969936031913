import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './LabelMap.module.scss';
import {
    setLabelEditing,
    removeArea, addArea, getCaseSummary, addLabel,
    updateTitle, updateAbbrev, updateRGB, revertLabel,
    getAreas, updateLabels
} from './labelMapSlice';
import classNames from 'classnames';
import * as d3 from 'd3';

//import { useForm } from 'react-hook-form'

import { TextField, Dialog, DialogContent } from '@material-ui/core';

import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';

function getOffset(me, parent) {
    const pbox = parent.getBoundingClientRect();
    const box = me.getBoundingClientRect();
    return {
        left: box.left - pbox.left,
        top: box.top - pbox.top,
        right: pbox.right - box.right

    }
}
function LabelCandidate() {
    const { caseSummary, labels, labelEditing } = useSelector(state => state.labelMap);
    let label = labels.find(v => v.id === labelEditing);
    if (!label) {
        label = null;
    }
    const autocompleteRef = useRef(null);
    const completeRef = useRef(null);
    const labelRef = useRef(null);
    const dispatch = useDispatch();
    const handleAddLabel = useCallback(() => {
        dispatch(addLabel());
    }, []);
    const handleExportSpreadsheet = useCallback(() => {
        let location = window.location;
        location.href = '/api/label/export_xls';
    }, []);
    return <section className="label-candidates">
        <Autocomplete
            open={true}
            options={labels}
            getOptionLabel={option => option.title}
            style={{ width: 450 }}
            value={label}
            ref={r => autocompleteRef.current = r}
            renderInput={params =>
                <TextField { ... params } inputRef={ref => completeRef.current=ref}
                    variant="outlined" />
            }
            renderOption={option =>
                <div className="label-option">
                    <span className="pilot-square" style={{color: 'rgb(' + option.rgb + ')'}}>■ </span>
                    <span style={{fontWeight: (caseSummary.label_cases[option.id] && caseSummary.label_cases[option.id].length > 0) ? 'bold': 'normal'}}>
                        ({option.id}) {option.title}
                    </span>
                </div>
            }
            onChange={(e, value) => {
                //context.setLabel(value);
                labelRef.current = value;
                if (value) {
                    console.log('setting label editing to', value.id);
                    dispatch(setLabelEditing(value.id));
                }
                //context.onStartClicked();
            }}
            blurOnSelect={true}
            filterOptions={createFilterOptions({
                stringify: option => option.title + option.label_map_abbrev
            })}
            />
            <button className="add-label" onClick={handleAddLabel}>Add Label</button>
            <button className="export-spreadsheet" onClick={handleExportSpreadsheet}>Export to spreadsheet</button>
    </section>
}
function LabelDetail() {
    const { caseSummary, labels, labelEditing, areas, newLabel } = useSelector(state => state.labelMap);
    const dispatch = useDispatch();
    let label = labels.find(v => v.id === labelEditing);
    if (!label) {
        label = {id: null, areas: [], dirty: false};
    }
    const cases = caseSummary.label_cases[label.id];
    const disabled = cases && cases.length > 0;
    const onRemoveArea = useCallback(areaId => e => {
        if (!disabled) {
            dispatch(removeArea(areaId));
        }
    }, [disabled]);
    const [ displayCases, setDisplayCases ] = useState(false);
    const handleTitleChange = useCallback((e) => {
        dispatch(updateTitle(e.target.value));
    }, []);
    const handleAbbrevChange = useCallback((e) => {
        dispatch(updateAbbrev(e.target.value));
    }, []);
    const handleRGBChange = useCallback((e) => {
        dispatch(updateRGB(e.target.value));
    }, []);
    const showCases = useCallback((e) => {
        setDisplayCases(true);
    }, []);
    const hideCases = useCallback((e) => {
        setDisplayCases(false);
    }, []);
    const handleRevertLabel = useCallback((e) => {
        dispatch(revertLabel());
    }, []);
    const handleSaveLabel = useCallback((e) => {
        dispatch(updateLabels());
    }, []);
    return <section className={classNames('label-detail', {disabled: disabled})}>
        <div className="detail-wrap">
            <section className="meta">
                <div className="entry">
                    <span className="label">Label ID</span>
                    <span className="label-id-info"><input disabled={true} type="text" value={label.id} className="label-id" />
                    <span className="cases-icon" onMouseEnter={showCases} onMouseLeave={hideCases}>{ cases ? cases.length : 0}</span>
                    {
                        displayCases &&
                        <span className="cases">{ cases && cases.join(', ') }</span>

                    }
                    {
                        newLabel &&
                            <span className="new">New Label</span>
                    }
                    </span>
                </div>
                <div className="entry">
                    <span className="label">Title</span> <textarea value={label.title} onChange={handleTitleChange} />
                </div>
                <div className="entry">
                    <span className="label">Abbrev</span> <input disabled={disabled} type="text" value={label.label_map_abbrev} onChange={handleAbbrevChange} />
                </div>
                <div className="entry">
                    <span className="label">RGB</span> <input disabled={disabled} type="text" value={label.rgb} onChange={handleRGBChange} />
                </div>
            </section>
            <section className="areas">
                <div className="entry">
                    <span className="label">Areas</span>
                    <span className="area-list">
                        {label.areas.length ? label.areas.map(v => {
                            let a = areas.find(_a => _a.id === v);
                            return <span key={a.id} className="area-box">({a.id}) {a.abbrev} - {a.title} {!disabled && <span className="button" onClick={onRemoveArea(a.id)}>X</span>}</span>;
                        }) : 'No coressponding area'}
                    </span>
                </div>
            </section>
            <section className="actions">
                <button disabled={!label.dirty} onClick={handleSaveLabel}>Save</button>
                <button disabled={!label.dirty} onClick={handleRevertLabel}>Revert</button>
            </section>
        </div>
    </section>
}

let origFilterOpt = createFilterOptions({
    stringify: option => option.title + option.abbrev
});

function AreaCandidate() {
    const autocompleteRef = useRef(null);
    const completeRef = useRef(null);
    const areaRef = useRef(null);
    const dispatch = useDispatch();
    const { caseSummary, areas, areaEditing, labels, labelEditing } = useSelector(state => state.labelMap);

    let area = areas.find(v => v.id === areaEditing);
    if (!area) {
        area = null;
    }

    let label = labels.find(v => v.id === labelEditing);
    if (!label) {
        label = {areas: []};
    }

    useEffect(() => {
        dispatch(getAreas());
    }, [ dispatch ]);
    const onAddArea = useCallback(areaId => e => {
        dispatch(addArea(areaId));
    }, []);
    const [inputValue, setInputValue] = useState('');
    const [autoValue, setAutoValue] = useState('');
    //let subAreas = label ? areas.filter(a => label.areas.indexOf(a.id) === -1) : areas;
    let filterOpt = (options, { inputValue }) => {
        console.log('asked for filter', inputValue);
        let subOpt = options.filter(v => label.areas.indexOf(v.id) === -1);
        return origFilterOpt(subOpt, { inputValue });
    };
    const cases = caseSummary.label_cases[label.id];
    const disabled = cases && cases.length > 0;
    return <section className="area-candidates">
        <Autocomplete
            options={areas}
            open={true}
            clearOnBlur={false}
            disableCloseOnSelect={true}
            getOptionLabel={option => option.title}
            inputValue={inputValue}
            style={{ width: 450 }}
            ref={r => autocompleteRef.current = r}
            renderInput={params =>
                <TextField { ... params } inputRef={ref => completeRef.current=ref}
                    variant="outlined" />
            }
            renderOption={option =>
                <div className="label-option">
                    <span className="pilot-square" style={{color: 'rgb(' + option.rgb + ')'}}>■ </span>
                    <span>
                        {option.title} ({option.abbrev})
                    </span>
                </div>
            }
            onInputChange={(e, value, reason) => {
                console.log('autovalue', autoValue, value);

                if (reason === 'reset') {
                } else {
                    setInputValue(value);
                }
            }}
            onChange={(e, value) => {
                //context.setLabel(value);
                areaRef.current = value;
                setAutoValue(value);
                if (value) {
                    //dispatch(setAreaEditing(value.id));
                    if (!disabled) {
                        dispatch(addArea(value.id));
                    }
                }
            }}
            filterOptions={filterOpt}
            />
    </section>
}
export default function ManagePanel() {
    const dispatch = useDispatch();
    useEffect(() => {
        //dispatch(getAreas());
        dispatch(getCaseSummary());
    }, [ dispatch ]);
    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 'none'
        },
    };
    let [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => {
        setModalIsOpen(true);
        //setTimeout(afterOpenModal, 1000);
    }
    const closeModal = () => {
        setModalIsOpen(false);
        d3.select('.tooltip').style('display', 'none');
    }
    const afterOpenModal = () => {
    }
    return (
        <div className="labels-function">
            <button className="labels-button"
                onClick={openModal}
                >Manage Labels</button>
            <Dialog
                className="label-summary label-manage"
                open={modalIsOpen}
                style={modalStyles}
                onClose={closeModal}
                TransitionProps={{
                    onEntered: afterOpenModal
                }}
                aria-labelledby="label-summary"
                >
                <DialogContent
                    className="modal-content"
                    >
                    <div className="title">
                        <div className="main-title">
                            Label management
                        </div>
                        <div className="sub-title">
                        </div>
                    </div>
                    <div className="labels-panel">
                        <LabelCandidate />
                        <LabelDetail />
                        <AreaCandidate />
                    </div>
                    <div className="tooltip">
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
