import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import logo from './images/logo.svg';
//import { Sample } from './features/sample/Sample';
import { CropList, CropListItem } from './features/crop/CropListPage';
import CaseDetail from './features/crop/CaseDetail';
import Slide from './features/crop/Slide';
import Inspector from './features/inspect/Inspector';
import { selectCase, notifyCropFinish, notifyCropStart, getProcessingQueue } from './features/crop/cropSlice';
import { notifyCountingEvent } from './features/layer2/layer2Slice';
import { getInfo } from './features/user/userSlice';
import { BrowserRouter as Router, Switch, Route, useParams, useLocation, NavLink } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GoogleLogin, GoogleLogout } from 'react-google-login';
import './scss/app.scss';
import { processGoogleLogin } from './api/API';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
//import LogRocket from 'logrocket';
import LabelMap from './features/labelmap/LabelMap';
import LabelMapList from './features/labelmap/LabelMapList';
import Flatmap from './features/flatmap/Flatmap';
import Layer from './features/layer/Layer';
import LayerList from './features/layer/LayerList';
import Layer2 from './features/layer2/Layer2';
import Layer2List from './features/layer2/Layer2List';

//LogRocket.init('qoir6t/marmo');

const WS_HOST = 'marmo.mrosa.org'
function NavItemCase() {
    const dispatch = useDispatch();
    const { case_id } = useParams();
    let { caseDetail } = useSelector(state => state.crop);
    let lock = null;
    if (caseDetail !== null) {
        if (caseDetail.locked) {
            lock = <FontAwesomeIcon icon={faLock} size="sm"/>;
        }
    }
    return (
        <>
            <li className="crumb">
                <NavLink to="/crop">Crops</NavLink>
            </li>
            <li className="crumb">
                {case_id} {lock}
            </li>
        </>
    );
}
function NavItemSlide() {
    const dispatch = useDispatch();
    const { case_id, slide_id } = useParams();
    let { caseDetail } = useSelector(state => state.crop);
    let lock = null;
    if (caseDetail !== null) {
        if (caseDetail.locked) {
            lock = <FontAwesomeIcon icon={faLock} size="sm"/>;
        }
    }
    return (
        <>
            <li className="crumb">
                <NavLink to="/crop">Crops</NavLink>
            </li>
            <li className="crumb">
                <NavLink to={'/crop/' + case_id}>{case_id}{lock}</NavLink>
            </li>
            <li>
                {slide_id}
            </li>
        </>
    );
}
function Nav() {
    const dispatch = useDispatch();
    const location = useLocation();
    if (location.pathname.match(/^\/(label|layer)/)) {
        return null;
    }
    return (
        <nav className="">
            <ul>
                <li className="crumb">
                    <NavLink to="/">Home</NavLink>
                </li>
                <Switch>
                    <Route path="/crop/:case_id/:slide_id">
                        <NavItemSlide/>
                    </Route>
                    <Route path="/crop/:case_id">
                        <NavItemCase/>
                    </Route>
                    <Route path="/crop">
                        <li>
                            <NavLink to="/crop">Crops</NavLink>
                        </li>
                    </Route>
                    <Route path="/flatmap">
                        <li>
                            <NavLink to="/flatmap">Flatmap</NavLink>
                        </li>
                    </Route>
                    <Route path="/layer">
                        <li>
                            <NavLink to="/layer">Layer</NavLink>
                        </li>
                    </Route>
                </Switch>
            </ul>
            <div className="login">
                <ul>
                </ul>
            </div>
        </nav>
    )
}

function App() {
    const dispatch = useDispatch();
    const { info, needLogin } = useSelector(state => state.user);
    async function login(response) {
        //const result = await processGoogleLogin(response.accessToken);
        const result = await processGoogleLogin(response.tokenId);
        if (response.accessToken) {
            //console.log('token', response.accessToken);
            localStorage.setItem('jwt', result.jwt);
            window.location.href = '/';
        } else {
            console.error('login failure?');
        }

    }
    function handleLoginFailure(e) {
        console.log('error login', e)
    }

    useEffect(() => {
        dispatch(getInfo());
        window.app = window.app || {};
        let ws = new WebSocket(`wss://${WS_HOST}/ws`);
        ws.onmessage = (event) => {
            let data = JSON.parse(event.data);
            if (data.type === 'CROP_FINISH') {
                dispatch(notifyCropFinish(data));
                dispatch(getProcessingQueue(data));
            } else if (data.type === 'CROP_START') {
                dispatch(notifyCropStart(data));
            } else if (data.type === 'COUNTING_CROP') {
                dispatch(notifyCountingEvent(data));
            }
        }

    }, [ dispatch ]);
    if (!info.username) {
        if (needLogin) {
            return (
                <div className="app">
                    <header className="app-header">
                    </header>
                    <section className="main">
                        Application requires login.
                            <GoogleLogin
                                clientId={'797935601311-j2a76gnpbfdcuogk0pa4fragdvdkl66h.apps.googleusercontent.com'}
                                onSuccess={login}
                                onFailure={handleLoginFailure}
                                cookiePolicy={'single_host_origin'}
                                responseType={'code,token'}
                            />
                    </section>
                    <footer className="footer">
                    </footer>
                    <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar newestOnTop closeOnClick
                        rtf={false} pauseOnFocusLoss draggable={false} pauseOnHover
                    />
                </div>
            );
        } else {
            return (
                <div className="loading">
                    <div className="loading-text">Loading please wait...</div>
                </div>
            );
        }
    }
    return (
        <div className="app">
            <Router>
                <header className="app-header">
                    <Nav />

                </header>
                <section className="main">
                    <Switch>
                        <Route path="/crop/:case_id/:slide_id">
                            <Slide/>
                        </Route>
                        <Route path="/crop/:case_id">
                            <CaseDetail/>
                        </Route>
                        <Route exact path="/crop">
                            <CropList/>
                        </Route>
                        <Route exact path="/">
                            <div>
                                <NavLink to="/crop">Cropping Machine &reg;</NavLink>
                            </div>
                            <div>
                                <NavLink to="/label">Label Map Interface</NavLink>
                            </div>
                            <div>
                                <NavLink to="/inspect">Flatmap Cell Inspector</NavLink>
                            </div>
                            <div>
                                <NavLink to="/layer">Layer</NavLink>
                            </div>
                            <div>
                                <NavLink to="/layer2">Layer Delineation for Cell Counting</NavLink>
                            </div>
                        </Route>
                        <Route exact path="/login">
                            <GoogleLogin
                                clientId={'797935601311-j2a76gnpbfdcuogk0pa4fragdvdkl66h.apps.googleusercontent.com'}
                                onSuccess={login}
                                onFailure={handleLoginFailure}
                                cookiePolicy={'single_host_origin'}
                                responseType={'code,token'}
                            />
                        </Route>
                        <Route exact path="/inspect">
                            <Inspector />
                        </Route>
                        <Route path="/label/:section_id+">
                            <LabelMap />
                        </Route>
                        <Route path="/label">
                            <LabelMapList />
                        </Route>
                        <Route path="/flatmap">
                            <Flatmap />
                        </Route>
                        <Route path="/layer/:section_id+">
                            <Layer />
                        </Route>
                        <Route path="/layer">
                            <LayerList />
                        </Route>
                        <Route path="/layer2/:section_id+">
                            <Layer2 />
                        </Route>
                        <Route path="/layer2">
                            <Layer2List />
                        </Route>
                    </Switch>

                </section>
            </Router>
            <footer className="footer">
            </footer>
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar newestOnTop closeOnClick
                rtf={false} pauseOnFocusLoss draggable={false} pauseOnHover
            />
        </div>
    );
}

export default App;
