import React, { useEffect, useRef, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCaseDetail, setSlideDetail,
    getSlideDetail, actionCropFull, flipCrop, addCrop, removeCrop,
    selectActiveCrops, updateCrop, recallSlideDetail, updateCrops
} from './cropSlice';
import { useParams } from 'react-router-dom';
import styles from './Crop.module.scss';
import classNames from 'classnames';
import { globalKeyDownFactory } from './utils';
import { fabric } from 'fabric';
import { GlobalHotKeys, ObserveKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';
import { uniqueId } from 'lodash';
import spinner from '../../images/spinner.svg';
import ProcessingQueue from './ProcessingQueue';

let global_canvas;
let _context = {
    showSpinner: true,
    top: 0,
    right: 0
}

function Help() {
    const [showTip, setShowTip] = useState(false);
    return (
        <>
            <a href="https://doc.marmosetbrain.org/cropping_ui.html" target="_blank"
                className={classNames(styles.user_manual)}
                onMouseEnter={() => setShowTip(true)}
                onMouseLeave={() => setShowTip(false)}
                >Help!</a>
            <div className={classNames(styles.help_tooltip, {[styles.hidden]: !showTip})}>
                <p>Quick reference: <strong>Shift + Click</strong> and Drag to draw new crop box</p>
                <p>Click inside to activate an existing crop</p>
                <p>Keyboard arrow key can be used for easy tuning of the active crop. Shift + arrow key for finer control</p>
                <p>k or K to increase or reduce the size of box by 0.5mm</p>
                <p>&gt; and &lt; to increase or reduce the size of box by 5%</p>
                <p>[ and ] to rotate the box</p>
                <p>Click and drag the red box at edges and corners to shift the edge and corner</p>
                <p>Name must not be empty</p>
                <p><strong>Remember to press the Crop button!</strong></p>
                <p><strong>Click</strong> for more info</p>
            </div>
        </>
    )

}
const PreviewContext = React.createContext(_context);
const clearCrops = () => {
    colorReset();
    global_canvas.forEachObject(obj => {
        if (obj.type === 'crop') {
            global_canvas.remove(obj);
        }
    })
}
function CropItem(props) {
    const {
        id, x, y, w, h, angle, section, hflip, status, resolution, color
    } = props;
    const context = useContext(PreviewContext);
    const { case_id } = useParams();
    const dispatch = useDispatch();

    const onUpdateHflip = (e) => {
        if (e.target.checked) {
            dispatch(flipCrop(id, true));
        } else {
            dispatch(flipCrop(id, false));
        }
    }

    const onUpdateSectionName = (e) => {
        dispatch(updateCrop({id: id, attrs: ['section'], values: { section: e.target.value}}))
    }

    const onRemoveSection = () => {
        clearCrops();
        colorReset();
        dispatch(removeCrop(id))
    }
    const onMouseEnter = (e) => {
        if (status === 'Done') {
            context.setShowSpinner(true);
            let rect = e.target.getBoundingClientRect();
            let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
            context.setTop(rect.bottom + 30 + scrollTop);
            context.setRight(100);
            context.setPreviewSection(section);
            context.setShowPreview(true);
        }
    }
    const onMouseLeave = (e) => {
        if (context.showPreview) {
            context.setShowPreview(false);
            context.setPreviewSection(null);
        }
    }
    const onMouseClick = (e) => {
        if (status === 'Done') {
            window.open(`/api/crop_preview/${case_id}/${section}`);
        }
    }
    const onCropFull = (e) => {
        e.preventDefault();
        dispatch(actionCropFull(case_id, section));
    }
    const onInputFocused = e => {
        window.app.inputing = true;
    }
    const onInputBlurred = e => {
        window.app.inputing = false;
    }
    return (
        <tr className="crop-item" style={{color: color}}>
            <td className="coord-x">{(x * resolution * 64).toFixed(2)}</td>
            <td className="coord-y">{(y * resolution * 64).toFixed(2)}</td>
            <td className="coord-w">{(w * resolution * 64).toFixed(2)}</td>
            <td className="coord-h">{(h * resolution * 64).toFixed(2)}</td>
            <td className="angle">{angle}</td>
            <td className="flip"><input type="checkbox" tabIndex="-1" value={1} checked={hflip} onChange={onUpdateHflip} /></td>
            <td className={styles.crop_name}><ObserveKeys><input type="text" value={section} onChange={onUpdateSectionName} onFocus={onInputFocused} onBlur={onInputBlurred} /></ObserveKeys></td>
            <td className={classNames(styles.crop_status)}
                >
                <div className={classNames({[styles.pointer]: status === 'Done'})}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={onMouseClick}
                    >
                    {status}
                </div>
            </td>
            <td className={styles.crop_remove}><span onClick={onRemoveSection}>{"\u274c"}</span></td>
        </tr>
    );
}

function CropList(props) {
    const { crops, resolution } = props;
    useEffect(() => {
    })
    return (
        <tbody>
            {
                crops.map((crop, i) => {
                    return <CropItem
                        key={crop.id} resolution={resolution} {...crop}
                        color={getColor(i)}
                    />;
                })
            }
        </tbody>
    );
}

const Crop = fabric.util.createClass(fabric.Rect, {
    type: 'crop',
    toString: function() {
        return this.x + '/' + this.y;
    },
    removeCrop: function() {
        this._active = false;
        this.remove();
    }
});

let _colors = [
    '#0000ff', '#00ff00', '#ff0000', '#00ffff', '#ff00ff', '#000000'
];
let _colorIdx = 0;

function colorReset() {
    _colorIdx = 0;
}
function getRandomColor() {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++ ) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;

}

function colorAdvance() {
    _colorIdx++;
    if (_colorIdx === _colors.length) {
        _colors.push(getRandomColor());
    }
};

function getColor(idx=null) {
    if (idx !== null) {
        return _colors[idx];
    } else {
        return _colors[_colorIdx];
    }
};

function getColorAndAdvance() {
    let color = getColor()
    colorAdvance()
    return color
}

const CropPanel = ({
    crops, slide, clearCrops
}) => {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const setOpacity = (o) => {
        ref.current.style.opacity = o;
    }
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(null);
    const cropRef = useRef(crops);
    const posRef = useRef(null);
    const pressedRef = useRef(false);
    useEffect(() => {
        /*
        $(document).on('mouseenter', '.usage-help', function(event) {
            $(this).parent().find('.help-tooltip').addClass('active')

        });
        $(document).on('mouseleave', '.usage-help', function(event) {
            $(this).parent().find('.help-tooltip').removeClass('active')
        });
        */
        posRef.current = {
            x: 0,
            y: 0,
            baseX: 0,
            baseY: 0
        }
        pressedRef.current = false;
    }, [dispatch])
    useEffect(() => {
        cropRef.current = crops;
    }, [crops])
    const onMouseMove = (e) => {
        let pressed = pressedRef.current;
        if (pressed) {
            /*
            setPosition({
                x: position.x + e.movementX,
                y: position.y + e.movementY
            });
            */
            let pos = posRef.current;
            //pos.x = pos.x + e.movementX;
            //pos.y = pos.y + e.movementY;
            pos.x = pos.baseX + e.clientX - pos.originX;
            pos.y = pos.baseY + e.clientY - pos.originY;

            if (ref.current) {
                ref.current.style.transform = `translate(${pos.x}px, ${pos.y}px)`;
            }
        }
    }
    const onStopMoving = (e) => {
        let pressed = pressedRef.current;
        if (pressed) {
            document.removeEventListener('mousemove', onMouseMove);
            pressedRef.current = false;
            setOpacity(1)
            let pos = posRef.current;
            pos.baseX = pos.x;
            pos.baseY = pos.y;
        }
    }
    const onStartMoving = (e) => {
        e.preventDefault();
        pressedRef.current = true;
        setOpacity(0.2)
        let pos = posRef.current;
        pos.originX = e.clientX;
        pos.originY = e.clientY;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onStopMoving);
    }
    const onSaveClicked = async (e) => {
        let crops = cropRef.current;
        e.preventDefault();
        for (let c of crops) {
            if (!c.section.trim()) {
                setError('Section name must not be empty.');
                return;
            }
        }
        if (window.app.saving) {
            return;
        }
        setSaving(true);
        window.app.saving = true;
        setError(null);
        toast('Saving, please be patient');
        clearCrops();
        colorReset();
        await dispatch(updateCrops())
        setSaving(false);
        window.app.saving = false;
    };

    /*
    useEffect(() => {
        if (ref.current) {
            ref.current.style.transform = `translate(${position.x}px, ${position.y}px)`;
        }
    }, [position])
    */
    const onDiscardClicked = (e) => {
        e.preventDefault();
        clearCrops();
        dispatch(recallSlideDetail());
    }
    //onMouseMove={onMouseMove}
    return (
        <GlobalHotKeys keyMap={{SAVE: ['ctrl+s', '`']}} handlers={{SAVE: onSaveClicked}}>
            <div id="crop_selection" className={styles.crop_selection} ref={ref}
                >
                <div className={styles.handle_bar}
                    onMouseDown={onStartMoving}
                    >&nbsp;</div>
                <form className={styles.crop_form}>
                    <h4>Active crops:</h4>
                    <table className={styles.crop_info}>
                        <thead>
                            <tr><th>X(mm)</th><th>Y(mm)</th><th>W(mm)</th><th>H(mm)</th><th>Angle</th><th>FlipLR</th><th>Name</th><th>Status</th><th></th></tr>
                        </thead>
                        <CropList
                            crops={crops} resolution={slide.resolution}
                        />
                    </table>
                    { error !== null &&
                        <div className="error form-error">{error}</div>
                        }
                    <button className="button btn-primary" id="btn-crop" disabled={saving}
                        style={{cursor: saving ? 'wait' : 'pointer'}}
                        onClick={onSaveClicked}
                        >Save & Crop</button>
                    <button id="btn-discard" onClick={onDiscardClicked}>Reset</button>
                </form>
            </div>
        </GlobalHotKeys>
    )
}

function Preview() {
    const { case_id } = useParams();
    const context = useContext(PreviewContext);
    const onPreviewImageLoaded = () => {
        context.setShowSpinner(false);
    };
    const previewStyle = {
        top: context.top + 'px',
        right: context.right + 'px'
    };
    return context.showPreview &&
            <div id="crop_preview" className={styles.crop_preview} style={previewStyle}>
                {
                    <>
                    { context.showSpinner &&
                        <div className={styles.preview_placeholder}>
                            <img src={spinner} alt="" width="118" height="118" />
                        </div>
                    }
                    <img src={`/api/crop_preview/${case_id}/${context.previewSection}?time=${Math.floor(Date.now() / 1000)}`} alt="preview"
                        className={styles.preview_image}
                        onLoad={onPreviewImageLoaded}
                    />
                    </>

                }
            </div>
}

export default function () {
    const { case_id, slide_id } = useParams();
    const slide = useSelector(state => state.crop.slideDetail);
    //const crops = slide ? slide.crops : [];
    const crops = useSelector(state => selectActiveCrops(state))
    const dispatch = useDispatch();
    const [showPreview, setShowPreview] = useState(false);
    const [previewSection, setPreviewSection] = useState(null);
    const [lastCrop, setLastCrop] = useState(null);
    const [canvas, setCanvas] = useState(null);
    const canvasRef = useRef(canvas);
    //const [showSpinner, setShowSpinner] = useState(true);
    function drawCrop(crop, color=null, newCrop=false) {
        const canvas = canvasRef.current;
        if (!canvas) {
            return;
        }
        let originX = 'center';
        let originY = 'center';
        if (crop.new_crop) {
            originX = 'left';
            originY = 'top';
        }
        if (color === null) {
            color = getColor()
            colorAdvance();
        }
        var _crop = new Crop({
            id: crop.id,
            width: crop.w,
            height: crop.h,
            fill: color,
            borderColor: color,
            opacity: 0.3,
            cornerColor: 'red',
            cornerSize: 10,
            angle: crop.angle,
            hasControls: true,
            selectable: true,
            hoverCursor: 'pointer',
            lockRotation: false,
            hasRotatingPoint: true,
            originX: originX,
            originY: originY,
            strokeUniform: true
        });
        _crop.setPositionByOrigin(new fabric.Point(crop.x, crop.y), 'left', 'top');
        canvas.add(_crop);
        if (crop.new_crop) {
            canvas.setActiveObject(_crop);
        }
        canvas.renderAll();
        canvas.calcOffset();
        return _crop;
    }
    useEffect(() => {
        colorReset();
        let started = false;
        let x = 0;
        let y = 0;
        if (case_id) {
            dispatch(getCaseDetail(case_id));
            dispatch(getSlideDetail(case_id, slide_id));
        }
        let _canvas = new fabric.Canvas('crop-canvas', {
            selection: false,
            controlsAboveOverlay: true,
            width: 2560,
            height: 1440
        });
        _canvas.on('after:render', () => {
            _canvas.contextContainer.strokeStyle = '#555';
            _canvas.forEachObject((obj) => {
                let bound = obj.getBoundingRect();
                _canvas.contextContainer.strokeRect(
                    bound.left,
                    bound.top,
                    bound.width,
                    bound.height
                )
            });
        });

        setCanvas(_canvas);
        canvasRef.current = _canvas;
        const onCropModified = (opt) => {
            const crop = opt.target;
            const pt = crop.getPointByOrigin('left', 'top');
            let x = Math.round(pt.x);
            let y = Math.round(pt.y);
            //let w = Math.round(crop.width * crop.scaleX);
            //let h = Math.round(crop.height * crop.scaleY);
            //let w = Math.round(crop.getScaledWidth());
            //let h = Math.round(crop.getScaledHeight());
            let w = Math.round(crop.scaleX ? crop.scaleX * crop.width : crop.width)
            let h = Math.round(crop.scaleY ? crop.scaleY * crop.height : crop.height)
            let angle = Math.round(crop.angle);
            dispatch(updateCrop({
                id: crop.get('id'),
                attrs: ['x', 'y', 'w', 'h', 'angle'],
                values: {x: x, y: y, w: w, h: h, angle: angle}
            }))
        }
        _canvas.on('object:scaling', onCropModified);
        _canvas.on('object:moving', onCropModified);
        _canvas.on('object:rotating', onCropModified);
        let slide_url = `/api/thumbnail/${case_id}/${slide_id}.jpg`;



        fabric.Image.fromURL(slide_url, function(oImg) {
            _canvas.add(oImg);
            //canvas.setWidth(oImg.width);
            //canvas.setHeight(oImg.height);
            oImg.set('selectable', false);
            oImg.sendToBack();
        })
        _canvas.on('mouse:down', (opt) => {
            const canvas = canvasRef.current;
            if (!canvas) {
                return;
            }
            if (opt.e.shiftKey) {
                if (window.app.saving) {
                    return;
                }
                let mouse = canvas.getPointer(opt.e);
                started = true;
                x = mouse.x;
                y = mouse.y;

                var crop = {
                    x: x,
                    y: y,
                    w: 1,
                    h: 1,
                    angle: 0,
                    id: uniqueId('new'),
                    new_crop: true,
                    status: 'Draft',
                    section: '',
                };
                dispatch(addCrop(crop));
                //addCrop(crop);
                //canvas.bringToFront(crop);
            }
        });

        _canvas.on('mouse:move', (opt) => {
            if(!started) {
                return false;
            }

            let mouse = _canvas.getPointer(opt.e);

            let w = mouse.x - x, h = mouse.y - y;

            if (!w || !h) {
                return false;
            }
            let values = {
                w: w,
                h: h,
            }
            let crop = _canvas.getActiveObject();
            if (crop) {
                if (w < 0) {
                    crop.set('width', -w);
                    crop.set('left', x + w);
                    values.w = -w;
                    values.x = x + w;
                } else {
                    crop.set('width', w);
                }

                if (h < 0) {
                    crop.set('height', -h);
                    crop.set('top', y + h);
                    values.h = -h;
                    values.y = y + h;
                } else {
                    crop.set('height', h);
                }
                _canvas.renderAll();
                dispatch(updateCrop({
                    id: crop.get('id'),
                    attrs: Object.keys(values),
                    values: values
                }))
            }
        });
        _canvas.on('mouse:up', function(opt) {
            if (!started) {
                return false;
            }

            started = false;
            var crop = _canvas.getActiveObject();
            crop.set({originX: 'center', originY: 'center'})
            crop.setPositionByOrigin(new fabric.Point(crop.left, crop.top), 'left', 'top');
            //crop.setCoords();
            //_canvas.discardActiveObject();
            _canvas.renderAll();
            _canvas.calcOffset();
            return;
        });
        global_canvas = _canvas;
        let globalKeydown = globalKeyDownFactory(canvasRef.current, lastCrop, setLastCrop, dispatch, 0.0004974);
        window.addEventListener('keydown', globalKeydown);

        function beforeUnload(e) {
            if (window.app.saving) {
                let confirmationMessage = 'Saving in progress, please do not navigate away from the page';
                (e || window.event).returnValue = confirmationMessage;
                return confirmationMessage;
            }
        }
        window.addEventListener('beforeunload', beforeUnload);

        return () => {
            window.removeEventListener('keydown', globalKeydown);
            dispatch(setSlideDetail(null));
        }
    }, [dispatch]);

    function onPrevClicked(e) {
        fetch(`/api/crop/prev_slide/${case_id}/${slide_id}`)
        .then((res) => {
            res.json().then((data) => {
                window.location.href = `/crop/${case_id}/${data.prev_slide_id}`
            });
        })
    }
    function onNextClicked(e) {
        fetch(`/api/crop/next_slide/${case_id}/${slide_id}`)
        .then((res) => {
            res.json().then((data) => {
                window.location.href = `/crop/${case_id}/${data.next_slide_id}`
            });
        })
    }
    useEffect(() => {
        if (slide) {
            for (let c of slide.crops) {
                if (c.deleted) {
                    continue;
                }
                let exists = false;
                canvas.forEachObject(obj => {
                    if (obj.type === 'crop' && obj.id === c.id) {
                        exists = true;
                    }
                })
                if (!exists) {
                    drawCrop(c);
                }
            }
            canvas.renderAll();
        }
        return;

    }, [slide]);
    const [showSpinner, setShowSpinner] = useState(true);
    const [top, setTop] = useState(0);
    const [right, setRight] = useState(0);
    return (
        <PreviewContext.Provider value={{
            showSpinner, setShowSpinner, top, setTop, right, setRight,
            showPreview, setShowPreview, previewSection, setPreviewSection
        }}>
            <div className="cropping-machine-container">
                <div className={styles.additional_nav}>
                    <button onClick={onPrevClicked}><span className="mirror">➔</span> Prev</button>
                    <button onClick={onNextClicked}>Next {'➔'}</button>
                </div>
                <ProcessingQueue />
                <Help />
                <div className="cropping-machine">
                    <canvas id="crop-canvas" width="1920" height="1080"></canvas>
                    {
                        slide &&
                            <CropPanel crops={crops} slide={slide}
                                clearCrops={clearCrops}
                            />}
                    <Preview />
                </div>
            </div>
        </PreviewContext.Provider>
    );
}
