import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCases, getCaseDetail, setCaseDetail, selectCaseSlides } from './cropSlice';
import { useParams } from 'react-router-dom';
import styles from './Crop.module.scss';
import classNames from 'classnames';
import ProcessingQueue from './ProcessingQueue';
import { Link } from 'react-router-dom';
import { Th } from './utils';

function sortSection(a, b) {
    let va = a;
    let vb = b;
    return (va > vb) - (va < vb);
}
function SlideItem(props) {
    const {
        case_id, slide_id, width, height,
        thumbnail_width, thumbnail_height,
        pending, total, sections } = props;
    const [detail, setDetail] = useState(false);
    const onToggleDetail = () => {
        if (detail) {
            setDetail(false);
        } else {
            setDetail(true);
        }
    };
    let _sections;
    if (sections !== null) {
        _sections = [...sections];
        _sections.sort(sortSection);
    } else {
        _sections = [];
    }
    return (
        <>
            <tr className={styles.row}>
                <td>
                    <Link to={'/crop/' + case_id + '/' + slide_id} >
                        {slide_id}
                    </Link>
                </td>
                <td>
                    {slide_id.includes('_') ?
                        slide_id.split('_')[0] :
                        'N/A'
                    }
                </td>
                <td>
                    {width}
                </td>
                <td>
                    {height}
                </td>
                <td>
                    {thumbnail_width}x{thumbnail_height}
                </td>
                <td>
                    {total - pending} / {total}
                </td>
                <td>
                    {_sections.map((v, idx) => <span className={styles.section_name} key={idx}>{v}</span>)}
                </td>
                <td className={styles.slide_thumbnail} onClick={onToggleDetail}>
                    <img src={`/api/thumbnail/${case_id}/${slide_id}.jpg`}
                        height="25"/>
                </td>
            </tr>
            { detail &&
                <tr className={styles.memo}>
                    <td colSpan="8">
                        <img src={`/api/thumbnail/${case_id}/${slide_id}.jpg`}
                            width="100%"/>
                    </td>
                </tr>
            }
        </>
    )
}

export default function () {
    const [orderBy, setOrderBy] = useState('slide_name');
    const [order, setOrder] = useState('asc');
    const slides = useSelector(state => selectCaseSlides(state, orderBy, order));
    const dispatch = useDispatch();

    const { case_id } = useParams();
    useEffect(() => {
        if (case_id) {
            dispatch(getCaseDetail(case_id));
        }
        return () => {
            dispatch(setCaseDetail(null));
        }
    }, [dispatch]);

    const onSortUpdate = e => {
        let field = e.target.dataset.orderBy;
        if (field === orderBy) {
            if (order === 'asc') {
                setOrder('desc');
            } else {
                setOrder('asc');
            }
        } else {
            setOrderBy(field);
            setOrder('desc');
        }
    }

    const [filterId, setFilterId] = useState('');
    const onFilterIdChange = (e) => {
        setFilterId(e.target.value);
    }
    const onClearFilterId = () => setFilterId('');

    const p = { order, orderBy, onSortUpdate };
    console.log('rendered');
    return (
        <>
        <ProcessingQueue />
        <table className={styles.list}>
            <thead>
                <tr className={styles.header}>
                    <Th name="slide_name" {...p}>Slide Name</Th>
                    <Th name="slide_id" {...p}>Slide ID</Th>
                    <Th name="width" {...p}>Width</Th>
                    <Th name="height" {...p}>Height</Th>
                    <Th name="thumbnail" {...p}>Thumbnail size</Th>
                    <Th name="progress" {...p}>Progress</Th>
                    <th>Sections</th>
                    <th>Thumbnail</th>
                </tr>
                <tr className={styles.filters}>
                    <th className={styles.filter}>
                        <input type="text" value={filterId} onChange={onFilterIdChange} />
                        {filterId !== '' && <span className={styles.filterClear} onClick={onClearFilterId}>X</span>}
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>

            </thead>
            <tbody>
                {
                    slides.filter(v => {
                        if (filterId.length > 0) {
                            let exp = RegExp(filterId, 'i');
                            if (!exp.test(v.slide_id)) {
                                return false;
                            }
                        }
                        return true;
                    }).map(v => <SlideItem key={v.id} case_id={case_id} {...v} />)
                }
            </tbody>
        </table>
        </>
    );
}
