import _ol_TileState_ from 'ol/TileState';
import * as _ol_extent_ from 'ol/extent';
import {default as TileGrid} from 'ol/tilegrid/TileGrid';
import {DEFAULT_TILE_SIZE as _ol_DEFAULT_TILE_SIZE} from 'ol/tilegrid/common';

import TileImageSource from 'ol/source/TileImage';
import ImageTile from 'ol/ImageTile';
import {createCanvasContext2D} from 'ol/dom';

let DjatokaTile = (function (ImageTile) {
    function DjatokaTile(tileCoord, state, src, crossOrigin, tileLoadFunction) {
        ImageTile.call(this,
            tileCoord, state, src, crossOrigin, tileLoadFunction
        );
        this.imageCache = null;
    }

    if (ImageTile) {
        DjatokaTile.__proto__ = ImageTile;
    }
    DjatokaTile.prototype = Object.create( ImageTile && ImageTile.prototype);
    DjatokaTile.prototype.constructor = DjatokaTile;

    DjatokaTile.prototype.getImage = function() {
        let tileSize = _ol_DEFAULT_TILE_SIZE;
        //var tileSize = 256;
        if (this.imageCache) {
            return this.imageCache;
        }
        var image = ImageTile.prototype.getImage.call(this);
        if (this.state === _ol_TileState_.LOADED) {
            if (image.width === tileSize && image.height === tileSize) {
                //this.zoomifyImageByContext_[key] = image;
                this.imageCache = image;
                return image;
            } else {
                // this is to draw the edge of the image which it doesn't fill a full grid
                var context = createCanvasContext2D(tileSize, tileSize);
                context.drawImage(image, 0, 0);
                //this.zoomifyImageByContext_[key] = context.canvas;
                //this.zoomifyImage_ = context.canvas;
                //return context.canvas;
                this.imageCache = context.canvas;
                //return context.canvas;
                return context.canvas;
            }
        } else {
            return image;
        }
    };
    return DjatokaTile;
}(ImageTile));


let DjatokaSource = (function (TileImageSource) {
    function DjatokaSource(opt_options) {

        var options = opt_options || {};
        this.url = options.url || 'http://'+ window.location.hostname + '/webapps/adore-djatoka/resolver';
        this.url_ver = 'Z39.88-2004';
        this.rft_id = options.image;
        this.svc_id = 'info:lanl-repo/svc/getRegion';
        this.svc_val_fmt = 'info:oft/fmt:kev:mtx:jpeg2000';
        this.format = 'image/jpeg';
        this.gamma = options.gamma || 1.0;
        this.crange = options.crange || '0-255,0-255,0-255';

        this.resolutions = [1];
        var tileGrid;
        var that = this;
        /**
        * @param {ol.TileCoord} tileCoord Tile Coordinate.
        * @param {number} pixelRatio Pixel ratio.
        * @param {ol.proj.Projection} projection Projection.
        * @return {string|undefined} Tile URL.
        */
        var tileUrlFunction = function(tileCoord, pixelRatio, projection) {
            if (!tileCoord) {
                return undefined;
            } else {
                var cz = tileCoord[0];
                var cx = tileCoord[1];
                var cy = tileCoord[2];
                var res = that.resolutions[cz];
                return that.url + '?url_ver=' + that.url_ver + '&rft_id=' + that.rft_id + '&svc_id=' + that.svc_id +
                    '&svc_val_fmt=' + that.svc_val_fmt + '&svc.format=' + that.format +
                    '&svc.level=' + (cz + 1) + '&svc.rotate=0&svc.region=' +
                    256 * cy * res + ',' + 256 * cx * res + ',256,256' +
                    '&svc.crange=' + this.crange + '&svc.gamma=' + this.gamma;
            }
        }
        TileImageSource.call(this, {
            attributions: options.attributions,
            crossOrigin: options.crossOrigin,
            logo: options.logo,
            tileClass: DjatokaTile,
            tileGrid: tileGrid,
            tileUrlFunction: tileUrlFunction
        });

        /**
         * The image meta stored in jp2 file including width and height
         * @protected
         * @type {Object|undefined}
         */
         this.imageMeta_ = undefined;
    }

    if (TileImageSource) {
        DjatokaSource.__proto__ = TileImageSource;
    }
    DjatokaSource.prototype = Object.create(TileImageSource && TileImageSource.prototype);
    DjatokaSource.prototype.constructor = DjatokaSource;
    return DjatokaSource;
}(TileImageSource));


DjatokaSource.prototype.getTileSize = function() {
    return [256, 256];
};

DjatokaSource.prototype.getImageMetadata = function(callback) {
    var meta_url = this.url + '?url_ver=' + this.url_ver + '&rft_id=' + this.rft_id + '&svc_id=info:lanl-repo/svc/getMetadata';
    var that = this;
    var xhr = new XMLHttpRequest();
    xhr.open('GET', meta_url, true);

    xhr.onload = function(e) {
        //var xhr = e.target;
        var obj = JSON.parse(xhr.responseText);
        that.imageMeta_ = obj;
        //console.log('meta saved', that.imageMeta_, meta_url);
        var width = obj.width;
        var height = obj.height;
        for (var i=1, ii=obj.levels; i < ii; i++) {
            that.resolutions.push(1 << i);
        }
        that.resolutions.reverse();
        var extent = [0, -height, width, 0];
        that.tileGrid = new TileGrid({
            extent: extent,
            origin: _ol_extent_.getTopLeft(extent),
            resolutions: that.resolutions
        });
        callback && callback();
    };
    xhr.send();
};
DjatokaSource.prototype.getMeta = function() {
    return this.imageMeta_;
};

export {DjatokaSource};
